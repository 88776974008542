<!-- BomsMassUpdateModal.vue -->
<template>
  <v-dialog v-model="dialogModel" max-width="1100px" persistent>
    <v-card class="position-relative">
      <v-card-title class="modal-title">
        <span class="text-h5">Update BOMs in Odoo</span>
        <v-icon small @click="closeModal" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>

      <div class="modal-content-scrollable">
        <v-row class="px-2">
          <v-col cols="12">
            <v-card-actions>
              <p style="font-size: large">
                Existing BOMs for family:
                <strong>{{ selectedFamily?.pnFamily || 'N/A' }}</strong>
              </p>
              <v-spacer></v-spacer>
              <v-btn class="btn-cancel" variant="text" @click="selectOutdatedBoms">Select all outdated</v-btn>
              <!-- <v-btn class="btn-cancel" variant="text" @click="selectAllBoms">Select all BOMS</v-btn> -->
            </v-card-actions>
          </v-col>
        </v-row>
        <v-row class="px-4" style="margin-top: -10px">
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              :items="boms"
              :headers="headers"
              item-value="id"
              density="compact"
              class="nested-table"
              :items-per-page="-1"
              show-select
              v-model="selected"
            >
              <!-- <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Existing BOMs for family: <strong>{{ selectedFamily.pnFamily }}</strong></v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template> -->
              <!-- <template v-slot:[`header.data-table-select`]></template> -->
              <template v-slot:[`item.upToDate`]="{ item }">
                <v-chip :color="item.upToDate ? 'success' : 'error'" small>{{ item.upToDate ? 'Up to date' : 'Outdated' }}</v-chip>
              </template>

              <template v-slot:[`item.timestamps`]="{ item }">
                <div class="timestamps">
                  <div class="d-flex flex-column">
                    <span class="text-caption">Created: {{ formatDate(item.createdAt) }}</span>
                    <span class="text-caption text-grey">Last updated: {{ formatDate(item.updatedAt) }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="controls-wrapper">
                  <v-tooltip text="Open in Odoo" location="top">
                    <template #activator="{ props }">
                      <span v-bind="props" @click.stop="openInOdoo(item.odooUrl)" class="action-button mr-3" :class="{ disabled: !item.odooUrl }">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                      </span>
                    </template>
                  </v-tooltip>

                  <v-tooltip text="Update BOM" location="top">
                    <template #activator="{ props }">
                      <span v-bind="props" @click.stop="updateBom(item)" class="action-button">
                        <i class="fa-solid fa-arrows-rotate"></i>
                      </span>
                    </template>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <div class="spacing-div"></div>
      </div>

      <v-card-actions class="fixed-actions px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn :disabled="selected.length == 0" class="btn-orange" variant="text" @click="handleUpdate">Update selected</v-btn>
        <v-btn class="btn-cancel" variant="text" @click="closeModal">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="updating" class="loader-container">
      <div class="circular-progress">
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="background" cx="25" cy="25" r="20" fill="white"></circle>
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
        </svg>
        <div class="percentage">{{ currentProgress }} / {{ totalItems }}</div>
      </div>
    </div>
    <Dialogs :updateDialog="updateDialog" @update:updateDialog="updateDialog = false" @confirmCancel="onUpdateConfirmed" />
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import Dialogs from '@/components/common/Dialogs.vue';
  import bomService from '@/services/api/bomService';
  import { useAuthStore } from '@/store/authStore';
  import Loader from '@/components/bomg/Loader.vue';

  export default defineComponent({
    name: 'MassUpdate',

    components: {
      Dialogs,
      Loader,
    },

    props: {
      modal: {
        type: Boolean,
        required: true,
      },
      modelValue: {
        type: Boolean,
        required: true,
      },
      selectedFamily: {
        type: Object as () => { pnFamily: string } | null,
        default: null,
      },
    },

    emits: ['update:modelValue', 'close', 'update-boms'],

    data() {
      return {
        loading: false,
        updating: false,
        updateDialog: false,
        headers: [
          { title: 'Reference', value: 'productPn' },
          { title: 'Product info', value: 'reference' },
          { title: 'BOM status', value: 'upToDate', sortable: false },
          { title: 'Timestamps', value: 'timestamps', sortable: false },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
        boms: [] as any[],
        selected: [] as any[],
        currentProgress: 0,
        totalItems: 0,
        errorCount: 0,
        errorDetails: [] as any[],
        successItems: [] as any[],
      };
    },
    watch: {
      dialogModel(newVal: boolean) {
        if (newVal) {
          this.getFamilyBomList();
        }
      },
    },
    computed: {
      dialogModel: {
        get(): boolean {
          return this.modelValue;
        },
        set(value: boolean) {
          this.$emit('update:modelValue', value);
          if (!value) {
            this.closeModal();
          }
        },
      },
    },

    methods: {
      async getFamilyBomList() {
        this.loading = true;
        this.boms = [];
        try {
          const family = this.selectedFamily?.pnFamily;
          const boms = await bomService.bomFilteredProductsFamily(family || '');
          this.boms = boms;
        } catch (error) {
          this.$error.view(error);
        } finally {
          this.loading = false;
        }
      },

      async updateFamilyBomList() {
        const authStore = useAuthStore();
        const token = authStore.credential?.token;

        this.updating = true;
        this.errorCount = 0;
        this.errorDetails = [];
        this.successItems = [];
        this.totalItems = 0;
        this.currentProgress = 0;

        try {
          const familys = this.selected.map((id) => this.boms.find((bom) => bom.id === id));

          const url = `${import.meta.env.VITE_API_URL}bom/odooBom`;

          const response = await fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(familys),
          });

          if (!response.ok) {
            throw new Error(`Error HTTP: ${response.status}`);
          }

          if (!response.body) {
            throw new Error('Response body is null');
          }

          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');

          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              console.log('Stream closed.');
              break;
            }

            const chunk = decoder.decode(value, { stream: true });
            // console.log('Raw chunk:', chunk);

            try {
              const lines = chunk.split('\n').filter(Boolean);

              for (const line of lines) {
                if (line.includes('data:')) {
                  const jsonString = line.split('data:')[1].trim();
                  const parsed = JSON.parse(jsonString);
                  // console.log('Parsed data:', parsed);

                  this.totalItems = parsed.total || this.totalItems;
                  this.currentProgress = parsed.current || this.currentProgress;

                  if (parsed.success) {
                    this.successItems.push(...parsed.success);
                  }

                  if (parsed.errors && parsed.errors.length > 0) {
                    parsed.errors.forEach((error: any) => {
                      if (!this.errorDetails.some((e) => e.productPn === error.productPn)) {
                        this.errorDetails.push(error);
                      }
                    });
                    this.errorCount = this.errorDetails.length;
                  }
                } else {
                  // console.warn('Skipping line without "data":', line);
                }
              }
            } catch (err) {
              console.error('Error parsing chunk:', err);
            }
          }
        } catch (error) {
          console.error('Error in stream:', error);
          this.$error.view(error);
        } finally {
          this.updating = false;

          let message = `Successfully updated: ${this.successItems.length} BOM(s)`;

          if (this.errorCount > 0) {
            const errorBoms = this.errorDetails.map((error) => error.productPn || 'Unknown PN').join(', ');
            message += `\nError updating: ${this.errorCount} BOM(s)`;
          }

          const messageType = this.errorCount > 0 ? 'warning' : 'success';
          this.showInfoMessage(message, messageType);
          await this.getFamilyBomList();
        }
      },
      closeModal() {
        this.selected = [];
        this.$emit('update:modelValue', false);
        this.$emit('close');
      },
      handleUpdate() {
        this.updateDialog = true;
      },
      onUpdateConfirmed() {
        this.updateFamilyBomList();

        this.updateDialog = false;
        // this.closeModal();
      },
      openInOdoo(url: string) {
        window.open(url, '_blank');
      },

      updateBom(bom: any) {
        this.selected = [];
        this.selected = [bom.id];
        this.handleUpdate();
      },

      formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },
      isItemSelectable(item: any) {
        return !item.upToDate;
      },
      selectOutdatedBoms() {
        this.selected = this.boms.filter((bom) => !bom.upToDate).map((bom) => bom.id);
      },

      selectAllBoms() {
        this.selected = this.boms.map((bom) => bom.id);
      },

      showInfoMessage(message: string, status: any) {
        const isWarning = status === 'warning';
        this.$swal.fire({
          toast: true,
          position: isWarning ? 'top-end' : 'top-end',
          icon: status || 'info',
          title: message,
          showConfirmButton: false,
          confirmButtonText: isWarning ? 'OK' : undefined,
          timer: isWarning ? 6000 : 3000,
          timerProgressBar: true,
          width: '25rem',
        });
      },
    },
  });
</script>
<style scoped>
  .position-relative {
    position: relative;
  }

  .modal-content-scrollable {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
    padding-bottom: 20px;
  }

  .fixed-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  .spacing-div {
    height: 50px;
    width: 100%;
  }
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }
  .action-button {
    cursor: pointer;
    color: #3b516b;
  }
  .action-button:hover {
    color: black;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(2px);
  }

  .circular-progress {
    position: relative;
    width: 120px;
    height: 120px;
  }

  .spinner {
    animation: rotate 2s linear infinite;
    width: 100%;
    height: 100%;
  }
  .background {
    stroke: none;
    fill: white;
  }
  .path {
    stroke: #ffa500;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: #3b3b3b;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
</style>
