<template>
  <div class="generate-drawings">
    <v-row>
      <v-col cols="12" md="12" class="mt-4"></v-col>
    </v-row>
    <v-row>
      <!-- No Data -->
      <v-col v-if="!pngUrl && !isWaiting" cols="12" md="12" class="content-col">
        <!-- <div class="no-data mt-6" data-cy="details-content">
          <h2 data-cy="details-title">No Drawings</h2>
          <p class="text-center" data-cy="details-description">
            <v-btn :disabled="isWaiting || isRequestDisabled" @click="handleRequestClick" class="btn-orange full-width bold-text mt-5">Request DRAWING</v-btn>
          </p>
        </div> -->
        <div class="no-data mt-6" data-cy="details-content">
          <h2 data-cy="details-title">No Drawings</h2>
          <p class="text-center" data-cy="details-description">
            <v-btn :disabled="isWaiting || isRequestDisabled" @click="handleRequestClick" class="btn-orange bold-text mt-5 mr-2 request-btn">REQUEST CUSTOMER DRAWING</v-btn>
            <v-btn :disabled="isWaiting || isRequestDisabled" @click="handleManufactureRequestClick" class="btn-orange bold-text mt-5 ml-2 request-btn">
              REQUEST MANUFACTURING DRAWING
            </v-btn>
          </p>
        </div>
        <div v-if="!isRequestDisabled">
          <hr class="divider" />
          <p class="additional-main-title">Additional info</p>
          <v-row>
            <v-col cols="12" md="7">
              <p class="additional-title">Overall Cable Length Tolerance</p>
              <v-data-table :headers="toleranceHeaders" :items="toleranceItems" item-key="name" class="elevation-1">
                <template v-slot:item.MFA="{ item }">
                  <v-text-field v-model="item.MFA" variant="underlined" hide-details></v-text-field>
                </template>
                <template v-slot:item.tolerance_mm="{ item }">
                  <v-text-field v-model="item.tolerance_mm" variant="underlined" hide-details></v-text-field>
                </template>
                <template v-slot:item.tolerance_in="{ item }">
                  <v-text-field v-model="item.tolerance_in" variant="underlined" hide-details></v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn @click="removeToleranceLine(item)" color="red"><i class="fa-duotone fa-solid fa-trash"></i></v-btn>
                </template>
                <template v-slot:no-data>No data available, please add new line</template>
                <template v-slot:bottom>
                  <v-toolbar flat class="mt-2">
                    <v-btn @click="addNewToleranceLine" :disabled="disableAddNewToleranceLine" class="btn-orange ml-4">Add new line</v-btn>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="3">
              <p class="additional-title">Note</p>
              <v-textarea rows="12" v-model="drawNote"></v-textarea>
            </v-col>
            <v-col cols="12" md="2">
              <p class="additional-title">Orientation</p>
              <v-select :items="['Auto', 'Landscape', 'Portrait']" v-model="selectedOrientation" hide-details></v-select>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- Waiting -->
      <v-col v-if="isWaiting && !pngUrl" cols="12" md="12" class="content-col-wait">
        <div class="no-data-v2" data-cy="details-content">
          <h2 class="text-center" data-cy="details-title">
            Please wait...
            <br />
            The drawing is being generated.
          </h2>
          <p data-cy="details-description">This may take some time, approximately 5 minutes on average.</p>
          <Loader class="mt-3" :progress="progress" />
        </div>
      </v-col>
      <!--  Image  -->
      <v-col v-if="pngUrl" cols="12" md="12" class="content-col">
        <div>
          <vue-pdf-app v-if="pdfFileUrl" :pdf="pdfFileUrl" style="width: 100%; height: 76vh" theme="light" :config="config" />
          <div class="float-btn">
            <v-btn :disabled="!pngUrl || !visioFileUrl" @click="downloadVisio" class="btn-orange download-visio-btn">Download Visio</v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import drawingService from '@/services/api/drawingService';
  import VuePdfApp from 'vue3-pdf-app';
  import 'vue3-pdf-app/dist/icons/main.css';
  import { useProductStore } from '@/store/productStore';
  import Loader from '@/components/bomg/Loader.vue';
  import { remove } from 'lodash';

  export default defineComponent({
    name: 'GenerateDrawings',
    components: {
      VuePdfApp,
      Loader,
    },
    data() {
      return {
        pngUrl: null as string | null,
        renderRequestId: '' as string,
        isWaiting: false,
        pollingIntervalId: null as number | null,
        isRequestDisabled: false,
        pdfFileUrl: null as string | null,
        visioFileUrl: null as string | null,
        config: {
          toolbar: {
            toolbarViewerLeft: false,
            openFile: false,
            viewBookmark: false,
          },
        },
        progress: 0,
        drawNote: '',
        toleranceHeaders: [
          { title: 'MFA length in meters', value: 'MFA' },
          { title: 'Overall length tolerance in mm', value: 'tolerance_mm' },
          { title: 'Overall length tolerance in inches', value: 'tolerance_in' },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
        toleranceItems: [] as Array<{ MFA: string; tolerance_mm: string; tolerance_in: string }>,
        selectedOrientation: 'Auto',
      };
    },

    props: {
      items: {
        type: Array as () => any[],
        required: false,
        default: () => [],
      },
      polarityData: {
        type: Object as PropType<any>,
        required: false,
        default: () => ({}),
      },
    },
    setup() {
      const productStore = useProductStore();
      return { productStore };
    },

    watch: {
      drawNote: {
        handler(newValue) {
          this.saveToStore();
        },
      },
      selectedOrientation: {
        handler(newValue) {
          this.saveToStore();
        },
      },
      toleranceItems: {
        handler(newValue) {
          this.saveToStore();
        },
        deep: true,
      },
    },
    computed: {
      disableAddNewToleranceLine() {
        return this.toleranceItems.some((item) => !item.MFA || !item.tolerance_mm || !item.tolerance_in);
      },
    },
    methods: {
      handleRequestClick() {
        this.resetState();
        this.mapConnectorsData();

        const productPn = this.productStore.currentProduct.productPn;
        const customerPn = this.productStore.currentProduct.customerPn;
        const description = this.productStore.currentProduct.description;
        const polarity = (this.productStore.currentProduct.polarity as { polarityData?: any })?.polarityData ?? {};
        const tolerance = this.productStore.currentProduct.tolerance;
        const note = this.productStore.currentProduct.note;
        const components = this.productStore.currentProduct.components;

        const patternInfo = (this.productStore.currentProduct.polarity as { patternInfo?: any })?.patternInfo;
        const polarityAdditionalData = {
          pattern: patternInfo?.pattern ?? null,
          connectorsInfo: patternInfo?.connectorsInfo ?? null,
        };

        const requestData = {
          structure: this.items[0],
          product: {
            description,
            productPn,
            customerPn,
            polarityAdditionalData,
            tolerance,
            note,
            components,
          },
          polarity,
        };

        this.sendRenderRequest(requestData, 'customer');
        // console.log('Request Data:', requestData);
      },
      handleManufactureRequestClick() {
        this.resetState();
        this.mapConnectorsData();

        const productPn = this.productStore.currentProduct.productPn;
        const customerPn = this.productStore.currentProduct.customerPn;
        const description = this.productStore.currentProduct.description;
        const polarity = (this.productStore.currentProduct.polarity as { polarityData?: any })?.polarityData ?? {};
        const tolerance = this.productStore.currentProduct.tolerance;
        const note = this.productStore.currentProduct.note;
        const components = this.productStore.currentProduct.components;

        const patternInfo = (this.productStore.currentProduct.polarity as { patternInfo?: any })?.patternInfo;
        const polarityAdditionalData = {
          pattern: patternInfo?.pattern ?? null,
          connectorsInfo: patternInfo?.connectorsInfo ?? null,
        };

        const requestData = {
          structure: this.items[0],
          product: {
            description,
            productPn,
            customerPn,
            polarityAdditionalData,
            tolerance,
            note,
            components,
          },
          polarity,
        };

        this.sendRenderRequest(requestData, 'manufacture');
        // console.log('Request Data:', requestData);
      },

      mapConnectorsData() {
        if (!this.polarityData?.polarityData || !Array.isArray(this.polarityData.polarityData)) {
          return;
        }

        this.polarityData.polarityData = this.polarityData.polarityData.map((item: any) => {
          const connectorAIndex = item.connectorA - 1;
          const connectorBIndex = item.connectorB - 1;

          const connectorAData = this.polarityData.connectorsData[0]?.[connectorAIndex] || {};
          const connectorBData = this.polarityData.connectorsData[1]?.[connectorBIndex] || {};

          return {
            ...item,
            legIdA: connectorAData.leg || null,
            breakoutA: connectorAData.breakout || null,
            connectorTypeA: connectorAData.connector || null,
            legIdB: connectorBData.leg || null,
            breakoutB: connectorBData.breakout || null,
            connectorTypeB: connectorBData.connector || null,
          };
        });
      },

      resetState() {
        this.pngUrl = null;
        this.renderRequestId = '';
        this.isWaiting = false;
        this.clearSessionData();
        this.progress = 0;
      },

      async sendRenderRequest(data: any, type: string) {
        try {
          const serviceMethod = type === 'customer' ? drawingService.requestRender : drawingService.requestManufactureRender;

          const orientation = this.productStore.currentProduct.orientation.toLowerCase();
          const response = await serviceMethod(data, orientation);

          if (response?.code) {
            this.renderRequestId = response.code;
            sessionStorage.setItem('renderID', this.renderRequestId);
            sessionStorage.setItem('renderType', type);
            this.initiatePolling();
            this.fetchDrawing();
            this.isWaiting = true;
          } else {
            this.$log.showError('No code received from server');
          }
        } catch (error) {
          this.$log.showError('Error sending render request', error);
        }
      },

      initiatePolling() {
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
        }

        this.pollingIntervalId = setInterval(() => this.fetchDrawing(), 10000);
      },

      async fetchDrawing() {
        if (!this.renderRequestId) {
          this.$log.showError('No renderRequestId found.');
          return;
        }

        try {
          const renderType = sessionStorage.getItem('renderType') || 'customer';
          const fetchMethod = renderType === 'customer' ? drawingService.getRender : drawingService.getManufactureRender;

          const response = await fetchMethod(this.renderRequestId);

          if (response?.status === 'error') {
            const errorMessage = response.errors?.[0]?.message || 'An unknown error occurred.';
            this.$log.showError(`${errorMessage}`);
            this.stopPolling();
            return;
          }

          if (response?.error) {
            this.$log.showError('Error fetching drawing:', response.errors);
            this.stopPolling();
            return;
          }

          if (response?.progress !== undefined) {
            this.progress = Number(response.progress);
          }

          if (response?.pngUrl) {
            this.handleSuccessfulFetch(response);
          } else {
            this.$log.info('Drawing is still being processed');
          }
        } catch (error) {
          this.$log.showError('Error fetching drawing', error);
          this.cleanupOnError();
        }
      },

      handleSuccessfulFetch(response: any) {
        this.pngUrl = response.pngUrl;
        this.pdfFileUrl = response.pdfUrl;
        this.visioFileUrl = response.vsdxUrl;

        this.$log.info('Drawing fetched successfully:', this.pngUrl);
        this.isWaiting = false;

        if (this.pngUrl) {
          sessionStorage.setItem('pngUrl', this.pngUrl);
        }
        if (this.pdfFileUrl) {
          sessionStorage.setItem('pdfUrl', this.pdfFileUrl);
        }
        if (this.visioFileUrl) {
          sessionStorage.setItem('vsdxUrl', this.visioFileUrl);
        }

        this.stopPolling();
      },

      stopPolling() {
        this.isWaiting = false;
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
        }
        sessionStorage.removeItem('renderID');
      },

      cleanupOnError() {
        this.resetState();
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
        }
      },

      downloadImage() {
        if (!this.pngUrl) return;
        this.downloadFile(this.pngUrl, 'rendered_image.png');
      },

      downloadPDF() {
        if (!this.pdfFileUrl) return;
        this.downloadFile(this.pdfFileUrl, 'rendered_drawing.pdf');
      },

      downloadVisio() {
        if (!this.visioFileUrl) return;
        this.downloadFile(this.visioFileUrl, 'rendered_drawing.vsdx');
      },

      downloadFile(fileUrl: string, filename: string) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        link.click();
        this.$log.info(`File downloaded: ${filename}`);
      },

      addNewToleranceLine() {
        this.toleranceItems.push({ MFA: '', tolerance_mm: '', tolerance_in: '' });
      },
      removeToleranceLine(item: any) {
        remove(this.toleranceItems, item);
      },
      saveToStore() {
        this.productStore.currentProduct.note = this.drawNote;
        this.productStore.currentProduct.orientation = this.selectedOrientation;
        this.productStore.currentProduct.tolerance = this.toleranceItems;
      },
      restoreFromStore() {
        this.drawNote = this.productStore.currentProduct.note;
        this.selectedOrientation = this.productStore.currentProduct.orientation || 'Auto';
        this.toleranceItems = this.productStore.currentProduct.tolerance;
      },

      clearSessionData() {
        sessionStorage.removeItem('pngUrl');
        sessionStorage.removeItem('renderID');
        sessionStorage.removeItem('pdfUrl');
        sessionStorage.removeItem('vsdxUrl');
        sessionStorage.removeItem('renderType');
      },
    },

    mounted() {
      this.restoreFromStore();
      const storedRenderID = sessionStorage.getItem('renderID');
      const storedPngUrl = sessionStorage.getItem('pngUrl');

      if (storedPngUrl) {
        this.pngUrl = storedPngUrl;
        this.pdfFileUrl = sessionStorage.getItem('pdfUrl');
        this.visioFileUrl = sessionStorage.getItem('vsdxUrl');
        this.$log.info('Loaded drawing link from sessionStorage:', storedPngUrl);
      }

      if (storedRenderID && !this.pngUrl) {
        this.renderRequestId = storedRenderID;
        this.$log.info('Found renderID in sessionStorage, starting polling:', this.renderRequestId);
        this.initiatePolling();
        this.isWaiting = true;
      }

      if (!this.items || this.items.length === 0) {
        this.isRequestDisabled = true;
        this.$log.warn('No items provided. Disabling request button.');
      }
    },

    beforeUnmount() {
      if (this.pollingIntervalId) {
        clearInterval(this.pollingIntervalId);
      }
    },
  });
</script>

<style scoped>
  .content-col {
    padding: 0px 12px;
    margin-top: -20px;
  }
  .content-col-wait {
    padding: 0px 12px;
    margin-top: -20px;
    height: calc(100vh - 164px);
    min-height: 400px;
  }

  .sidebar-col {
    padding: 0px 12px;
    text-align: center;
  }

  .sidebar-btn-col {
    padding: 0px 12px;
    text-align: center;
  }

  .no-data-v2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .highlight {
    color: orange;
  }

  .btn-orange {
    background-color: orange;
    color: #fff;
  }

  .full-width {
    width: 100%;
  }

  .bold-text {
    font-weight: bold;
  }

  .divider {
    border-top: 1px solid #ccc;
    margin: 16px 0;
  }

  .dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 50px auto;
  }

  .dots span {
    width: 15px;
    height: 15px;
    background-color: orange;
    border-radius: 50%;
    animation: bounce 1.2s infinite;
  }

  .dots span:nth-child(1) {
    animation-delay: 0s;
  }

  .dots span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dots span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.5);
    }
  }

  .float-btn {
    position: absolute;
    bottom: 6%;
    right: 2%;
  }

  .pdf-app.light {
    --pdf-app-background-color: white;
  }

  :deep(.pdf-app .pdfViewer .page) {
    border-image: none !important;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }

  :deep(.v-table tr:hover) {
    background-color: #f9f9f9;
  }
  :deep(.v-table tr) {
    height: 44px;
  }
  :deep(.v-table td) {
    border: none !important;
  }

  .additional-main-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
  }
  .additional-title {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
  }
  .request-btn {
    width: 400px;
  }
</style>
