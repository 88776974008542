<!-- src/components/Loader.vue -->
<template>
  <div class="circular-progress">
    <svg class="spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
    </svg>
    <div v-if="progress !== null && progress !== undefined" class="percentage">{{ progress }}%</div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';

  export default defineComponent({
    name: 'Loader',
    props: {
      progress: {
        type: Number,
        required: false,
        default: null,
      },
    },
    setup(props) {
      const progress = computed(() => {
        return props.progress;
      });

      return {
        progress,
      };
    },
  });
</script>

<style scoped>
  .circular-progress {
    position: relative;
    width: 120px;
    height: 120px;
  }

  .spinner {
    animation: rotate 2s linear infinite;
    width: 100%;
    height: 100%;
  }

  .path {
    stroke: #ffa500;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: #3b3b3b;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
</style>
