<template>
  <v-dialog v-model="showReplaceModal" max-width="1100px">
    <v-card>
      <v-card-title class="modal-title pr-2">
        Replace Raw Material in BOMs
        <v-icon @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="5" style="padding: 0">
              <p class="field-title">Current Material:</p>
              <v-text-field v-model="currentMaterialValue" placeholder="Enter Material Name" outlined dense hide-details variant="underlined" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="ml-5" style="padding: 0">
              <p class="field-title">
                Description:
                <span class="desc-text">{{ desc }}</span>
              </p>
              <p class="field-title">
                UOM:
                <span class="desc-text">Meters</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 mb-1">
              <v-card-actions class="pa-0">
                <v-spacer></v-spacer>
                <v-btn class="btn-cancel" variant="text" @click="selectAllBoms">Select all BOMs</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-0">
              <div class="table-container">
                <v-data-table
                  :loading="loading"
                  :items="bomsWithUniqueIds"
                  :headers="headers"
                  item-value="uniqueId"
                  density="compact"
                  class="nested-table"
                  v-model:items-per-page="itemsPerPage"
                  :items-per-page-options="itemsPerPageOptions"
                  show-select
                  v-model="selected"
                  fixed-header
                >
                  <template v-slot:[`item.referenceType`]="{ item }">
                    <v-chip :color="item.referenceType === 'certified' ? 'success' : 'error'" small>
                      {{ item.referenceType === 'certified' ? 'Certified' : 'Not Certified' }}
                    </v-chip>
                  </template>

                  <template v-slot:[`item.timestamps`]="{ item }">
                    <div class="timestamps">
                      <div class="d-flex flex-column">
                        <span class="text-caption">Created: {{ formatDate(item.createdAt) }}</span>
                        <span class="text-caption text-grey">Last updated: {{ formatDate(item.updatedAt) }}</span>
                      </div>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-9">
            <div class="top-replace w-100">
              <p class="field-title">Replacement BOM lines:</p>
              <v-btn @click="openSearchByMaterialModal" class="btn-orange" :disabled="!currentMaterialValue">ADD replacement raw material</v-btn>
            </div>

            <v-col cols="12" v-if="replacementMaterial" class="mt-4 pa-4 material-details-card">
              <div class="d-flex align-center mb-2">
                <p class="field-title mb-0">Component PN:</p>
                <span class="desc-text ml-2">{{ replacementMaterial.pn }}</span>
              </div>

              <div class="d-flex align-start mb-2">
                <p class="field-title mb-0">Description:</p>
                <span class="desc-text ml-2" style="flex: 1">{{ replacementMaterial.description }}</span>
              </div>

              <div class="d-flex align-center mb-2" v-if="replacementMaterial.uom">
                <p class="field-title mb-0">UOM:</p>
                <span class="desc-text ml-2">{{ replacementMaterial.uom }}</span>
              </div>

              <div class="d-flex align-center mb-2" v-if="replacementMaterial.barcode">
                <p class="field-title mb-0">Barcode:</p>
                <span class="desc-text ml-2">{{ replacementMaterial.barcode }}</span>
              </div>

              <div class="d-flex justify-end mt-4">
                <v-btn color="error" variant="text" size="small" @click="clearReplacementMaterial">
                  <v-icon small class="mr-1">mdi-delete</v-icon>
                  Remove
                </v-btn>
                <v-btn color="primary" variant="text" size="small" class="ml-2" @click="openSearchByMaterialModal">
                  <v-icon small class="mr-1">mdi-pencil</v-icon>
                  Change
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" v-else class="mt-4 text-center grey--text">
              <p>No replacement material selected. Click "ADD replacement raw material" to select one.</p>
            </v-col>
          </v-row>

          <v-row class="mt-9">
            <div class="checkboxes">
              <v-checkbox v-model="updateCertBoms" label="Update certified BOMs" class="mt-4" hide-details color="orange" density="compact"></v-checkbox>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel mr-2" @click="close">Close</v-btn>
        <v-btn class="btn-orange" @click="handleBomMaterialReplacement" data-cy="search-button" :disabled="!replacementMaterial || selected.length === 0">
          Replace Material in Selected BOMs
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Material search modal (similar to Boms.vue) -->
    <v-dialog v-model="showSearchByMaterialModal" max-width="600px" persistent>
      <v-card>
        <v-card-title class="modal-title" data-cy="compare-modal-title">
          <span class="text-h5">Add replacement raw material</span>
          <v-icon small @click="showSearchByMaterialModal = false" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
        </v-card-title>
        <br />

        <v-card-subtitle>Select Material:</v-card-subtitle>

        <v-card-text>
          <v-combobox
            v-model="selectedMaterial"
            :items="odooMaterialsOptions"
            item-title="pn"
            item-value="pn"
            label="Search and Select Material (min 3 characters)"
            clearable
            filterable
            :loading="loadingMaterials"
            @update:search="handleSearchMaterial"
            @update:modelValue="setMaterialDetails"
            hide-details
            variant="underlined"
          >
            <template #item="{ props, item }">
              <v-list-item style="position: relative; max-width: 550px" v-bind="props">
                <template #title>
                  <span style="color: black">{{ item.value }}</span>
                </template>
                <template #subtitle>
                  <v-tooltip location="left">
                    <template #activator="{ props: tooltipActivatorProps }">
                      <span v-bind="tooltipActivatorProps" style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                        {{ item.raw.description }}
                      </span>
                    </template>
                    <div style="width: 250px; white-space: normal">
                      {{ item.raw.description }}
                    </div>
                  </v-tooltip>
                </template>
              </v-list-item>
            </template>
          </v-combobox>

          <div v-if="selectedMaterialDetails" style="margin-top: 16px">
            <p>
              <strong>Component PN:</strong>
              {{ selectedMaterialDetails.pn }}
            </p>
            <p>
              <strong>Description:</strong>
              {{ selectedMaterialDetails.description }}
            </p>
            <p v-if="selectedMaterialDetails.uom">
              <strong>UOM:</strong>
              {{ selectedMaterialDetails.uom }}
            </p>
            <p v-if="selectedMaterialDetails.barcode">
              <strong>Barcode:</strong>
              {{ selectedMaterialDetails.barcode }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn block class="btn-orange" @click="addMaterialToBomLines" data-cy="add-material-button" :disabled="!selectedMaterialDetails">ADD RAW MATERIAL</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editBomLines" max-width="80%" data-cy="compare-modal" persistent class="custom-dialog">
      <v-card>
        <v-card-title class="modal-title" data-cy="compare-modal-title">
          <span class="text-h5">Add replacement BOM Lines</span>
          <v-icon small @click="editBomLines = false" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
        </v-card-title>
        <v-card-actions class="px-6 pb-4">
          <v-spacer></v-spacer>
          <v-btn class="btn-cancel" @click="editBomLines = false" data-cy="cancel-btn">Cancel</v-btn>
          <v-btn class="btn-save" @click="saveBom" data-cy="done-btn">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import BomDataTable from '@/components/bomg/bom/BomTable.vue';
  import bomService from '@/services/api/bomService';
  import etcService from '@/services/api/etcService';

  export default defineComponent({
    name: 'ReplaceMaterialModal',

    components: {
      BomDataTable,
    },

    props: {
      showReplaceModal: {
        type: Boolean,
        required: true,
      },
      currentMaterial: {
        type: Object,
        default: () => ({ pn: '', odoo_id: null, description: '' }),
      },
    },

    emits: ['update:showReplaceModal', 'close'],

    data() {
      return {
        itemsPerPage: 50,
        itemsPerPageOptions: [10, 25, 50, 100, -1],
        dialog: false,
        currentMaterialValue: '',
        desc: '',
        loading: false,
        headers: [
          { title: 'Product Part Number', value: 'productPn', width: '35%' },
          { title: 'Product Info', value: 'reference', width: '25%' },
          { title: 'Certification', value: 'referenceType', width: '5%', sortable: false },
          { title: 'Timestamps', value: 'timestamps', width: '35%', sortable: false },
        ],
        boms: [] as any[],
        showSearchByMaterialModal: false,
        certifiedBoms: [] as any[],
        editBomLines: false,
        updateCertBoms: false,
        incRevBom: false,
        archiveAffectedBom: false,
        shouldRunSearch: false,
        selected: [] as string[],
        selectedMaterial: null as any,
        selectedMaterialDetails: null as any,
        odooMaterialsOptions: [] as any[],
        loadingMaterials: false,
        replacementMaterial: null as any,
      };
    },

    computed: {
      bomsWithUniqueIds() {
        return this.boms.map((bom) => ({
          ...bom,
          uniqueId: `${bom.productPn}-${bom.reference}`,
        }));
      },
    },

    watch: {
      showReplaceModal(newVal) {
        if (newVal && this.currentMaterial) {
          this.currentMaterialValue = this.currentMaterial.pn || '';
          this.desc = this.currentMaterial.description || '';
          this.shouldRunSearch = true;
        }
      },
      '$data.shouldRunSearch'(newVal) {
        if (newVal && this.showReplaceModal) {
          this.getBomsbyMaterialPN();
          this.shouldRunSearch = false;
        }
      },

      currentMaterial: {
        handler(newVal) {
          if (newVal) {
            this.currentMaterialValue = newVal.pn || '';
            this.desc = newVal.description || '';
          }
        },
        deep: true,
      },
    },

    methods: {
      saveBom() {
        this.editBomLines = false;
      },

      close() {
        this.clearSelections();
        // Set to empty string, not empty object
        this.currentMaterialValue = '';
        this.$emit('update:showReplaceModal', false);
        this.$emit('close');
      },

      formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },

      async getBomsbyMaterialPN() {
        this.loading = true;
        this.boms = [];
        try {
          const materialPn = this.currentMaterialValue;
          const allOdooBoms = await bomService.bomsOdooByMaterial(materialPn);
          this.boms = [...allOdooBoms.certifiedBoms, ...allOdooBoms.boms];
          this.certifiedBoms = allOdooBoms.certifiedBoms;
        } catch (error) {
          this.$error.view(error);
        } finally {
          this.loading = false;
        }
      },

      selectAllBoms() {
        this.selected = this.bomsWithUniqueIds.map((bom) => bom.uniqueId);
        console.log('Selected all BOMs. Count:', this.selected.length);
      },

      clearSelections() {
        this.selected = [];
        this.replacementMaterial = null;
        this.updateCertBoms = false;
      },

      openSearchByMaterialModal() {
        this.selectedMaterial = null;
        this.selectedMaterialDetails = null;
        this.odooMaterialsOptions = [];
        this.showSearchByMaterialModal = true;
      },

      async handleSearchMaterial(query: string) {
        if (!query || query.length < 3) return;

        this.loadingMaterials = true;
        try {
          const response = await etcService.getOdooMaterials(query);
          this.odooMaterialsOptions = response
            .filter((material: any) => material.active)
            .map((material: any) => {
              const regex = /^\[(.*?)\]\s*(.*)$/;
              const match = material.display_name.match(regex);

              return {
                pn: match ? match[1] : '',
                description: match ? match[2] : '',
                odoo_id: material.id,
                uom: material?.uom_id?.val || '',
                barcode: material.barcode || '',
              };
            });
        } catch (error) {
          this.$error.view(error);
        } finally {
          this.loadingMaterials = false;
        }
      },

      setMaterialDetails(material: any) {
        if (!material) {
          this.selectedMaterialDetails = null;
          return;
        }

        const pn = material.pn;
        const foundMaterial = this.odooMaterialsOptions.find((m: any) => m.pn === pn);
        if (foundMaterial) {
          this.selectedMaterialDetails = foundMaterial;
        }
      },

      addMaterialToBomLines() {
        if (this.selectedMaterialDetails) {
          this.replacementMaterial = {
            pn: this.selectedMaterialDetails.pn,
            odoo_id: this.selectedMaterialDetails.odoo_id,
            description: this.selectedMaterialDetails.description,
            uom: this.selectedMaterialDetails.uom || 'Each',
            barcode: this.selectedMaterialDetails.barcode || '',
          };

          this.showSearchByMaterialModal = false;
        }
      },

      clearReplacementMaterial() {
        this.replacementMaterial = null;
      },

      cleanAndClose() {
        this.clearSelections();
        this.$emit('update:showReplaceModal', false);
        this.$emit('close');
      },

      async handleBomMaterialReplacement() {
        if (!this.replacementMaterial || !this.currentMaterialValue) {
          this.$error.view('Both current and replacement materials are required');
          return;
        }

        if (this.selected.length === 0) {
          this.$error.view('Please select at least one BOM to update');
          return;
        }

        try {
          const payload: any = {
            targetMaterial: {
              pn: this.currentMaterialValue,
              description: this.desc,
              odoo_id: this.currentMaterial.odoo_id,
            },
            replacementMaterial: {
              pn: this.replacementMaterial.pn,
              odoo_id: this.replacementMaterial.odoo_id,
              description: this.replacementMaterial.description,
              uom: this.replacementMaterial.uom || 'Each',
              barcode: this.replacementMaterial.barcode || '',
            },
          };

          const notificationMessage = `The raw material replacement from <b>${this.currentMaterialValue}</b> to <b>${this.replacementMaterial.pn}</b> has been initiated. Once the operation is done, we will notify you through BOM updates slack channel.`;

          const certifiedBoms = this.boms.filter((bom) => bom.certificationData && bom.referenceType === 'certified');
          const certifiedPns = certifiedBoms.map((bom) => bom.productPn);
          const selectedBoms = this.bomsWithUniqueIds.filter((bom) => this.selected.includes(bom.uniqueId));

          if (this.updateCertBoms) {
            payload.certifiedPns = certifiedPns;
            const selectedUncertifiedBoms = selectedBoms.filter((bom) => !bom.certificationData || bom.referenceType !== 'certified');
            payload.bomIds = selectedUncertifiedBoms.map((bom) => bom.productPn);
          } else {
            payload.bomIds = selectedBoms.map((bom) => bom.productPn);
          }

          this.$log.showSuccessMaterial(notificationMessage);
          this.cleanAndClose();

          try {
            await bomService.bomMaterialReplacer(payload);
          } catch (apiError) {
            this.$log.showError(`Error on material replacement: ${apiError}`);
          }
        } catch (error) {
          this.$error.view(error);
          this.cleanAndClose();
        }
      },
    },
  });
</script>

<style scoped>
  .table-container {
    height: calc(100vh - 400px); /* Adjust the "400px" based on your layout */
    min-height: 200px;
    overflow-y: auto;
    overflow-x: auto;
  }

  .v-data-table--fixed-header .v-data-table__wrapper {
    overflow-y: auto;
  }

  ::v-deep(.v-data-table__bottom) {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 1;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ::v-deep(.v-data-table__wrapper) {
    max-height: unset !important;
  }
  .modal-title {
    background-color: #3b516b;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .field-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .desc-text {
    font-size: 14px;
    font-weight: 400;
  }
  .top-replace {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }
  .material-details-card {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
</style>
