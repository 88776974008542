<template>
  <v-row>
    <v-col cols="12" md="3" class="mt-2">
      <h3 class="mb-2">Target BOM</h3>
      <!-- <p>{{ generation_item }}</p> -->
      <!-- {{  referenceBomPn }} -->
      <v-row>
        <v-col cols="12" class="mt-2">
          <v-select
            :items="[{ title: 'Generated BOM', disabled: true }]"
            v-model="selectedTarget"
            item-text="title"
            item-value="title"
            :item-disabled="(item: any) => item.disabled"
            variant="underlined"
            density="compact"
            label="Target"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :disabled="item.raw.disabled"></v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col cols="12">
          <v-text-field v-model="generation_item" variant="underlined" density="compact" label="Selected PN" readonly></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" class="mt-4"></v-col>
    <v-col cols="12" md="3" class="mt-2">
      <h3 class="mb-2">Reference BOM</h3>
      <v-row>
        <v-col cols="12" class="mt-2">
          <v-select
            :items="sourceList"
            item-text="title"
            item-value="title"
            :item-disabled="(item: any) => item.disabled"
            v-model="selectedSource"
            variant="underlined"
            density="compact"
            label="Source"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :disabled="item.raw.disabled"></v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row style="margin-top: -30px">
        <v-col cols="12">
          <v-combobox
            v-model="selectedReference"
            :loading="referenceOptionsLoading"
            :items="referenceOptions"
            variant="underlined"
            density="compact"
            label="Selected PN"
            @keydown.enter="fetchOdooData"
          ></v-combobox>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" class="mt-4">
      <v-row style="margin-top: 70px">
        <v-col cols="8">
          <v-select
            v-if="referenceOptionRevision.length > 1"
            :items="referenceOptionRevision"
            v-model="selectedReferenceOptionRevision"
            item-title="reference"
            item-value="id"
            variant="underlined"
            density="compact"
            label="Revision"
          ></v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" class="text-center">
      <v-btn :disabled="compareDisabled" style="width: 33%" class="btn-orange" @click="compare">Compare</v-btn>
    </v-col>
  </v-row>
  <v-divider :thickness="2" class="mb-3 mt-4"></v-divider>

  <v-row>
    <v-col class="comparison-col">
      <div class="d-flex align-center justify-space-between w-100 comparison-status">
        <span class="comparison-title">
          <h2>
            Comparison Status
            <v-chip
              v-if="compareData?.summary"
              text-color="white"
              density="comfortable"
              size="large"
              :class="['status-chip', compareData?.summary?.comparisonStatus ? 'chip-' + compareData.summary.comparisonStatus : 'default-class']"
            >
              {{ compareData?.summary?.comparisonStatus ? compareData.summary.comparisonStatus.charAt(0).toUpperCase() + compareData.summary.comparisonStatus.slice(1) : 'N/A' }}
            </v-chip>
          </h2>
          <span class="comparison-title-details" v-if="compareData?.summary">
            <span class="mr-4">
              {{ compareData?.summary?.identicalLines ?? 'N/A' }}/{{ compareData?.summary?.totalLines ?? 'N/A' }}
              <span class="small-text">Identical BOM Lines</span>
            </span>

            <span class="mr-4">
              {{ compareData?.summary?.similarLines ?? 'N/A' }}/{{ compareData?.summary?.totalLines ?? 'N/A' }}
              <span class="small-text">Similar BOM Lines</span>
            </span>

            <span>
              {{ compareData?.summary?.differentLines ?? 'N/A' }}/{{ compareData?.summary?.totalLines ?? 'N/A' }}
              <span class="small-text">Different BOM Lines</span>
            </span>
          </span>
        </span>
      </div>
    </v-col>
  </v-row>
  <v-divider :thickness="2" class="mt-4 mb-5"></v-divider>
  <v-row class="align-center justify-space-between">
    <v-col cols="auto" class="d-flex align-center">
      <v-btn value="line-by-line" @click="setViewMode('line-by-line')" :class="['viewMode-btn', viewMode === 'line-by-line' ? 'selected-toggle active-toggle' : 'inactive-toggle']">
        Line-by-line
      </v-btn>
      <v-btn
        value="only-differences"
        v-if="compareData?.summary?.comparisonStatus !== 'identical'"
        @click="setViewMode('only-differences')"
        :class="['viewMode-btn', viewMode === 'only-differences' ? 'selected-toggle active-toggle' : 'inactive-toggle']"
      >
        Only Differences
      </v-btn>
    </v-col>

    <!-- Legend -->
    <v-col cols="auto" class="d-flex align-center justify-end">
      <div class="legend d-flex">
        <!-- Identical/Proportional -->
        <n-tooltip trigger="hover" placement="top" teleport="body" :z-index="9999">
          <template #trigger>
            <div class="d-flex align-center mr-4" style="cursor: pointer">
              <span class="legend-demo" style="background-color: #5baa64; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56"></span>
              <span class="comparison-value-desc ml-2">Identical/Proportional</span>
            </div>
          </template>
          <div>
            <span>
              <span style="background-color: #5baa64; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbspIdentical - Exact match
            </span>
            <br />
            <span>
              <span style="background-color: #5baa64; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp
              <span style="background-color: #d03e2e; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbspProportional - Scaled values (in red)
            </span>
          </div>
        </n-tooltip>

        <!-- Similar -->
        <n-tooltip trigger="hover" placement="top" teleport="body" :z-index="9999">
          <template #trigger>
            <div class="d-flex align-center mr-4" style="cursor: pointer">
              <span class="legend-demo" style="background-color: #f6e7a8; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56"></span>
              <span class="comparison-value-desc ml-2">Similar</span>
            </div>
          </template>
          <div>
            <span>
              <span style="background-color: #f6e7a8; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp
              <span style="background-color: #d03e2e; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp Different quantities (in red)
            </span>
          </div>
        </n-tooltip>

        <!-- Different -->
        <n-tooltip trigger="hover" placement="top" teleport="body" :z-index="9999">
          <template #trigger>
            <div class="d-flex align-center mr-4" style="cursor: pointer">
              <span class="legend-demo" style="background-color: #d03e2e; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56"></span>
              <span class="comparison-value-desc ml-2">Different</span>
            </div>
          </template>
          <div>
            <span>
              <span style="background-color: #d03e2e; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp Different material
            </span>
          </div>
        </n-tooltip>

        <!-- Missing -->
        <n-tooltip trigger="hover" placement="top" teleport="body" :z-index="9999">
          <template #trigger>
            <div class="d-flex align-center mr-4" style="cursor: pointer">
              <span
                class="legend-demo"
                style="
                  background: repeating-linear-gradient(45deg, transparent, transparent 10px, #ebebeb 10px, #ebebeb 11px);
                  width: 20px;
                  height: 20px;
                  border-radius: 5%;
                  border: 1px solid #123d56;
                "
              ></span>
              <span class="comparison-value-desc ml-2">Missing</span>
            </div>
          </template>
          <div>
            <span>
              <span style="background-color: #ebebeb; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              &nbsp
              <span style="background-color: #d03e2e; width: 20px; height: 20px; border-radius: 5%; border: 1px solid #123d56">&nbsp&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              Not in target/reference
            </span>
          </div>
        </n-tooltip>
      </div>
    </v-col>
  </v-row>
  <v-row class="mt-5">
    <v-col cols="12" md="6" style="padding-right: 0px">
      <span class="table-title">Target BOM Lines</span>
      <v-data-table :headers="targetHeaders" :items="filteredTargetBom" class="elevation-1" hide-default-footer density="compact" :items-per-page="-1">
        <template v-slot:body="{ items }">
          <template v-if="items.length">
            <tr v-for="(item, index) in items" :key="index" :class="GetCompareStatus(item)">
              <!-- Material -->
              <td>
                <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                  <template #trigger>
                    <span style="text-wrap: nowrap">{{ (item as BomLine).material?.pn }}</span>
                  </template>
                  {{ (item as BomLine).material?.description }}
                </n-tooltip>
              </td>

              <!-- Cut Size -->
              <td class="text-end" v-if="(item as BomLine).cutSize !== 0">
                <template v-if="item.differences?.values?.cutSize">
                  <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                    <template #trigger>
                      <span class="diff">{{ (item as BomLine).cutSize.toFixed(2) }}</span>
                    </template>
                    Reference value: {{ item.differences.values.cutSize.toFixed(2) }}
                  </n-tooltip>
                </template>
                <template v-else>
                  {{ (item as BomLine).cutSize.toFixed(2) }}
                </template>
              </td>
              <td v-else></td>

              <!-- Cut UOM -->
              <td>
                <template v-if="item.differences?.values?.cutUom">
                  <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                    <template #trigger>
                      <span class="diff">{{ (item as BomLine).cutUom }}</span>
                    </template>
                    Reference value: {{ item.differences.values.cutUom }}
                  </n-tooltip>
                </template>
                <template v-else>
                  {{ (item as BomLine).cutUom }}
                </template>
              </td>

              <!-- Cut Quantity -->
              <td class="text-end" v-if="(item as BomLine).cutQuantity !== 0">
                <template v-if="item.differences?.values?.cutQuantity">
                  <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                    <template #trigger>
                      <span class="diff">{{ (item as BomLine).cutQuantity.toFixed(2) }}</span>
                    </template>
                    Reference value: {{ item.differences.values.cutQuantity.toFixed(2) }}
                  </n-tooltip>
                </template>
                <template v-else>
                  {{ (item as BomLine).cutQuantity.toFixed(2) }}
                </template>
              </td>
              <td v-else></td>

              <!-- Quantity  -->
              <td class="text-end" v-if="(item as BomLine).quantity !== 0">
                <template v-if="item.differences?.values?.quantity">
                  <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                    <template #trigger>
                      <span class="diff">{{ (item as BomLine).quantity.toFixed(2) }}</span>
                    </template>
                    Reference value: {{ item.differences.values.quantity.toFixed(2) }}
                  </n-tooltip>
                </template>
                <template v-else>
                  {{ (item as BomLine).quantity.toFixed(2) }}
                </template>
              </td>
              <td v-else></td>

              <!-- UOM  -->
              <td>
                <template v-if="item.differences?.values?.uom">
                  <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                    <template #trigger>
                      <span class="diff">{{ (item as BomLine).uom }}</span>
                    </template>
                    Reference value: {{ item.differences.values.uom }}
                  </n-tooltip>
                </template>
                <template v-else>
                  {{ (item as BomLine).uom }}
                </template>
              </td>

              <!-- Consumed Operation  -->
              <td>
                <template v-if="item.differences?.values?.consumedOperation">
                  <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                    <template #trigger>
                      <span class="diff">{{ (item as BomLine).consumedOperation }}</span>
                    </template>
                    Reference value: {{ item.differences.values.consumedOperation }}
                  </n-tooltip>
                </template>
                <template v-else>
                  {{ (item as BomLine).consumedOperation }}
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9" class="text-center">
                No
                <strong>target BOM</strong>
                data
              </td>
            </tr>
          </template>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="6" style="padding-left: 0px">
      <span class="table-title">Reference BOM Lines</span>
      <v-data-table :headers="referenceHeaders" :items="filteredReferenceBom" class="elevation-1" hide-default-footer density="compact" :items-per-page="-1">
        <template v-slot:body="{ items }">
          <template v-if="items.length">
            <tr v-for="(item, index) in items" :key="index" :class="'reference-' + GetCompareStatus(item)">
              <td>
                <n-tooltip trigger="hover" teleport="body" :z-index="9999">
                  <template #trigger>
                    <span style="text-wrap: nowrap">{{ (item as BomLine).material?.pn }}</span>
                  </template>
                  {{ (item as BomLine).material?.description }}
                </n-tooltip>
              </td>
              <td class="text-end" v-if="(item as BomLine).cutSize !== 0">{{ (item as BomLine).cutSize.toFixed(2) }}</td>
              <td v-else></td>
              <td>{{ (item as BomLine).cutUom }}</td>
              <td class="text-end" v-if="(item as BomLine).cutQuantity !== 0">{{ (item as BomLine).cutQuantity.toFixed(2) }}</td>
              <td v-else></td>
              <td class="text-end" v-if="(item as BomLine).quantity !== 0">{{ (item as BomLine).quantity.toFixed(2) }}</td>
              <td v-else></td>
              <td>{{ (item as BomLine).uom }}</td>
              <td>{{ (item as BomLine).consumedOperation }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9" class="text-center">
                Please select
                <strong>reference BOM</strong>
                source
              </td>
            </tr>
          </template>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import bomService from '@/services/api/bomService';
  import odooService from '@/services/api/odooService';

  interface BomLine {
    material: { pn: string; description: string };
    cutSize: number;
    cutUom: string;
    cutQuantity: number;
    quantity: number;
    uom: string;
    consumedOperation: string;
    comparisonStatus?: string;
    differences?: any;
  }

  interface ReferenceBom {
    productPn: string;
    routing?: string;
    bomLines: BomLine[];
    id?: any;
    reference?: string;
    referenceType?: string;
  }
  export default defineComponent({
    name: 'GenerateCompare',
    props: {
      currentBom: {
        type: Array as PropType<BomLine[]>,
        required: false,
        default: () => [],
      },
      generation_item: {
        type: String,
        required: false,
        default: () => '',
      },
      referenceBomPn: {
        type: String,
        required: false,
      },

      loadingData: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        referenceBomList: [] as ReferenceBom[],
        odooBomList: [] as any,
        sourceList: [
          { title: 'Reference', disabled: false },
          { title: 'LocalStorage', disabled: true },
          { title: 'Odoo13', disabled: false },
          { title: 'Odoo17', disabled: true },
          { title: 'Certified', disabled: false },
        ],
        selectedTarget: 'Generated BOM',
        selectedSource: null as string | null,
        selectedReference: null as string | null,
        referenceOptions: [] as string[],
        referenceOptionRevision: [] as { pn: string; reference: string; id: any }[],
        referenceOptionsLoading: false,
        selectedReferenceOptionRevision: null,
        isCollapsed: true,
        viewMode: 'line-by-line',
        identicalLines: 20,
        totalLines: 20,
        similarLines: 5,
        differentLines: 1,

        targetBom: [] as BomLine[],
        targetHeaders: [
          { title: 'Component', value: 'materials', sortable: false, width: '30%' },
          { title: 'Cut Size', value: 'cutSize', sortable: false, width: '5%', align: 'end' as const },
          { title: 'Cut UoM', value: 'cutUom', sortable: false, width: '13%' },
          { title: 'Cuts Num', value: 'cutQuantity', sortable: false, width: '5%', align: 'end' as const },
          { title: 'QTY', value: 'quantity', sortable: false, width: '10%', align: 'end' as const },
          { title: 'UOM', value: 'uom', sortable: false, width: '13%' },
          { title: 'Consumed in', value: 'consumedOperation' },
        ],

        targetBomLines: [],

        referenceHeaders: [
          { title: 'Component', value: 'materials', sortable: false, width: '30%' },
          { title: 'Cut Size', value: 'cutSize', sortable: false, width: '5%', align: 'end' as const },
          { title: 'Cut UoM', value: 'cutUom', sortable: false, width: '13%' },
          { title: 'Cuts Num', value: 'cutQuantity', sortable: false, width: '5%', align: 'end' as const },
          { title: 'QTY', value: 'quantity', sortable: false, width: '10%', align: 'end' as const },
          { title: 'UOM', value: 'uom', sortable: false, width: '13%' },
          { title: 'Consumed in', value: 'consumedOperation' },
        ],
        originalTargetBom: [] as BomLine[],
        referenceBomLines: [] as BomLine[],
        compareData: {} as any,
        compareTarget: [] as BomLine[],
        compareReference: [] as BomLine[],
        compareDisabled: false,
      };
    },

    mounted() {
      this.selectedSource = 'Reference';
      // console.log('Current BOM:', this.currentBom);
      this.originalTargetBom = this.currentBom.map((item: any) => {
        const newItem = { ...item };
        if (Array.isArray(newItem.materials) && newItem.materials.length > 0) {
          newItem.material = newItem.materials[0];
        }
        return newItem;
      });
      this.targetBom = this.currentBom.map((item: any) => {
        const newItem = { ...item };
        if (Array.isArray(newItem.materials) && newItem.materials.length > 0) {
          newItem.material = newItem.materials[0];
        }
        return newItem;
      });
      if (this.referenceBomPn && this.referenceBomPn.length > 0) {
        this.selectedSource = 'Certified';

        setTimeout(() => {
          this.compare();
        }, 1000);
      }
    },
    watch: {
      selectedSource(newSource) {
        this.selectedReference = this.generation_item;
        this.fetchDataForSource(newSource);
      },
      selectedReference() {
        this.populateReferenceRevisions();
      },
      referenceOptionRevision(newRevisions) {
        if (newRevisions.length > 0) {
          this.selectedReferenceOptionRevision = newRevisions[0].id;
        }
      },
    },
    computed: {
      filteredTargetBom() {
        if (this.viewMode === 'only-differences') {
          return this.targetBom.filter((item) => item.comparisonStatus !== 'identical');
        }
        return this.targetBom;
      },
      filteredReferenceBom() {
        if (this.viewMode === 'only-differences') {
          return this.referenceBomLines.filter((item) => item.comparisonStatus !== 'identical');
        }
        return this.referenceBomLines;
      },
      referenceBom() {
        return this.referenceBomLines;
      },
    },
    methods: {
      SearchReference() {
        console.log('SearchReference');
      },
      setViewMode(mode: string) {
        this.viewMode = mode;
      },
      fetchDataForSource(source: string) {
        switch (source) {
          case 'LocalStorage': {
            const storedData = JSON.parse(localStorage.getItem('bom_list') || '{}');
            this.selectedReference = storedData?.productPn || null;
            break;
          }
          case 'Reference': {
            this.selectedReference = null;
            this.getReferenceBomList();
            break;
          }
          case 'Certified': {
            this.selectedReference = null;
            this.getCertifiedBomList();
            break;
          }
          case 'Odoo13': {
            this.getReferenceOdoo13();
            console.log('Selected source is Odoo13.');
            break;
          }
          default: {
            console.log('Selected source is invalid or not LocalStorage.');
          }
        }
      },
      compare() {
        if (!this.filteredTargetBom.length) {
          this.$log.showWarning(`No Target: No data available in the target BOM.`);
          return;
        }

        this.clearComparisonData();

        // Prepare
        this.targetBom = [...this.originalTargetBom];
        const targetBomData = {
          product_pn: this.generation_item,
          routing: '',
          bom_lines: this.mapBomLines(this.targetBom),
        };

        let referenceBomData = null;

        switch (this.selectedSource) {
          case 'LocalStorage': {
            const referenceBom = JSON.parse(localStorage.getItem('bom_list') || '[]');
            referenceBomData = {
              product_pn: referenceBom.productPn || '',
              routing: referenceBom.routing || '',
              bom_lines: this.mapBomLines(referenceBom.bomLines || []),
            };
            break;
          }

          case 'Reference':
          case 'Certified': {
            const reference = this.selectedReferenceOptionRevision;
            const matchedBom = this.referenceBomList.find((bom) => bom.id === reference);
            if (matchedBom) {
              referenceBomData = {
                product_pn: matchedBom.productPn,
                routing: matchedBom.routing || 'this.selectedRouting',
                bom_lines: this.mapBomLines(matchedBom.bomLines || []),
              };
            } else {
              console.log('No matching BOM found in the reference BOM list.');
              return;
            }
            break;
          }

          case 'Odoo13': {
            referenceBomData = {
              product_pn: '',
              routing: '',
              bom_lines: this.mapBomLines(this.odooBomList?.bomLines || []),
            };
            break;
          }

          default:
            console.log('Selected source is invalid.');
            return;
        }

        if (referenceBomData) {
          const data = {
            targetBom: targetBomData,
            referenceBom: referenceBomData,
          };
          this.referenceBomLines = referenceBomData.bom_lines;
          this.compareBom(data);
        }
      },
      clearComparisonData() {
        this.targetBom = [];
        this.referenceBomLines = [];
        this.compareData = {};
      },

      mapBomLines(bomLines: any[]) {
        return bomLines.map((line: any) => ({
          material: {
            pn: line.materials?.pn || line.material?.pn || '',
            description: line.materials?.description || line.material?.description || '',
          },
          cutSize: line.cutSize || 0,
          cutUom: line.cutUom || '',
          cutQuantity: line.cutQuantity || 0,
          uom: line.uom || '',
          quantity: line.quantity || 0,
          consumedOperation: line.consumedOperation || '',
        }));
      },
      async getReferenceBomList() {
        this.referenceOptionsLoading = true;
        try {
          const bomList = await bomService.bomList();
          this.referenceBomList = bomList;

          const uniquePns: string[] = Array.from(new Set(bomList.map((bom: any) => bom.productPn)));
          this.referenceOptions = uniquePns;

          this.referenceBomList = bomList;
          console.log('Reference BOM List:', this.referenceBomList);
          this.selectSimilarReference(this.generation_item);
          this.referenceOptionsLoading = false;
        } catch (error: any) {
          this.referenceOptionsLoading = false;
          this.$error.view(error);
        }
      },
      async getCertifiedBomList() {
        this.referenceOptions = [];
        this.referenceOptionRevision = [];
        this.selectedReference = this.generation_item;
        this.referenceBomList = [];

        this.referenceOptionsLoading = true;
        try {
          const bomList = await bomService.bomList();
          this.referenceBomList = bomList.filter((bom: ReferenceBom) => bom.referenceType === 'certified');

          const uniquePns: string[] = Array.from(new Set(this.referenceBomList.map((bom: any) => bom.productPn)));
          this.referenceOptions = uniquePns;

          console.log('Certified BOM List:', this.referenceBomList);

          this.selectSimilarReference(this.referenceBomPn || '');

          this.referenceOptionsLoading = false;
        } catch (error: any) {
          this.referenceOptionsLoading = false;
          this.compareDisabled = true;
          this.$log.showInfo(`No BOM available from Certified for the selected item: <br> ${this.generation_item}`);
          console.error('Failed to fetch Certified data:', error);
        }
      },

      async getReferenceOdoo13(pn?: string) {
        this.compareDisabled = true;
        this.referenceOptions = [];
        this.referenceOptionRevision = [];

        // this.selectedReference = this.generation_item;
        this.odooBomList = [];

        this.referenceOptionsLoading = true;
        try {
          const searchPn = pn || this.generation_item;
          const odooData = await odooService.getOdooBoms(searchPn);

          this.odooBomList = odooData;

          this.referenceOptionsLoading = false;
          this.compareDisabled = false;
        } catch (error) {
          const searchPn = pn || this.generation_item;
          this.referenceOptionsLoading = false;
          this.compareDisabled = true;
          this.$log.showInfo(`No BOM available from Odoo for the selected item: <br> ${searchPn}`);
          console.error('Failed to fetch Odoo data:', error);
        }
      },
      fetchOdooData() {
        if (this.selectedSource === 'Odoo13' && this.selectedReference) {
          this.getReferenceOdoo13(this.selectedReference);
        }
      },
      populateReferenceRevisions() {
        this.referenceOptionRevision = this.referenceBomList
          .filter((bom) => bom.productPn === this.selectedReference)
          .map((bom) => ({
            pn: bom.productPn,
            reference: bom.reference || 'N/A',
            id: bom.id || null,
          }));
      },

      selectSimilarReference(referencePn: string) {
        // const targetPn = this.generation_item;
        const targetPn = referencePn || this.generation_item;

        if (!targetPn || !Array.isArray(this.referenceBomList)) {
          console.error('Invalid targetPn or referenceBomList:', targetPn, this.referenceBomList);
          return;
        }

        // Check for an exact match first
        const exactMatch = this.referenceBomList.find((bom: any) => bom.productPn === targetPn);
        if (exactMatch) {
          //console.log(`Exact match found: ${exactMatch.productPn}`);
          this.selectedReference = exactMatch.productPn;
          this.populateReferenceRevisions();

          return;
        }

        let truncatedPn = targetPn;

        while (truncatedPn.length > 0) {
          const similarReference = this.referenceBomList.find((bom: any) => bom.productPn.startsWith(truncatedPn));

          if (similarReference) {
            // console.log(`Match found: ${similarReference.productPn}`);
            this.selectedReference = similarReference.productPn;
            this.populateReferenceRevisions();
            return;
          }

          truncatedPn = truncatedPn.slice(0, -1);
        }

        // console.log('No match found. Exiting search.');
        this.selectedReference = null;
      },
      async compareBom(data: any) {
        try {
          const bomList = await bomService.bomCompare(data);
          this.compareData = bomList;
          this.updateCurrentTables();
          //console.log('Bom List:', bomList);
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      updateCurrentTables() {
        const compareData = this.compareData.comparisonResults || [];
        const origTarget = this.targetBom;
        const origReference = this.referenceBom;

        // console.log('Original Target BOM:', origTarget);
        // console.log('Original Reference BOM:', origReference);
        // console.log('Comparison Data:', compareData);

        const emptyBomLine = {
          material: { pn: '', description: '' },
          cutSize: 0,
          cutUom: '',
          cutQuantity: 0,
          quantity: 0,
          uom: '',
          consumedOperation: '',
          comparisonStatus: '',
        };

        const modTargetBom: BomLine[] = [];
        const modReferenceBom: BomLine[] = [];

        compareData.forEach((result: any) => {
          const lineIndexTarget = (result.lineIndexTarget || 0) - 1;
          const lineIndexReference = (result.lineIndexReference || 0) - 1;
          const { comparisonStatus, differences } = result;

          if (lineIndexTarget >= 0 && lineIndexTarget < origTarget.length) {
            modTargetBom.push({
              ...origTarget[lineIndexTarget],
              comparisonStatus,
              differences,
            });
          } else {
            modTargetBom.push({ ...emptyBomLine, comparisonStatus });
          }

          if (lineIndexReference >= 0 && lineIndexReference < origReference.length) {
            modReferenceBom.push({
              ...origReference[lineIndexReference],
              comparisonStatus,
              differences,
            });
          } else {
            modReferenceBom.push({ ...emptyBomLine, comparisonStatus });
          }
        });

        // console.log('Updated Target BOM:', modTargetBom);
        // console.log('Updated Reference BOM:', modReferenceBom);

        this.targetBom = modTargetBom;
        this.referenceBomLines = modReferenceBom;
      },
      GetCompareStatus(item: any) {
        if (item && item.comparisonStatus) {
          return `row-${item.comparisonStatus.toLowerCase()}`;
        }
        return '';
      },
    },
  });
</script>

<style scoped>
  .comparison-item {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .comparison-value {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .comparison-percentage {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .comparison-value-desc {
    font-size: 12px;
    color: #6c757d;
  }

  .selected-toggle {
    background-color: #3d4e65 !important;
    color: white !important;
    border-radius: 50px;
  }

  .v-btn-toggle > .v-btn {
    min-width: 150px;
    font-size: 16px;
    font-weight: 500;
    background-color: #f1f2f3;
    color: #333333;
    border-radius: 0px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
    height: 60px;
  }
  :deep(.v-table tr) {
    height: 50px;
  }
  .table-title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    background-color: #f5f5f5;
    padding: 10px 20px 10px 11px;
    border-left: 4px solid #3d4e65;
  }
  .small-text {
    font-size: 12px;
    color: #6c757d;
  }

  .comparison-status {
    position: relative;
  }
  .comparison-title {
    margin-top: 4px;
    font-weight: 600;
    color: #000000;
  }
  .status-chip {
    font-size: 16px;
    font-weight: 600;
    color: white;
    border-radius: 50px;
    margin-left: 16px;
  }
  .comparison-title-details {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    position: absolute;
    right: 0px;
    top: 7px;
  }

  .row-proportional {
    background-color: #ddf5d4;
  }
  .row-identical {
    background-color: #ddf5d4;
  }
  .row-similar {
    background-color: #f6e7a8;
  }
  .row-different {
    color: red;
    background-color: #e5b6af;
  }
  :deep(.reference-row-missing-target) {
    color: red;
    /* background-image: linear-gradient(58deg, #ffffff 25%, #dedede 25%, #dedede 50%, #ffffff 50%, #ffffff 75%, #dedede 75%, #dedede 100%); */
    background: repeating-linear-gradient(49deg, transparent, transparent 10px, #ebebeb 10px, #ebebeb 11px);
    /* background-color: #f3f3f3; */
  }
  :deep(.row-missing-reference) {
    color: red;
    background: repeating-linear-gradient(49deg, transparent, transparent 10px, #ebebeb 10px, #ebebeb 11px);
  }

  /* :deep(.reference-row-missing-reference) {
      color: red;
      background: repeating-linear-gradient(49deg, transparent, transparent 10px, #ebebeb 10px, #ebebeb 11px);
    } */
  /*
    :deep(.row-missing-target) {
      color: red;
      background-color: transparent;
      box-shadow:
        inset 2px 0 red,
        inset -2px 0 red,
        0 -2px red,
        0 2px red;
    }
  
    :deep(.reference-row-missing-reference) {
      color: red;
      background-color: transparent;
      box-shadow:
        inset 2px 0 red,
        inset -2px 0 red,
        0 -2px red,
        0 2px red;
    }
  
     .row-missing {
      background-color: #ff000093;
    } */

  .chip-identical {
    background-color: #5baa64;
  }
  .chip-similar {
    background-color: #7e7e7e;
  }
  .chip-different {
    background-color: #d03e2e;
  }

  .diff {
    color: red;
    font-weight: bold;
    text-decoration: underline;
  }

  .viewMode-btn {
    min-width: 150px;
    font-size: 16px;
    font-weight: 500;
    background-color: #f1f2f3;
    color: #333333;
    border-radius: 4px;
    transition: all 0.3s;
  }
  .viewMode-btn[value='line-by-line'] {
    border-radius: 4px 0px 0px 4px;
    height: 40px;
  }
  .viewMode-btn[value='only-differences'] {
    border-radius: 0px 4px 4px 0px;
    height: 40px;
  }

  .selected-toggle {
    background-color: #3d4e65 !important;
    color: white !important;
  }

  .active-toggle {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .inactive-toggle {
    background-color: #e0e0e0;
    color: #757575;
  }

  .legend {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
</style>
