<template>
  <v-dialog v-model="showWizardModal" max-width="1300px" data-cy="modal" persistent class="custom-dialog">
    <v-card>
      <v-card-title class="headline">
        Start Wizard
        <v-icon small @click="closeStartModal" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-4">
        <div class="part-number-display">
          <h2>Part Number: {{ formattedPartNumber }}</h2>
        </div>

        <div class="configurator-grid">
          <!-- Row 1 -->
          <div class="grid-row">
            <v-select v-model="selectedProductFamily" :items="productFamilies" label="Product family" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedFiberCount" :items="fiberCounts" label="Fiber Count" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedFiberType" :items="fiberTypes" label="Fiber Type" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedJacketRating" :items="jacketRatings" label="Jacket Rating" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedConnectorA" :items="connectorTypes" label="Connector A" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedConnectorB" :items="connectorTypes" label="Connector B" outlined dense hide-details class="config-select"></v-select>
          </div>

          <!-- Row 2 -->
          <div class="grid-row">
            <v-select v-model="selectedPolarityType" :items="polarityTypes" label="Polarity type" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedPullingEye" :items="pullingEyes" label="Pulling eye" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedBreakoutCode" :items="breakoutCodes" label="Breakout Code" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedBreakoutLengthA" :items="breakoutLengths" label="Breakout Length A" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedBreakoutLengthB" :items="breakoutLengths" label="Breakout Length B" outlined dense hide-details class="config-select"></v-select>

            <v-select v-model="selectedProductLengthUOM" :items="productLengthUOMs" label="Product Length UOM" outlined dense hide-details class="config-select"></v-select>
          </div>

          <!-- Row 3 -->
          <div class="grid-row">
            <v-text-field v-model="productLength" label="Product Length" outlined dense hide-details type="number" class="config-select"></v-text-field>

            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="spacer"></div>
            <div class="spacer"></div>

            <div class="button-group">
              <v-btn class="btn-cancel mr-2" @click="clearConfigurator">CLEAR CONFIGURATOR</v-btn>
              <!-- <v-btn class="btn-save" @click="applyConfiguration">APPLY</v-btn> -->
            </div>
          </div>
        </div>

        <div class="preview-section mt-6">
          <h3>Generated Description:</h3>
          <p>{{ generatedDescription }}</p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="closeStartModal">Cancel</v-btn>
        <v-btn disabled class="btn-save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Product } from '@/types/pm/product';

  export default defineComponent({
    name: 'Wizard',
    props: {
      showWizardModal: Boolean,
      product: {
        type: Object as PropType<Product | null>,
        default: null,
      },
    },

    data() {
      return {
        selectedProductFamily: 'Fiber Array',
        selectedFiberCount: '12 fiber',
        selectedFiberType: 'OM4 multimode',
        selectedJacketRating: 'Riser',
        selectedConnectorA: 'LC UPC Simplex',
        selectedConnectorB: '',
        selectedPolarityType: '',
        selectedPullingEye: '',
        selectedBreakoutCode: '',
        selectedBreakoutLengthA: '',
        selectedBreakoutLengthB: '',
        selectedProductLengthUOM: 'M',
        productLength: '',

        // Dropdown
        productFamilies: ['Fiber Array', 'Fiber Trunc', 'Fiber Cable'],
        fiberCounts: ['4 fiber', '8 fiber', '12 fiber', '16 fiber', '24 fiber', '48 fiber'],
        fiberTypes: ['OM3 multimode', 'OM4 multimode', 'OM5 multimode', 'OS2 singlemode'],
        jacketRatings: ['Riser', 'Plenum', 'LSZH', 'OFNP'],
        connectorTypes: ['LC UPC Simplex', 'LC APC Simplex', 'SC UPC', 'SC APC', 'MPO-8 Female', 'MPO-12 Female', 'MPO-24 Female'],
        polarityTypes: ['Type A', 'Type B', 'Type C', 'Universal'],
        pullingEyes: ['None', 'End A', 'End B', 'Both Ends'],
        breakoutCodes: ['Standard', 'Custom', 'None'],
        breakoutLengths: ['300mm', '500mm', '750mm', '1000mm', '1500mm', '2000mm'],
        productLengthUOMs: ['M', 'FT'],
      };
    },

    computed: {
      formattedPartNumber(): string {
        const fiberCount = this.selectedFiberCount ? this.selectedFiberCount.split(' ')[0] : '';

        let fiberTypeCode = '';
        if (this.selectedFiberType.includes('OM3')) fiberTypeCode = 'M3';
        else if (this.selectedFiberType.includes('OM4')) fiberTypeCode = 'M4';
        else if (this.selectedFiberType.includes('OM5')) fiberTypeCode = 'M5';
        else if (this.selectedFiberType.includes('OS2')) fiberTypeCode = 'SM';

        let jacketCode = '';
        if (this.selectedJacketRating === 'Riser') jacketCode = 'R';
        else if (this.selectedJacketRating === 'Plenum') jacketCode = 'P';
        else if (this.selectedJacketRating === 'LSZH') jacketCode = 'L';
        else if (this.selectedJacketRating === 'OFNP') jacketCode = 'FP';

        let connectorACode = this.selectedConnectorA.includes('LC')
          ? 'LC'
          : this.selectedConnectorA.includes('SC')
            ? 'SC'
            : this.selectedConnectorA.includes('MPO')
              ? 'M' + this.selectedConnectorA.split('-')[1].substring(0, 1)
              : '';

        let connectorBCode = this.selectedConnectorB.includes('LC')
          ? 'LC'
          : this.selectedConnectorB.includes('SC')
            ? 'SC'
            : this.selectedConnectorB.includes('MPO')
              ? 'M' + this.selectedConnectorB.split('-')[1].substring(0, 1)
              : '';

        const prefix = this.selectedProductFamily.includes('Fiber Array') ? 'FA' : this.selectedProductFamily.includes('Fiber Trunc') ? 'FT' : 'FC';

        return `${prefix}-${fiberCount}${fiberTypeCode}${jacketCode}-${connectorACode}${connectorBCode}`;
      },

      generatedDescription(): string {
        if (!this.selectedProductFamily) return '';

        return `${this.selectedProductFamily}, ${this.selectedFiberCount}, ${this.selectedFiberType}, ${this.selectedJacketRating} rated, with ${this.selectedConnectorA}${this.selectedConnectorB ? ' and ' + this.selectedConnectorB : ''} connectors${this.productLength ? ', ' + this.productLength + this.selectedProductLengthUOM + ' length' : ''}.`;
      },
    },

    methods: {
      closeStartModal() {
        this.$emit('close');
      },

      clearConfigurator() {
        this.selectedProductFamily = '';
        this.selectedFiberCount = '';
        this.selectedFiberType = '';
        this.selectedJacketRating = '';
        this.selectedConnectorA = '';
        this.selectedConnectorB = '';
        this.selectedPolarityType = '';
        this.selectedPullingEye = '';
        this.selectedBreakoutCode = '';
        this.selectedBreakoutLengthA = '';
        this.selectedBreakoutLengthB = '';
        this.selectedProductLengthUOM = 'M';
        this.productLength = '';
      },

      applyConfiguration() {
        // bal bla bla
      },

      saveConfiguration() {
        if (this.product) {
          this.$emit('update-product', {
            basePN: this.formattedPartNumber,
            description: this.generatedDescription,
          });
        } else {
          this.$emit('create-product', {
            basePN: this.formattedPartNumber,
            description: this.generatedDescription,
          });
        }
        this.$emit('close');
      },
    },

    watch: {
      product: {
        immediate: true,
        handler(newProduct) {
          if (newProduct && newProduct.basePN) {
            const pn = newProduct.basePN;
            if (pn.startsWith('FA-')) {
              this.selectedProductFamily = 'Fiber Array';

              const match = pn.match(/FA-(\d+)/);
              if (match && match[1]) {
                this.selectedFiberCount = match[1] + ' fiber';
              }
            }
          }
        },
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .part-number-display {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  }

  .configurator-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .grid-row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    align-items: center;
  }

  .config-select {
    width: 100%;
  }

  .spacer {
    width: 100%;
  }

  .button-group {
    grid-column: 6;
    display: flex;
    justify-content: flex-end;
  }

  .preview-section {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 4px;
  }
</style>
