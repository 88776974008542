<template>
  <v-dialog v-model="modal" max-width="600px" data-cy="import-odoo-modal" persistent class="custom-dialog">
    <v-card data-cy="modal-card">
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">Import BOMs from Odoo</span>
        <v-icon small @click="closeModal" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>

      <v-card-text data-cy="modal-content" class="pa-6">
        <v-form ref="form" data-cy="form">
          <!-- Odoo Version Selector -->
          <v-select
            v-model="selectedOdooVersion"
            :items="odooVersions"
            label="Select Odoo Version"
            class="mb-4"
            data-cy="odoo-version-select"
            variant="underlined"
            hide-details
          ></v-select>

          <!-- Replace text field with autocomplete -->
          <v-autocomplete
            v-model:search="searchQuery"
            :items="odooBomOptions"
            label="Enter Product Family"
            variant="underlined"
            hide-details
            item-title="default_code"
            item-value="default_code"
            clearable
            :loading="loadingBoms"
            :no-filter="true"
            @update:search="onSearchOdooBoms"
            @update:model-value="onBomSelected"
            class="mb-4"
            data-cy="product-number-input"
          >
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :title="item.raw.default_code" :subtitle="`${item.raw.name} (${item.raw.numberOfBoms} BOMs)`"></v-list-item>
            </template> -->
            <template #item="{ props, item }">
              <v-list-item style="position: relative; max-width: 550px" v-bind="props">
                <template #title>
                  <span style="color: black">{{ item.raw.default_code }}</span>
                </template>
                <template #subtitle>
                  <v-tooltip location="left">
                    <template #activator="{ props: tooltipActivatorProps }">
                      <span v-bind="tooltipActivatorProps" style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                        {{ item.raw.name }}
                      </span>
                    </template>
                    <!-- Tooltip Content with custom width -->
                    <div style="width: 250px; white-space: normal">
                      {{ item.raw.name }}
                    </div>
                  </v-tooltip>
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel mr-2" @click="closeModal" data-cy="cancel-button">Cancel</v-btn>
        <v-btn class="btn-orange" style="width: 110px" @click="importBom" :disabled="!selectedProduct" data-cy="import-button">Import</v-btn>
      </v-card-actions>
    </v-card>

    <!-- BomsModal component -->
    <BomsModal
      v-if="showBomsModal"
      :modal="showBomsModal"
      :modalTitle="'Edit Imported BOM'"
      :bomData="importedBomData"
      :families="families"
      @close="closeBomsModal"
      @save="handleBomSave"
      :certified="true"
    />
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import BomsModal from './BomsModal.vue';
  import odooService from '@/services/api/odooService';

  interface OdooBom {
    id: number;
    default_code: string;
    name: string;
    numberOfBoms: number;
  }

  export default defineComponent({
    name: 'ImportOdooModal',

    components: {
      BomsModal,
    },

    props: {
      modal: {
        type: Boolean,
        required: true,
      },
      families: {
        type: Array as () => {
          pnFamily: string;
          bomCount: number;
          boms: any[];
        }[],
        required: true,
      },
    },

    data() {
      return {
        selectedOdooVersion: 'Odoo 13',
        odooVersions: ['Odoo 13', 'Odoo 17'],
        selectedBom: null as string | null,
        searchQuery: '',
        selectedProduct: null as string | null,
        showBomsModal: false,
        importedBomData: {} as any,
        odooBomOptions: [] as OdooBom[],
        loadingBoms: false,
      };
    },

    methods: {
      async onSearchOdooBoms(value: string) {
        if (!value || value.length < 2) {
          this.odooBomOptions = [];
          return;
        }

        this.loadingBoms = true;
        try {
          const results = await odooService.getOdooBomsByFamily(value);
          this.odooBomOptions = results.partNumbers
            .map((item: { id: any; default_code: any; name: any; numberOfBoms: any }) => ({
              id: item.id,
              default_code: item.default_code,
              name: item.name,
              numberOfBoms: item.numberOfBoms,
            }))
            .filter((item: { reference: boolean; numberOfBoms: number }) => item.numberOfBoms > 0);
        } catch (error) {
          console.log(`Failed to search BOMs: ${error}`);
        } finally {
          this.loadingBoms = false;
        }
      },

      onBomSelected(bom: string | null) {
        this.selectedProduct = bom || null;
      },

      async importBom() {
        if (!this.selectedProduct) return;

        try {
          const importedBom = await odooService.getOdooBoms(this.selectedProduct);
          this.importedBomData = importedBom;
          this.showBomsModal = true;
        } catch (error) {
          this.$log.showError(`Failed to import BOM: ${error}`);
        }
      },

      closeModal() {
        this.$emit('close');
        this.resetForm();
      },

      closeBomsModal() {
        this.showBomsModal = false;
        this.closeModal();
      },

      handleBomSave() {
        this.showBomsModal = false;
        this.closeModal();
        this.$emit('bom-imported');
      },

      resetForm() {
        this.selectedBom = null;
        this.searchQuery = '';
        this.selectedProduct = null;
        this.showBomsModal = false;
        this.importedBomData = {};
        this.odooBomOptions = [];
      },
    },

    watch: {
      modal(newVal) {
        if (!newVal) {
          this.resetForm();
        }
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    /* padding: 16px 24px; */
  }

  .btn-cancel {
    color: #64748b;
    border: 1px solid #64748b;
  }
</style>
