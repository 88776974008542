<template>
  <div>
    <AssembliesModal 
      title="Blueprint" 
      :currentItem="defaultItem"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AssembliesModal from '@/views/pm/temp/AssembliesModal_PM.vue';

export default defineComponent({
  name: 'BlueprintTab',
  components: {
    AssembliesModal,
  },
  data() {
    return {
      defaultItem: {
        id: '',
        type: 'blueprint', // Set default type to blueprint
        name: '',
        description: '',
        code: undefined,
        components: [],
      }
    }
  }
});
</script> 