<!-- src/components/TopMenu.vue -->
<template>
  <v-app-bar app color="topAppBar" light data-cy="app-bar">
    <img class="logo" src="@/assets/viaphoton-logo.svg" alt="viaphoton logo" @click="navigateTo('Landing')" data-cy="logo" />
    <v-toolbar-title class="title-text" data-cy="toolbar-title">
      | &nbsp;Product
      <span>Master</span>
    </v-toolbar-title>

    <div class="env-data" v-if="isLocalDomain"><span class="env-text">LOCAL</span></div>
    <div class="env-data" v-if="isDevDomain"><span class="env-text">DEV</span></div>
    <div class="env-data" v-if="isStagingDomain"><span class="env-text">STAGING</span></div>
    <v-spacer></v-spacer>
    <teleport to="body">
      <div class="usersnap">
        <button class="feedback-btn" onclick="window.Usersnap.logEvent('openusersnap')">Feedback</button>
      </div>
    </teleport>

    <!-- Profile Menu -->
    <v-menu min-width="220px" rounded offset-y data-cy="profile-menu">
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" class="profile-button" data-cy="profile-button">
          <v-avatar color="grey lighten-1" size="large" data-cy="profile-avatar">
            <span class="text-h6 profile-initials" data-cy="profile-initials">
              {{ initials }}
            </span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-card-text class="profile-card-text" data-cy="profile-card-text">
          <div class="text-center profile-content" data-cy="profile-content">
            <v-avatar color="grey lighten-2" size="70px" class="profile-avatar-large" data-cy="profile-avatar-large">
              <span class="text-h5 profile-initials-large" data-cy="profile-initials-large">
                {{ initials }}
              </span>
            </v-avatar>
            <h3 class="profile-name" data-cy="user-name">{{ user?.name }}</h3>
            <p class="text-caption profile-email" data-cy="user-email">{{ user?.email }}</p>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-list>
          <v-list-item @click="handleProfileOptionClick('Profile')" data-cy="profile-button-profile">
            <v-list-item-title>
              <i class="fa-solid fa-user mr-3"></i>
              Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleProfileOptionClick('Logout')" data-cy="profile-button-logout">
            <v-list-item-title>
              <i class="fa-solid fa-right-from-bracket" style="margin-right: 10px"></i>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuth0 } from '@auth0/auth0-vue';

  export default defineComponent({
    name: 'TopMenu',

    setup() {
      const router = useRouter();
      const { user } = useAuth0();

      const initials = computed(() => {
        if (user.value && user.value.name) {
          const names = user.value.name.split(' ');
          return names.map((n) => n[0]).join('');
        }
        return '';
      });

      const isLocalDomain = computed(() => {
        return window.location.origin === 'http://127.0.0.1:5173';
      });
      const isDevDomain = computed(() => {
        return window.location.origin === 'https://bomg.dev.viaphoton.dev';
      });
      const isStagingDomain = computed(() => {
        return window.location.origin === 'https://bomg.staging.viaphoton.dev';
      });

      const navigateTo = (page: string) => {
        router.push({ name: page });
      };

      const handleProfileOptionClick = (page: string) => {
        router.push({ name: page });
      };

      let markerWidget: any;

      const openMarkerWidget = () => {
        if (markerWidget) {
          markerWidget.show();
        }
      };

      return {
        navigateTo,
        user,
        initials,
        handleProfileOptionClick,
        openMarkerWidget,
        isLocalDomain,
        isDevDomain,
        isStagingDomain,
      };
    },
  });
</script>

<style scoped>
  .logo {
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }

  .title-text {
    margin-inline-start: 20px;
    position: absolute;
    left: 140px;
    font-size: 22px;
    font-weight: 500;
  }
  .title-text span {
    font-size: 20px;
    font-weight: 400;
  }

  /* .usersnap {
    margin-right: 30px;
    margin-top: -32px;
  }
  .feedback-btn {
    background-color: orange;
    padding: 4px 20px;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    border-radius: 0px 0px 10px 10px;
    transition: 0.3s;
  }
  .feedback-btn:hover {
    background-color: orange;
    color: #333e50;
  } */

  .usersnap {
    position: fixed;
    top: 0px;
    right: 100px;
    z-index: 3000;
  }

  .feedback-btn {
    background-color: orange;
    padding: 4px 20px;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    border-radius: 0px 0px 10px 10px;
    transition: 0.3s;
  }

  .feedback-btn:hover {
    background-color: darkorange;
  }

  .profile-button {
    margin-right: 15px !important; /* Ensure consistent spacing */
  }

  .profile-avatar {
    border-radius: 50%; /* Ensure it's always round */
    overflow: hidden;
    cursor: pointer;
  }

  .profile-avatar-large {
    margin: 10px auto; /* Center the avatar */
    border-radius: 50%; /* Maintain circular shape */
  }

  .profile-initials,
  .profile-initials-large {
    font-weight: 500;
    color: #333;
  }

  .profile-content {
    padding: 16px;
  }

  .profile-name {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #444;
  }

  .profile-email {
    margin-top: 4px;
    color: #666;
  }

  .profile-card-text {
    text-align: center;
    padding: 16px;
  }
  .env-data {
    position: absolute;
    left: 330px;
    top: 16px;
  }
  .env-text {
    background-color: orange;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 8px;
    color: #333;
    font-weight: 800;
  }
</style>
