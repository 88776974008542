<template>
  <div>

    <Bom :modal="modal" :modalTitle="modalTitle" :bomData="bomData" :uomOptions="uomOptions" :families="certifiedFamilies" @close="closeModal" @save="saveBom" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Bom from '@/views/pm/temp/BomsModal_PM.vue';

export default defineComponent({
  name: 'CertificationTab',
  components: {
    Bom
  },
  data() {
    return {
      modal: true,
      modalTitle: '',
      bomData: {},
      uomOptions: [],
      certifiedFamilies: [],
      showMassUpdateModal: false,
      showImportModal: false,
      showSearchByMaterialModal: false,
      showRevokeDialog: false,
      showCancelDialog: false,
      showDeleteDialog: false,  
    };
  },    
  methods: {
    closeModal() {
      this.modal = false;
    },
    saveBom() {
      console.log('saveBom');
    },
  },
  
});
</script> 