<template>
  <v-dialog v-model="showDrawModal" max-width="1100px" persistent>
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">Drawing</span>
        <v-icon small @click="close" class="close-icon">mdi-close</v-icon>
      </v-card-title>

      <v-card-actions class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn @click="close" class="btn-cancel">Close</v-btn>
        <!-- <v-btn @click="save" class="btn-save">Apply</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType, watch } from 'vue';

  export default defineComponent({
    props: {
      showDrawModal: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close', 'save'],
    methods: {
      close() {
        this.$emit('close');
      },
      save() {
        this.$emit('save');
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .close-icon {
    cursor: pointer;
  }
</style>
