// src/App.vue
<template>
  <router-view />
</template>

<script lang="ts">
  import { defineComponent, watchEffect } from 'vue';
  import { useAuth0 } from '@auth0/auth0-vue';
  import { useAuthStore } from '@/store/authStore';

  export default defineComponent({
    name: 'App',

    setup() {
      const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
      const authStore = useAuthStore();

      watchEffect(async () => {
        if (isAuthenticated.value && user.value) {
          authStore.setUser(user.value as any);

          try {
            const token = await getAccessTokenSilently();
            authStore.setCredential({ token });
          } catch (error) {
            console.error('Error getting token:', error);
          }
        } else {
          authStore.logout();
        }
      });

      return {};
    },
  });
</script>

<style>
  .app-root {
    height: 100%;
    width: 100%;
  }
</style>
