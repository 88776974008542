<template>
  <v-app>
    <v-main class="main-container">
      <TopMenu />
      <router-view class="view" />
      <Footer class="footer" />
    </v-main>
  </v-app>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import TopMenu from '@/components/pm/TopMenu.vue';
  import Footer from '@/components/common/Footer.vue';

  export default defineComponent({
    name: 'MainLayout',
    components: {
      TopMenu,
      Footer,
    },
  });
</script>

<style scoped>
  .main-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .view {
    margin-bottom: 34px;
    flex: 1 0 auto;
  }

  .footer {
    width: 100%;
    height: 52px;
    text-align: center;
    background-color: var(--v-theme-background-base);
    padding: 1rem;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    color: var(--footer-font-color);
    flex-shrink: 0;
  }
</style>
