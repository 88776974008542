<template>
  <v-dialog v-model="showProductDetailsModal" max-width="1300px" data-cy="modal" persistent class="custom-dialog">
    <v-card data-cy="modal-card">
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ modalTitle }}</span>
        <v-icon small @click="close" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text data-cy="modal-content">
        <!-- <div class="class-header d-flex align-center justify-space-between">
          <h2 class="mb-3">Product</h2>
        </div> -->
        <v-row>
          <v-col cols="12" md="12" class="mt-3">
            <v-row>
              <v-col cols="12" md="5" style="padding: 0px 0px 0px 12px; display: flex; align-items: center">
                <v-text-field
                  v-model="basePN"
                  label="Base Part Number"
                  variant="underlined"
                  @keydown.stop
                  @keyup.enter="basePN.length !== 0 && handleBasePN()"
                  :loading="loadingBasePN"
                  hide-details
                ></v-text-field>
                <v-btn @click="handleBasePN" class="btn-orange ml-4" data-cy="edit-product-button" :disabled="basePN.length == 0">Find</v-btn>
                <v-btn @click="openWizardModal" class="btn-orange ml-4" data-cy="edit-product-button">PN Wizard</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" style="padding: 4px 0px 0px 12px">
                <v-text-field v-model="description" label="Description" variant="underlined" hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-tabs v-model="tab" style="border-bottom: 2px solid; margin-top: 16px; margin-left: 12px" data-cy="tabs">
            <v-tab :key="0" :active="activeTab === 0" @click="activeTab = 0" data-cy="tab-overview">
              <i class="fa-regular fa-file-invoice"></i>
              &nbsp; Customers
            </v-tab>
            <v-tab :key="1" :active="activeTab === 1" @click="activeTab = 1" data-cy="tab-bom">
              <i class="fa-duotone fa-solid fa-pen-to-square"></i>
              &nbsp; Blueprint
            </v-tab>
            <v-tab :key="2" :active="activeTab === 2" @click="activeTab = 2" data-cy="tab-bom">
              <i class="fa-sharp-duotone fa-solid fa-code-compare"></i>
              &nbsp; Certification data (BOM)
            </v-tab>
            <v-tab :key="4" :active="activeTab === 4" @click="activeTab = 4" data-cy="tab-bom">
              <i class="fa-duotone fa-solid fa-image"></i>
              &nbsp; Drawings
            </v-tab>
            <v-tab :key="4" :active="activeTab === 5" @click="activeTab = 5" data-cy="tab-bom">
              <i class="fa-duotone fa-solid fa-money-check-dollar"></i>
              &nbsp; Pricing
            </v-tab>
          </v-tabs>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <div class="tab-content">
              <div v-if="activeTab === 0">
                <CustomersVendorsTab
                  :product="actualProduct"
                  @add-customer="addCustomer"
                  @edit-customer="editCustomer"
                  @delete-customer="deleteCustomer"
                  @add-vendor="addVendor"
                  @edit-vendor="editVendor"
                  @delete-vendor="deleteVendor"
                />
              </div>
              <div v-if="activeTab === 1">
                <BlueprintTab />
              </div>
              <div v-if="activeTab === 2">
                <CertificationTab />
              </div>
              <div v-if="activeTab === 4">
                <DrawingsTab />
              </div>
              <div v-if="activeTab === 5">
                <PricingTab />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel mr-2" @click="close" data-cy="cancel-button">Cancel</v-btn>
        <v-btn class="btn-save" @click="saveProduct" data-cy="save-button">Save</v-btn>
      </v-card-actions>
    </v-card>
    <Wizard :showWizardModal="showWizardModal" :product="selectedProduct" @close="showWizardModal = false" />
  </v-dialog>

  <!-- Customer Dialog -->
  <v-dialog v-model="showCustomerDialog" max-width="600px">
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">{{ customerDialogMode === 'add' ? 'Add Customer' : 'Edit Customer' }}</span>
        <v-icon small @click="cancelCustomerDialog" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="customerForm.name" label="Customer Name" variant="underlined"></v-text-field>
        <v-text-field v-model="customerForm.productPNFormat" label="Customer PN" variant="underlined"></v-text-field>
        <v-text-field v-model="customerForm.descriptionFormat" label="Customer Description" variant="underlined"></v-text-field>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="cancelCustomerDialog">Cancel</v-btn>
        <v-btn class="btn-save" @click="saveCustomer">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Vendor Dialog -->
  <v-dialog v-model="showVendorDialog" max-width="600px">
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">{{ vendorDialogMode === 'add' ? 'Add Vendor' : 'Edit Vendor' }}</span>
        <v-icon small @click="cancelVendorDialog" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="vendorForm.name" label="Vendor Name" variant="underlined"></v-text-field>
        <v-text-field v-model="vendorForm.productPNFormat" label="Vendor PN" variant="underlined"></v-text-field>
        <v-text-field v-model="vendorForm.descriptionFormat" label="Vendor Description" variant="underlined"></v-text-field>
      </v-card-text>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="cancelVendorDialog">Cancel</v-btn>
        <v-btn class="btn-save" @click="saveVendor">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import Wizard from './ProductsWizard.vue';
  import { Product, Customer, Vendor } from '@/types/pm/product';
  import productsService from '@/services/api_PM/products';
  import assemblyService from '@/services/api/assemblyService';
  import CustomersVendorsTab from './tabs/CustomersVendorsTab.vue';
  import BlueprintTab from './tabs/BlueprintTab.vue';
  import CertificationTab from './tabs/CertificationTab.vue';
  import DrawingsTab from './tabs/DrawingsTab.vue';
  import PricingTab from './tabs/PricingTab.vue';

  export default defineComponent({
    name: 'ProductDetailsModal',
    props: {
      showProductDetailsModal: {
        type: Boolean,
        required: true,
      },
      selectedProduct: {
        type: Object as PropType<Product | null>,
        required: false,
        default: null,
      },
      modalTitle: {
        type: String,
        required: true,
      },
    },
    components: {
      Wizard,
      CustomersVendorsTab,
      BlueprintTab,
      CertificationTab,
      DrawingsTab,
      PricingTab,
    },

    data() {
      return {
        currentProduct: {
          id: '',
          basePN: '',
          description: '',
          customers: [],
          vendors: [],
        } as Product,
        customersHeaders: [
          { title: 'Customer', value: 'name' },
          { title: 'Customer PN', value: 'productPNFormat' },
          { title: 'Customer Description', value: 'descriptionFormat' },
          { title: 'Actions', value: 'actions', sortable: false, width: '100px' },
        ],
        vendorsHeaders: [
          { title: 'Vendor', value: 'name' },
          { title: 'Vendor PN', value: 'productPNFormat' },
          { title: 'Vendor Description', value: 'descriptionFormat' },
          { title: 'Actions', value: 'actions', sortable: false, width: '100px' },
        ],
        showWizardModal: false,
        selectedCustomer: null as string | null,
        customerPN: '',
        customerDescription: '',
        basePN: '',
        loadingBasePN: false,
        description: '',

        editingCustomerIndex: -1,
        editingVendorIndex: -1,

        // Customer dialog
        showCustomerDialog: false,
        customerDialogMode: 'add', // 'add' or 'edit'
        customerForm: {
          id: '',
          odooID: '',
          name: '',
          productPNFormat: '',
          descriptionFormat: '',
        },

        // Vendor dialog
        showVendorDialog: false,
        vendorDialogMode: 'add', // 'add' or 'edit'
        vendorForm: {
          id: '',
          odooID: '',
          name: '',
          productPNFormat: '',
          descriptionFormat: '',
        },
        tab: 0,
        activeTab: 0,
      };
    },
    computed: {
      actualProduct(): Product {
        return this.selectedProduct || this.currentProduct;
      },
      customerOptions(): Customer[] {
        return this.actualProduct.customers;
      },
      vendorOptions(): Vendor[] {
        return this.actualProduct.vendors;
      },

      hasCustomerChanges() {
        if (this.customerDialogMode === 'add') {
          return this.customerForm.name !== '' || this.customerForm.productPNFormat !== '' || this.customerForm.descriptionFormat !== '';
        }

        if (this.editingCustomerIndex === -1) return false;

        const originalCustomer = this.actualProduct.customers[this.editingCustomerIndex];
        if (!originalCustomer) return false;

        return (
          this.customerForm.name !== originalCustomer.name ||
          this.customerForm.productPNFormat !== originalCustomer.productPNFormat ||
          this.customerForm.descriptionFormat !== originalCustomer.descriptionFormat
        );
      },

      hasVendorChanges() {
        if (this.vendorDialogMode === 'add') {
          return this.vendorForm.name !== '' || this.vendorForm.productPNFormat !== '' || this.vendorForm.descriptionFormat !== '';
        }

        if (this.editingVendorIndex === -1) return false;

        const originalVendor = this.actualProduct.vendors[this.editingVendorIndex];
        if (!originalVendor) return false;

        return (
          this.vendorForm.name !== originalVendor.name ||
          this.vendorForm.productPNFormat !== originalVendor.productPNFormat ||
          this.vendorForm.descriptionFormat !== originalVendor.descriptionFormat
        );
      },
    },
    watch: {
      showProductDetailsModal: {
        immediate: true,
        handler(newVal: boolean) {
          if (newVal) {
            if (this.modalTitle === 'Add New Product') {
              this.currentProduct = {
                id: '',
                basePN: '',
                description: '',
                customers: [],
                vendors: [],
              };
            }
            this.resetData();
          }
        },
      },
      selectedProduct: {
        immediate: true,
        handler(product: Product | null) {
          if (product) {
            this.basePN = product.basePN;
            this.description = product.description;
            if (product.customers && product.customers.length > 0) {
              this.selectedCustomer = product.customers[0].odooID || '';
              this.updateCustomerFields();
            }
          }
        },
      },
    },
    methods: {
      resetData() {
        this.basePN = this.actualProduct.basePN || '';
        this.description = this.actualProduct.description || '';
        this.selectedCustomer = null;
        this.customerPN = '';
        this.customerDescription = '';
      },
      close() {
        this.$emit('close');
      },
      openWizardModal() {
        this.showWizardModal = true;
      },
      formatDate(date: string) {
        return new Date(date).toLocaleDateString();
      },
      updateCustomerFields() {
        if (!this.actualProduct || !this.selectedCustomer) return;

        const customer = this.actualProduct.customers.find((c: any) => c.odooID === this.selectedCustomer);
        if (customer) {
          this.customerPN = customer.productPNFormat.replace(/LENGTH\(\d+\)\+?UOM/g, '100M');
          this.customerDescription = customer.descriptionFormat.replace(/LENGTH/g, '100 Meters');
        }
      },

      parseBasePN(basePN: string): string {
        const regex = /-(M|F|CM|I)(\d+)$/;
        return basePN.replace(regex, '');
      },

      handleBasePN() {
        if (!this.basePN) {
          this.$log.showError('Please enter a Base Part Number');
          return;
        }

        this.basePN = this.parseBasePN(this.basePN);
        this.getBasePnData(this.basePN);
      },

      async getBasePnData(item: any) {
        this.loadingBasePN = true;
        try {
          const response = await assemblyService.generateAssembly(item);
          if (response) {
            this.description = response.description;
          }
          this.loadingBasePN = false;
        } catch (error: any) {
          this.loadingBasePN = false;
          this.$error.view(error);
        }
      },

      async createProduct() {
        const newProduct = {
          basePN: this.basePN,
          description: this.description,
          categoryId: 'NONE',
          customers: this.actualProduct.customers,
          vendors: this.actualProduct.vendors,
        };

        try {
          const response = await productsService.createProduct(newProduct);
          if (response) {
            this.$emit('product-created');
            this.$log.showSuccessMessage('Product created successfully');
            this.close();
          } else {
            this.$log.showError('Error creating product');
          }
        } catch (error) {
          this.$log.showError('Error creating product');
        }
      },

      async updateProduct(updatedProduct: Product) {
        try {
          console.log('Sending updated product to API:', JSON.stringify(updatedProduct));

          const response = await productsService.updateProduct(updatedProduct.id, updatedProduct);
          if (response) {
            this.$emit('product-updated');
            this.$log.showSuccessMessage('Product updated successfully');
            this.close();
          } else {
            this.$log.showError('Error updating product');
          }
        } catch (error) {
          console.error('Error updating product:', error);
          this.$log.showError('Error updating product');
        }
      },

      saveProduct() {
        if (!this.basePN || !this.description) {
          this.$log.showError('Please fill in all required fields');
          return;
        }

        try {
          if (this.modalTitle === 'Add New Product') {
            // Add
            this.currentProduct.basePN = this.basePN;
            this.currentProduct.description = this.description;
            this.createProduct();
          } else if (this.selectedProduct) {
            // Update
            const updatedProduct = {
              ...this.selectedProduct,
              basePN: this.basePN,
              description: this.description,
              customers: [...this.selectedProduct.customers],
              vendors: [...this.selectedProduct.vendors],
            };

            this.updateProduct(updatedProduct);
          }
        } catch (error) {
          this.$log.showError('Error saving product');
        }
      },

      // Customer Methods
      addCustomer() {
        this.customerDialogMode = 'add';
        this.resetCustomerForm();
        this.showCustomerDialog = true;
      },

      editCustomer(customer: Customer) {
        this.customerDialogMode = 'edit';
        this.customerForm = {
          id: customer.id || '',
          odooID: customer.odooID || '',
          name: customer.name || '',
          productPNFormat: customer.productPNFormat || '',
          descriptionFormat: customer.descriptionFormat || '',
        };
        this.editingCustomerIndex = this.actualProduct.customers.findIndex((c) => c.id === customer.id);
        this.showCustomerDialog = true;
      },

      deleteCustomer(customer: Customer) {
        if (this.selectedProduct) {
          const index = this.selectedProduct.customers.findIndex((c) => c.id === customer.id);
          if (index !== -1) {
            const updatedCustomers = [...this.selectedProduct.customers];
            updatedCustomers.splice(index, 1);
            this.selectedProduct.customers = updatedCustomers;
          }
        } else {
          const index = this.currentProduct.customers.findIndex((c) => c.id === customer.id);
          if (index !== -1) {
            const updatedCustomers = [...this.currentProduct.customers];
            updatedCustomers.splice(index, 1);
            this.currentProduct.customers = updatedCustomers;
          }
        }
      },

      resetCustomerForm() {
        this.customerForm = {
          id: '',
          odooID: '',
          name: '',
          productPNFormat: '',
          descriptionFormat: '',
        };
      },

      cancelCustomerDialog() {
        this.showCustomerDialog = false;
        this.resetCustomerForm();
      },

      saveCustomer() {
        if (!this.customerForm.name || !this.customerForm.productPNFormat) {
          this.$log.showError('Please fill in all required customer fields');
          return;
        }

        try {
          if (this.customerDialogMode === 'add') {
            const newCustomer = {
              ...this.customerForm,
              id: Date.now().toString(),
            };

            if (!newCustomer.odooID) {
              newCustomer.odooID = 'SOME_RANDOM_ID_' + Date.now().toString();
            }

            if (this.selectedProduct) {
              this.selectedProduct.customers = [...this.selectedProduct.customers, newCustomer];
            } else {
              this.currentProduct.customers = [...this.currentProduct.customers, newCustomer];
            }
          } else {
            const updatedCustomer = { ...this.customerForm };

            const index = this.editingCustomerIndex !== -1 ? this.editingCustomerIndex : this.actualProduct.customers.findIndex((c) => c.id === updatedCustomer.id);

            if (index !== -1) {
              const updatedCustomers = [...this.actualProduct.customers];
              updatedCustomers[index] = updatedCustomer;

              if (this.selectedProduct) {
                this.selectedProduct.customers = updatedCustomers;
              } else {
                this.currentProduct.customers = updatedCustomers;
              }
              console.log('Customer updated:', updatedCustomer);
            } else {
              this.$log.showError('Error updating customer: Customer not found');
              return;
            }
          }

          this.showCustomerDialog = false;
          this.resetCustomerForm();
          this.editingCustomerIndex = -1;
        } catch (error) {
          this.$log.showError('Error saving customer');
        }
      },

      // Vendor Methods
      addVendor() {
        this.vendorDialogMode = 'add';
        this.resetVendorForm();
        this.showVendorDialog = true;
      },

      editVendor(vendor: Vendor) {
        this.vendorDialogMode = 'edit';
        this.vendorForm = {
          id: vendor.id || '',
          odooID: vendor.odooID || '',
          name: vendor.name || '',
          productPNFormat: vendor.productPNFormat || '',
          descriptionFormat: vendor.descriptionFormat || '',
        };
        this.editingVendorIndex = this.actualProduct.vendors.findIndex((v) => v.id === vendor.id);
        this.showVendorDialog = true;
      },

      deleteVendor(vendor: Vendor) {
        if (this.selectedProduct) {
          const index = this.selectedProduct.vendors.findIndex((v) => v.id === vendor.id);
          if (index !== -1) {
            const updatedVendors = [...this.selectedProduct.vendors];
            updatedVendors.splice(index, 1);
            this.selectedProduct.vendors = updatedVendors;
          }
        } else {
          const index = this.currentProduct.vendors.findIndex((v) => v.id === vendor.id);
          if (index !== -1) {
            const updatedVendors = [...this.currentProduct.vendors];
            updatedVendors.splice(index, 1);
            this.currentProduct.vendors = updatedVendors;
          }
        }
      },

      resetVendorForm() {
        this.vendorForm = {
          id: '',
          odooID: '',
          name: '',
          productPNFormat: '',
          descriptionFormat: '',
        };
      },

      cancelVendorDialog() {
        this.showVendorDialog = false;
        this.resetVendorForm();
      },

      saveVendor() {
        if (!this.vendorForm.name || !this.vendorForm.productPNFormat) {
          this.$log.showError('Please fill in all required vendor fields');
          return;
        }

        try {
          if (this.vendorDialogMode === 'add') {
            const newVendor = {
              ...this.vendorForm,
              id: Date.now().toString(),
            };

            if (!newVendor.odooID) {
              newVendor.odooID = 'VEN_' + Date.now().toString();
            }

            if (this.selectedProduct) {
              this.selectedProduct.vendors = [...this.selectedProduct.vendors, newVendor];
            } else {
              this.currentProduct.vendors = [...this.currentProduct.vendors, newVendor];
            }
          } else {
            const updatedVendor = { ...this.vendorForm };

            const index = this.editingVendorIndex !== -1 ? this.editingVendorIndex : this.actualProduct.vendors.findIndex((v) => v.id === updatedVendor.id);

            if (index !== -1) {
              const updatedVendors = [...this.actualProduct.vendors];
              updatedVendors[index] = updatedVendor;

              if (this.selectedProduct) {
                this.selectedProduct.vendors = updatedVendors;
              } else {
                this.currentProduct.vendors = updatedVendors;
              }
            } else {
              this.$log.showError('Error updating vendor: Vendor not found');
              return;
            }
          }

          this.showVendorDialog = false;
          this.resetVendorForm();
          this.editingVendorIndex = -1;
        } catch (error) {
          this.$log.showError('Error saving vendor');
        }
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .class-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .v-data-table.mb-6 {
    margin-bottom: 1.5rem;
  }
  .controls-wrapper {
    padding: 0 8px;
    gap: 16px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }

  .fa-pen-to-square {
    cursor: pointer;
  }
  .fa-pen-to-square:hover {
    color: #f0ad4e;
  }
  .fa-trash {
    color: #d9534f;
    cursor: pointer;
  }
  .fa-trash:hover {
    color: red;
  }

  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }

  .tab-content {
    margin-top: -12px;
    background-color: #f5f5f5;
    border-radius: 0 0 10px 10px;
    padding: 16px;

 


  }
</style>
