<template>
  <svg :viewBox="`0 0 ${VIEWBOX_WIDTH} ${canvasHeight}`" :width="VIEWBOX_WIDTH" :height="canvasHeight" style="background: #f5f5f5; border: 1px solid #dddddd">
    <!-- LEFT SIDE -->

    <g :class="`connector-${connector.id}`" v-for="connector in leftConnectorData" :key="`left-connector-${connector.id}`">
      <rect :x="LEFT_MARGIN" :y="connector.y" :width="CONNECTOR_WIDTH" :height="connector.height" fill="#ddd" stroke="#666" />
      <text text-anchor="middle" alignment-baseline="middle" :transform="`translate(${LEFT_MARGIN - 15}, ${connector.y + connector.height / 2}) rotate(-90)`" fill="#333">
        Conn {{ connector.id }}
      </text>
      <text
        v-if="connector.key"
        text-anchor="middle"
        alignment-baseline="middle"
        :transform="`translate(${LEFT_MARGIN - 30}, ${connector.y + 50}) rotate(0)`"
        fill="#333"
        font-weight="bold"
      >
        <tspan x="0" dy="0">KEY</tspan>
        <tspan x="0" dy="1.2em">{{ connector.key }}</tspan>
      </text>

      <g v-for="(fiber, idx) in connector.fiberPositions" :key="`left-fiber-${connector.id}-${fiber.position}`">
        <circle
          :cx="LEFT_MARGIN + CONNECTOR_WIDTH / 2"
          :cy="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          r="3"
          :fill="fiber.isMissing ? 'none' : '#333'"
          :stroke="fiber.isMissing ? '#333' : 'none'"
          stroke-width="1"
        />
        <text
          :x="LEFT_MARGIN + CONNECTOR_WIDTH / 2 - 25"
          :y="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          alignment-baseline="middle"
          fill="#000"
          :class="{ 'highlighted-text': isFiberHighlighted(connector.id, fiber.position, 'left') }"
        >
          {{ fiber.position }}
        </text>
      </g>
    </g>

    <!-- RIGHT SIDE -->
    <g v-for="connector in rightConnectorData" :key="`right-connector-${connector.id}`">
      <rect :x="VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH" :y="connector.y" :width="CONNECTOR_WIDTH" :height="connector.height" fill="#ddd" stroke="#666" />
      <text
        text-anchor="middle"
        alignment-baseline="middle"
        :transform="`translate(${VIEWBOX_WIDTH - RIGHT_MARGIN + 15}, ${connector.y + connector.height / 2}) rotate(90)`"
        fill="#333"
      >
        Conn {{ connector.id }}
      </text>
      <text
        v-if="connector.key"
        text-anchor="middle"
        alignment-baseline="middle"
        :transform="`translate(${VIEWBOX_WIDTH - 30}, ${connector.y + 50}) rotate(0)`"
        fill="#333"
        font-weight="bold"
      >
        <tspan x="0" dy="0">KEY</tspan>
        <tspan x="0" dy="1.2em">{{ connector.key }}</tspan>
      </text>

      <g v-for="(fiber, idx) in connector.fiberPositions" :key="`right-fiber-${connector.id}-${fiber.position}`">
        <circle
          :cx="VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH / 2"
          :cy="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          r="3"
          :fill="fiber.isMissing ? 'none' : '#333'"
          :stroke="fiber.isMissing ? '#333' : 'none'"
          stroke-width="1"
        />
        <text
          :x="VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH / 2 + 25"
          :y="getFiberY(connector.y, idx, connector.fiberPositions.length)"
          text-anchor="end"
          alignment-baseline="middle"
          fill="#000"
          :class="{ 'highlighted-text': isFiberHighlighted(connector.id, fiber.position, 'right') }"
        >
          {{ fiber.position }}
        </text>
      </g>
    </g>

    <path
      v-for="(item, idx) in items"
      :key="`connection-${idx}`"
      :d="
        generatePath(
          getFiberX('left', item.connectorA, item.fiberAPosition),
          getFiberYByData(item.connectorA, item.fiberAPosition, 'left'),
          getFiberX('right', item.connectorB, item.fiberBPosition),
          getFiberYByData(item.connectorB, item.fiberBPosition, 'right')
        )
      "
      :stroke="getColorValue(item.color || '')"
      fill="none"
      stroke-width="3"
      @mouseenter="highlightPath(idx)"
      @mouseleave="unhighlightPath(idx)"
      :class="{ 'highlighted-path': highlightedPath === idx || isSelected(item) }"
    />
  </svg>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, PropType } from 'vue';

  type FiberPosition = number | 'A' | 'B';

  interface PolarityItem {
    fiber: number;
    color?: string;
    connectorA: number;
    fiberAPosition: FiberPosition;
    connectorB: number;
    fiberBPosition: FiberPosition;
  }

  interface FiberPositionData {
    position: FiberPosition;
    isMissing: boolean;
  }

  interface ConnectorInfo {
    sequence: 'asc' | 'desc';
    connectorType: string;
    fibers: number;
    key?: 'UP' | 'DOWN';
    connectorsCount?: number;
  }

  interface ConnectorData {
    id: number;
    fiberPositions: FiberPositionData[];
    height: number;
    y: number;
    key?: 'UP' | 'DOWN';
  }

  export default defineComponent({
    name: 'FiberConnectionsVisualizer',
    props: {
      items: { type: Array as PropType<PolarityItem[]>, required: true },
      selectedRow: { type: Object as PropType<PolarityItem | null>, default: null },
      drawOptions: { type: Object as any, default: () => ({}) },
    },
    setup(props) {
      const VIEWBOX_WIDTH = 359;
      const CONNECTOR_WIDTH = 70;
      const LEFT_MARGIN = 60;
      const RIGHT_MARGIN = 60;
      const CONNECTOR_SPACING = 20;
      const TOP_BOTTOM_PADDING = 40;
      const distanceBetweenFibers = 27;
      const INITIAL_TOP = 20;
      const colors: { [key: string]: string } = {
        Black: '#000000',
        Red: '#FF0000',
        White: '#FFFFFF',
        Slate: '#708090',
        Brown: '#A52A2A',
        Green: '#008000',
        Orange: '#FFA500',
        Blue: '#0000FF',
      };

      const highlightedPath = ref<number | null>(null);

      // Check if connectors exist on both sides
      function isValidConnection(item: PolarityItem, leftConnectors: ConnectorData[], rightConnectors: ConnectorData[]): boolean {
        const leftConnector = leftConnectors.find((c) => c.id === item.connectorA);
        const rightConnector = rightConnectors.find((c) => c.id === item.connectorB);

        if (!leftConnector || !rightConnector) {
          return false;
        }

        // Check if fiber positions exist in respective connectors
        const leftFiberExists = leftConnector.fiberPositions.some((fp) => fp.position === item.fiberAPosition);
        const rightFiberExists = rightConnector.fiberPositions.some((fp) => fp.position === item.fiberBPosition);

        return leftFiberExists && rightFiberExists;
      }

      function isSelected(item: PolarityItem) {
        return props.selectedRow && props.selectedRow.fiber === item.fiber && props.selectedRow.connectorA === item.connectorA && props.selectedRow.connectorB === item.connectorB;
      }

      function highlightPath(index: number) {
        // highlightedPath.value = index;
      }

      function unhighlightPath(index: number) {
        // if (highlightedPath.value === index) {
        //   highlightedPath.value = null;
        // }
      }

      function isFiberHighlighted(connectorId: number, fiberPosition: FiberPosition, side: 'left' | 'right'): boolean {
        if (highlightedPath.value !== null) {
          const item = props.items[highlightedPath.value];
          if (item) {
            if (side === 'left') {
              if (item.connectorA === connectorId && item.fiberAPosition === fiberPosition) {
                return true;
              }
            } else {
              if (item.connectorB === connectorId && item.fiberBPosition === fiberPosition) {
                return true;
              }
            }
          }
        }
        if (props.selectedRow) {
          if (side === 'left') {
            if (props.selectedRow.connectorA === connectorId && props.selectedRow.fiberAPosition === fiberPosition) {
              return true;
            }
          } else {
            if (props.selectedRow.connectorB === connectorId && props.selectedRow.fiberBPosition === fiberPosition) {
              return true;
            }
          }
        }
        return false;
      }

      function getConnectorInfo(side: 'left' | 'right'): ConnectorInfo {
        if (!props.drawOptions.connectorInfo) {
          return { sequence: 'asc', connectorType: '', fibers: 0 };
        }
        return side === 'left'
          ? props.drawOptions.connectorInfo.a || { sequence: 'asc', connectorType: '', fibers: 0 }
          : props.drawOptions.connectorInfo.b || { sequence: 'asc', connectorType: '', fibers: 0 };
      }

      function buildFiberPositions(connectorInfo: ConnectorInfo, side: 'left' | 'right'): FiberPositionData[] {
        const result: FiberPositionData[] = [];
        const letterPositions = new Set<FiberPosition>();
        props.items.forEach((item) => {
          if (side === 'left' && (item.connectorA || item.connectorA === 0)) {
            if (typeof item.fiberAPosition === 'string') {
              letterPositions.add(item.fiberAPosition);
            }
          }
          if (side === 'right' && (item.connectorB || item.connectorB === 0)) {
            if (typeof item.fiberBPosition === 'string') {
              letterPositions.add(item.fiberBPosition);
            }
          }
        });
        letterPositions.forEach((pos) => {
          result.push({ position: pos, isMissing: false });
        });
        const count = connectorInfo.fibers || 0;
        if (!(connectorInfo.fibers === 2 && (letterPositions.has('A') || letterPositions.has('B')))) {
          let numeric = Array.from({ length: count }, (_, i) => i + 1);
          if (connectorInfo.sequence === 'desc') {
            numeric = numeric.reverse();
          }
          numeric.forEach((num) => {
            let isMissing = false;
            if ((connectorInfo.connectorType === 'M8F' || connectorInfo.connectorType === 'M8M') && [5, 6, 7, 8].includes(num)) {
              isMissing = true;
            }
            result.push({ position: num, isMissing });
          });
        }
        return result;
      }

      function buildConnectorData(ids: number[], side: 'left' | 'right'): ConnectorData[] {
        let currentY = INITIAL_TOP;
        const data: ConnectorData[] = [];
        const info = getConnectorInfo(side);

        const connectorIds = info.connectorsCount ? Array.from({ length: info.connectorsCount }, (_, i) => i + 1) : ids;

        for (const id of connectorIds) {
          const fiberPositions = buildFiberPositions(info, side);

          let height = TOP_BOTTOM_PADDING;
          if (fiberPositions.length > 1) {
            height += (fiberPositions.length - 1) * distanceBetweenFibers;
          }

          data.push({
            id,
            fiberPositions,
            height,
            y: currentY,
            key: info.key,
          });

          currentY += height + CONNECTOR_SPACING;
        }

        return data;
      }

      const leftConnectorsRaw = computed(() => Array.from(new Set(props.items.map((item) => item.connectorA))).sort((a, b) => a - b));
      const rightConnectorsRaw = computed(() => Array.from(new Set(props.items.map((item) => item.connectorB))).sort((a, b) => a - b));
      const leftConnectorData = computed<ConnectorData[]>(() => buildConnectorData(leftConnectorsRaw.value, 'left'));
      const rightConnectorData = computed<ConnectorData[]>(() => buildConnectorData(rightConnectorsRaw.value, 'right'));

      const validItems = computed(() => {
        return props.items.filter((item) => isValidConnection(item, leftConnectorData.value, rightConnectorData.value));
      });

      const canvasHeight = computed(() => {
        const leftMax = leftConnectorData.value.length
          ? leftConnectorData.value[leftConnectorData.value.length - 1].y + leftConnectorData.value[leftConnectorData.value.length - 1].height
          : 100;
        const rightMax = rightConnectorData.value.length
          ? rightConnectorData.value[rightConnectorData.value.length - 1].y + rightConnectorData.value[rightConnectorData.value.length - 1].height
          : 100;
        return Math.max(leftMax, rightMax) + 50;
      });

      function getFiberY(baseY: number, fiberIdx: number, fiberCount: number): number {
        const startY = baseY + TOP_BOTTOM_PADDING / 2;
        return startY + fiberIdx * distanceBetweenFibers;
      }

      function getFiberYByData(connectorId: number, fiberPos: FiberPosition, side: 'left' | 'right'): number {
        const dataList = side === 'left' ? leftConnectorData.value : rightConnectorData.value;
        const cData = dataList.find((c) => c.id === connectorId);
        if (!cData) {
          return 0;
        }
        const idx = cData.fiberPositions.findIndex((fp) => fp.position === fiberPos);
        if (idx < 0) {
          return 0;
        }
        return getFiberY(cData.y, idx, cData.fiberPositions.length);
      }

      function generatePath(x1: number, y1: number, x2: number, y2: number): string {
        const cx = (x1 + x2) / 2;
        return `M ${x1} ${y1} C ${cx} ${y1}, ${cx} ${y2}, ${x2} ${y2}`;
      }

      function getFiberX(side: 'left' | 'right', connectorId: number, fiberPos: FiberPosition): number {
        return side === 'left' ? LEFT_MARGIN + CONNECTOR_WIDTH / 2 : VIEWBOX_WIDTH - RIGHT_MARGIN - CONNECTOR_WIDTH / 2;
      }

      function getColorValue(colorName: string) {
        return colors[colorName] || '#000000';
      }

      return {
        // Constants
        VIEWBOX_WIDTH,
        canvasHeight,
        LEFT_MARGIN,
        RIGHT_MARGIN,
        CONNECTOR_WIDTH,
        CONNECTOR_SPACING,
        distanceBetweenFibers,
        TOP_BOTTOM_PADDING,
        // Computed properties
        items: validItems,
        drawOptions: props.drawOptions,
        leftConnectorData,
        rightConnectorData,
        highlightedPath,
        // Methods
        isSelected,
        highlightPath,
        unhighlightPath,
        isFiberHighlighted,
        getFiberY,
        getFiberYByData,
        getFiberX,
        generatePath,
        getColorValue,
      };
    },
  });
</script>
<style scoped>
  .highlighted-path {
    stroke-width: 6px !important;
    stroke-opacity: 0.8;
    cursor: pointer;
    filter: drop-shadow(0 0 2px #555);
  }
  .highlighted-text {
    font-weight: bold;
    font-size: 18px;
    fill: #000000;
  }
  circle {
    transition: fill 0.2s;
  }
</style>
