<!-- src/components/bom/BomEditTable.vue -->
<template>
  <v-row data-cy="tab-content-bom">
    <v-col cols="12" md="12" v-if="viewBom && viewBom.length"></v-col>

    <v-col cols="12" md="12" style="padding: 0px 12px">
      <div v-if="(viewBom && viewBom.length) || standalone">
        <div class="top-line">
          <h2>BOM Lines:</h2>
          <v-btn class="btn-orange" @click="openAddNewLineModal" data-cy="add-new-item">Add New Component</v-btn>
        </div>

        <v-data-table :headers="headers" :items="viewBom" :items-per-page="-1" class="elevation-1" density="compact">
          <template #item.materials="{ item }">
            <v-select
              v-model="item.selectedMaterial"
              :items="item.materials"
              item-title="pn"
              item-value="pn"
              @update:model-value="reorderMaterials(item)"
              variant="underlined"
              hide-details
            >
              <template #item="{ props, item }">
                <v-list-item style="position: relative; max-width: 1100px" v-bind="props">
                  <template #title>
                    <span style="color: black">{{ item.value }}</span>
                  </template>
                  <template #subtitle>
                    <!-- <span style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                      {{ item.raw.description }}
                    </span> -->
                    <v-tooltip location="bottom">
                      <template #activator="{ props: tooltipActivatorProps }">
                        <span v-bind="tooltipActivatorProps" style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                          {{ item.raw.description }}
                        </span>
                      </template>
                      <!-- Tooltip Content with custom width -->
                      <div style="width: 250px; white-space: normal">
                        {{ item.raw.description }}
                      </div>
                    </v-tooltip>
                  </template>
                </v-list-item>
              </template>
              <template #prepend-item>
                <div style="display: flex; align-items: center; justify-content: space-between; padding: 8px">
                  <v-btn class="btn-orange" size="small" @click="openAddMaterialModal(item)" data-cy="add-new-item">Add Material</v-btn>
                </div>
              </template>
            </v-select>
          </template>

          <template #item.cutSize="{ item }">
            <span v-if="(item.uom === 'Each' && item.cutSize == 0) || !isLengthCategory(item.uom)"></span>
            <!-- <n-input-number v-else v-model:value="item.cutSize" placeholder="" :min="0" :max="9999" :show-button="false" :style="{ textAlign: 'right' }" /> -->
            <v-number-input v-else v-model="item.cutSize" placeholder="" :reverse="true" variant="underlined" :min="0" :max="9999" hide-details />
          </template>

          <template #item.cutUom="{ item }">
            <!-- <span>{{ item.cutUom }}</span> -->
            <div v-if="isLengthCategory(item.uom)">
              <!-- <n-select v-model:value="item.cutUom" :options="getCutUomOptions(item.uom)" placeholder="" /> -->
              <v-select v-model="item.cutUom" :items="getCutUomOptions(item.uom)" item-title="label" item-value="value" placeholder="" variant="underlined" hide-details></v-select>
            </div>
          </template>

          <template #item.cutQuantity="{ item }">
            <span v-if="(item.uom === 'Each' && item.cutQuantity == 0) || !isLengthCategory(item.uom)"></span>
            <!-- <n-input-number v-else v-model:value="item.cutQuantity" placeholder="" :min="0" :max="9999" :show-button="false" :style="{ textAlign: 'right' }" /> -->
            <v-number-input v-else v-model="item.cutQuantity" placeholder="" :reverse="true" variant="underlined" :min="0" :max="9999" hide-details />
          </template>

          <template #item.quantity="{ item }">
            <v-number-input :disabled="item.uom !== 'Each'" v-model="item.quantity" placeholder="" :reverse="true" variant="underlined" :min="0" :max="9999" hide-details />
          </template>

          <template #item.uom="{ item }">
            <span>{{ item.uom }}</span>
          </template>

          <template #item.consumedOperation="{ item }">
            <!-- <n-select v-model:value="item.consumedOperation" :options="consumedOperationOptions" placeholder="" clearable filterable /> -->
            <v-select
              v-model="item.consumedOperation"
              :items="consumedOperationOptions"
              label=""
              clearable
              hide-details
              item-title="label"
              item-value="value"
              filter
              variant="underlined"
            ></v-select>
          </template>

          <template #item.action="{ item }">
            <span @click="showDeleteDialog(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
          </template>
        </v-data-table>
      </div>

      <div v-else class="text-center no-data" data-cy="details-content">
        <div v-if="!loadingData">
          <h2 data-cy="details-title">No Product Data</h2>
          <p data-cy="details-description">
            Please enter a
            <strong>PN</strong>
            and press the
            <span class="mini-btn"><strong style="color: orange">GENERATE</strong></span>
            button.
          </p>
        </div>
        <v-skeleton-loader v-else type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
      </div>
    </v-col>

    <v-dialog v-model="isAddMaterialModalOpen" max-width="600px" persistent>
      <v-card>
        <!-- Header -->
        <v-card-title class="modal-title" data-cy="compare-modal-title">
          <span class="text-h5">Add New Material</span>
          <v-icon small @click="closeModal" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
        </v-card-title>

        <!-- Select Material -->
        <v-card-text>
          <div>Select Material:</div>
          <div style="margin: 16px 0">
            <v-combobox
              v-model="selectedMaterial"
              :items="odooMaterialsOptions"
              item-title="pn"
              item-value="pn"
              label="Search and Select Material (min 3 characters)"
              clearable
              filterable
              :loading="loadingMaterials"
              @update:search="handleSearchMaterial"
              @update:modelValue="setMaterialDetails"
              hide-details
              variant="underlined"
            >
              <template #item="{ props, item }">
                <v-list-item style="position: relative; max-width: 550px" v-bind="props">
                  <template #title>
                    <span style="color: black">{{ item.value }}</span>
                  </template>
                  <template #subtitle>
                    <!-- <span style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                      {{ item.raw.description }}
                    </span> -->
                    <v-tooltip location="left">
                      <template #activator="{ props: tooltipActivatorProps }">
                        <span v-bind="tooltipActivatorProps" style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                          {{ item.raw.description }}
                        </span>
                      </template>
                      <!-- Tooltip Content with custom width -->
                      <div style="width: 250px; white-space: normal">
                        {{ item.raw.description }}
                      </div>
                    </v-tooltip>
                  </template>
                </v-list-item>
              </template>

              <!-- <template #prepend-item>
                <v-progress-circular v-if="loadingMaterials" indeterminate size="24"></v-progress-circular>
              </template> -->
            </v-combobox>
          </div>

          <!-- Display Selected Material Details -->
          <div v-if="selectedMaterialDetails">
            <p>
              <strong>Component PN:</strong>
              {{ selectedMaterialDetails.pn }}
            </p>
            <p>
              <strong>Description:</strong>
              {{ selectedMaterialDetails.description }}
            </p>
          </div>
        </v-card-text>

        <!-- Footer -->
        <v-card-actions>
          <v-btn block class="btn-orange" @click="confirmAddMaterial" data-cy="add-new-item">Add Material</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isAddNewLineModalOpen" max-width="600px">
      <template #activator="{ props }">
        <!-- Place your activator button or element here -->
      </template>

      <v-card>
        <v-card-title class="modal-title" data-cy="compare-modal-title">
          <span class="text-h5">Add New BOM Line</span>
          <v-icon small @click="closeModal" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
        </v-card-title>
        <br />

        <v-card-subtitle>Select Material:</v-card-subtitle>

        <v-card-text>
          <v-combobox
            v-model="selectedMaterial"
            :items="odooMaterialsOptions"
            item-title="pn"
            item-value="pn"
            label="Search and Select Material (min 3 characters)"
            clearable
            filterable
            :loading="loadingMaterials"
            @update:search="handleSearchMaterial($event, 'newRow')"
            @update:modelValue="setMaterialDetails"
            hide-details
            variant="underlined"
          >
            <template #item="{ props, item }">
              <v-list-item style="position: relative; max-width: 550px" v-bind="props">
                <template #title>
                  <span style="color: black">{{ item.value }}</span>
                </template>
                <template #subtitle>
                  <!-- <span style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                    {{ item.raw.description }}
                  </span> -->
                  <v-tooltip location="left">
                    <template #activator="{ props: tooltipActivatorProps }">
                      <span v-bind="tooltipActivatorProps" style="color: gray; display: block; word-wrap: break-word; word-break: break-word; white-space: normal">
                        {{ item.raw.description }}
                      </span>
                    </template>
                    <!-- Tooltip Content with custom width -->
                    <div style="width: 250px; white-space: normal">
                      {{ item.raw.description }}
                    </div>
                  </v-tooltip>
                </template>
              </v-list-item>
            </template>
          </v-combobox>

          <!-- :custom-filter="customFilter" -->

          <div v-if="selectedMaterialDetails" style="margin-top: 16px">
            <p>
              <strong>Component PN:</strong>
              {{ selectedMaterialDetails.pn }}
            </p>
            <p>
              <strong>Description:</strong>
              {{ selectedMaterialDetails.description }}
            </p>
            <p>
              <strong>UOM:</strong>
              {{ selectedMaterialDetails.uom }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn block class="btn-orange" @click="confirmAddNewLine(selectedMaterialDetails)" data-cy="add-new-item">Add New Line</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <Dialogs :deleteDialog="deleteDialog" @confirmDeleteItem="confirmDeleteItem" @closeDeleteDialog="closeDeleteDialog" />
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import Dialogs from '@/components/common/Dialogs.vue';
  import etcService from '@/services/api/etcService';
  import { VNumberInput } from 'vuetify/lib/labs/components.mjs';
  import type { VNode } from 'vue';
  import { h, ref } from 'vue';
  import type { SelectOption } from 'naive-ui';
  import { NTooltip } from 'naive-ui';

  interface Material {
    pn: string;
    odoo_id: number;
    description: string;
    uom?: string;
  }

  interface BomItem {
    materials: Material[];
    material?: Material;
    selectedMaterial: string;
    cutSize: number;
    cutUom: string;
    cutQuantity: number;
    quantity: number;
    uom: string;
    consumedOperation?: string;
  }

  export default defineComponent({
    name: 'GenerateViewBom',
    components: {
      Dialogs,
      VNumberInput,
    },
    props: {
      viewBom: {
        type: Array as PropType<BomItem[]>,
        required: false,
        default: () => [],
      },
      loadingData: {
        type: Boolean,
        required: false,
      },
      standalone: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        headers: [
          { title: 'Component', value: 'materials', sortable: false, width: '25%' },
          { title: 'Cut Size', value: 'cutSize', sortable: false, width: '10%' },
          { title: 'Cut UoM', value: 'cutUom', sortable: false, width: '13%' },
          { title: 'Cuts Num', value: 'cutQuantity', sortable: false, width: '10%' },
          { title: 'Quantity', value: 'quantity', sortable: false, width: '10%' },
          { title: 'UOM', value: 'uom', sortable: false, width: '13%' },
          { title: 'Consumed In', value: 'consumedOperation', sortable: false, width: '19%' },
          { title: '', value: 'action', sortable: false, width: '2%' },
        ],
        consumedOperationOptions: [
          { label: 'Cut Fiber Cable', value: 'Cut Fiber Cable' },
          { label: 'Cut Hybrid Trunk Cable', value: 'Cut Hybrid Trunk Cable' },
        ],
        deleteDialog: false,
        itemToDelete: null as BomItem | null,
        isAddMaterialModalOpen: false,
        isAddNewLineModalOpen: false,
        selectedMaterial: null as any,
        selectedMaterialDetails: [] as any,
        loadingMaterials: false,
        currentBomItem: null as BomItem | null,
        odooMaterialsOptions: [] as Material[],
        unitOptions: [] as any[],
        unitConversionRates: {
          Meter: 1,
          Foot: 0.3048,
          Millimeter: 0.001,
          Inch: 0.0254,
          Centimeter: 0.01,
          NanoMeter: 1e-9,
        } as Record<string, number>,
      };
    },
    mounted() {
      this.initializeMaterialSelection();
      this.getUnits();
    },
    watch: {
      viewBom: {
        handler(newBom) {
          newBom.forEach((item: any) => {
            if (this.isLengthCategory(item.cutUom)) {
              this.calculateQuantity(item);
            }
          });
        },
        deep: true,
      },
    },
    methods: {
      handleSearch() {
        console.log('searching');
      },
      async getUnits() {
        try {
          this.unitOptions = await etcService.getUnits();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      renderOption: ({ node, option }: { node: VNode; option: SelectOption }) =>
        h(NTooltip, { placement: 'left', style: { maxWidth: '250px' } }, { trigger: () => node, default: () => `${option.description}` }),
      initializeMaterialSelection() {
        this.viewBom.forEach((item) => {
          if (Array.isArray(item.materials) && item.materials[0]?.pn) {
            item.selectedMaterial = item.materials[0].pn;
          } else if (item.material?.pn) {
            item.selectedMaterial = item.material.pn;
          } else {
            item.selectedMaterial = '';
          }
        });
      },
      showDeleteDialog(item: BomItem) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },
      closeDeleteDialog() {
        this.deleteDialog = false;
        this.itemToDelete = null;
      },
      confirmDeleteItem() {
        if (this.itemToDelete) {
          const index = this.viewBom.indexOf(this.itemToDelete);
          if (index > -1) {
            this.viewBom.splice(index, 1);
          }
          this.closeDeleteDialog();
        }
      },
      reorderMaterials(item: BomItem) {
        const selected = item.selectedMaterial;
        const index = item.materials.findIndex((material) => material.pn === selected);
        if (index > -1) {
          const [selectedMaterial] = item.materials.splice(index, 1);
          item.materials.unshift(selectedMaterial);
        }
      },

      addNewMaterial(item: any) {
        console.log('add new material', item);
      },

      openAddMaterialModal(item: BomItem) {
        this.selectedMaterial = '';
        this.selectedMaterialDetails = null;
        this.odooMaterialsOptions = [];
        this.currentBomItem = item;
        this.isAddMaterialModalOpen = true;
      },
      openAddNewLineModal() {
        this.selectedMaterial = '';
        this.selectedMaterialDetails = null;
        this.odooMaterialsOptions = [];
        this.isAddNewLineModalOpen = true;
      },
      async handleSearchMaterial(query?: string, type?: string) {
        if (!query || query.length < 3) return;

        this.loadingMaterials = true;
        try {
          const response = await etcService.getOdooMaterials(query);
          this.odooMaterialsOptions = response
            .filter((material: any) => material.active)
            .map((material: any) => {
              const regex = /^\[(.*?)\]\s*(.*)$/;
              const match = material.display_name.match(regex);
              if (type === 'newRow') {
                return {
                  pn: match ? match[1] : '',
                  description: match ? match[2] : '',
                  odoo_id: material.id,
                  uom: material?.uom_id.val || '',
                };
              } else {
                return {
                  pn: match ? match[1] : '',
                  description: match ? match[2] : '',
                  odoo_id: material.id,
                };
              }
            });
        } catch (error: any) {
          this.$error.view(error);
        } finally {
          this.loadingMaterials = false;
        }
      },
      setMaterialDetails(materialPn: any) {
        const pn = materialPn.pn;
        const material = this.odooMaterialsOptions.find((m) => m.pn === pn);
        if (material) {
          this.selectedMaterialDetails = material;
        }
      },

      confirmAddMaterial() {
        if (this.currentBomItem && this.selectedMaterial) {
          const materialPn = this.selectedMaterial.pn;
          const material = this.odooMaterialsOptions.find((m) => m.pn === materialPn);

          if (material) {
            this.currentBomItem.materials = this.currentBomItem.materials.filter((m) => m.pn !== material.pn);
            this.currentBomItem.materials.unshift(material);
            this.currentBomItem.selectedMaterial = material.pn;
          }
        }

        this.isAddMaterialModalOpen = false;
        this.selectedMaterial = '';
      },

      confirmAddNewLine(row: any) {
        this.viewBom.push({
          materials: [{ pn: row.pn, odoo_id: row.odoo_id, description: row.description }],
          selectedMaterial: this.selectedMaterial,
          cutSize: 0,
          cutUom: '',
          cutQuantity: 0,
          quantity: 1,
          uom: row.uom || '',
        });

        this.isAddNewLineModalOpen = false;
        this.selectedMaterial = '';
      },
      isLengthCategory(uom: string) {
        const category = this.unitOptions.find((category) => category.value.some((unit: any) => unit.name === uom));
        return category && category.name === 'Length';
      },
      getCutUomOptions(itemUom: string) {
        const category = this.unitOptions.find((category) => category.value.some((unit: any) => unit.name === itemUom));
        if (category && category.name === 'Length') {
          return category.value.map((unit: any) => ({
            label: unit.name,
            value: unit.name,
          }));
        }
        return [];
      },
      closeModal() {
        this.isAddMaterialModalOpen = false;
        this.isAddNewLineModalOpen = false;
      },

      calculateQuantity(item: BomItem) {
        const cutUomRate = this.unitConversionRates[item.cutUom] || 1;
        const itemUomRate = this.unitConversionRates[item.uom] || 1;

        if (!item.cutSize || !item.cutQuantity || !cutUomRate || !itemUomRate) {
          item.quantity = 0;
          return;
        }

        const sizeInMeters = item.cutSize * cutUomRate;
        const sizeInTargetUom = sizeInMeters / itemUomRate;
        const rawQuantity = sizeInTargetUom * item.cutQuantity;
        console.log('rawQuantity', rawQuantity);
        item.quantity = parseFloat(rawQuantity.toFixed(2));

        // console.log('item.quantity', item.quantity);
      },
    },
  });
</script>

<style scoped>
  .no-data {
    padding-top: 62px;
  }
  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }
  span > i.fa-trash:hover {
    cursor: pointer;
    color: red;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }

  :deep(.v-table tr:hover) {
    background-color: #f9f9f9;
  }
  :deep(.v-table tr) {
    height: 44px;
  }
  .top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .modal-title {
    background-color: #3b516b;
    color: white;
    /* padding: 16px 24px; */
  }

  .compare-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  .info-section {
    min-width: 0;
  }

  .field-label {
    color: #64748b;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 400;
  }

  .field-value {
    color: #1e293b;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mt-6 {
    margin-top: 24px;
  }

  .btn-close {
    background-color: #cce3ff;
    color: rgb(56, 104, 207);
  }
  :deep(.v-number-input__control) {
    display: none !important;
  }
  :deep(.v-field__prepend-inner) {
    display: none !important;
  }
  ::v-deep(.v-field--disabled) {
    opacity: 1 !important;
  }
</style>
