<!-- src/components/components/BomTable.vue -->
<template>
  <v-data-table :headers="bomHeaders" :items="bomList" :items-per-page="-1" class="elevation-1" density="compact">
    <template #item.materials="{ item }">
      <span class="component-item">
        {{ Array.isArray(item.materials) && item.materials[0]?.pn ? item.materials[0].pn : item.material?.pn }}
        <n-tooltip v-if="Array.isArray(item.materials) && item.materials[0]?.virtual" trigger="hover" placement="top">
          <template #trigger>
            <span>*</span>
          </template>
          Virtual material based on
          <strong>{{ item.materials[0]?.originalPn }}</strong>
          <hr style="margin-bottom: 4px" />
          Material does not exist in the system yet.
          <br />
          It will be added to the system after BOM is created in Odoo
        </n-tooltip>
      </span>
    </template>
    <template #item.description="{ item }">
      <span class="table-desc">
        {{
          Array.isArray(item.materials) && item.materials[0]?.description
            ? item.materials[0].description.replace(/\[.*?\]\s*/, '')
            : item.material?.description.replace(/\[.*?\]\s*/, '')
        }}
      </span>
    </template>
    <!-- <template #item.cutSize="{ item }">
      <span v-if="(item.uom === 'Each' && item.cutSize == 0) || !isLengthCategory(item.uom)"></span>
      <span class="float-right" v-else>{{ item.cutSize.toFixed(2) }}</span>
    </template> -->
    <template #item.cutSize="{ item }">
      <span v-if="(item.uom === 'Each' && item.cutSize > 0) || (isLengthCategory(item.uom) && item.cutSize > 0)" class="float-right">
        {{ item.cutSize.toFixed(2) }}
      </span>
    </template>
    <template #item.cutUom="{ item }">
      <span>{{ item.cutUom }}</span>
    </template>
    <!-- <template #item.cutQuantity="{ item }">
      <span v-if="(item.uom === 'Each' && item.cutQuantity == 0) || !isLengthCategory(item.uom)"></span>
      <span class="float-right" v-else>{{ item.cutQuantity }}</span>
    </template> -->
    <template #item.cutQuantity="{ item }">
      <span v-if="(item.uom === 'Each' && item.cutQuantity > 0) || (isLengthCategory(item.uom) && item.cutQuantity > 0)" class="float-right">
        {{ item.cutQuantity }}
      </span>
    </template>
    <template #item.quantity="{ item }">
      <span class="float-right">{{ item.quantity.toFixed(2) }}</span>
    </template>
    <template #item.uom="{ item }">
      <span>{{ item.uom }}</span>
    </template>
    <template #item.consumedOperation="{ item }">
      <span>{{ item.consumedOperation }}</span>
    </template>
  </v-data-table>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import etcService from '@/services/api/etcService';

  export default defineComponent({
    name: 'BomDataTable',
    props: {
      bomList: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: true,
      },
    },
    data() {
      return {
        bomHeaders: [
          { title: 'Component', value: 'materials', width: '18%' },
          { title: 'Description', value: 'description', width: '35%' },
          { title: 'Cut Size', value: 'cutSize', align: 'end' as const },
          { title: 'Cut UoM', value: 'cutUom' },
          { title: 'Cuts Num', value: 'cutQuantity', align: 'end' as const },
          { title: 'Quantity', value: 'quantity', align: 'end' as const },
          { title: 'UOM', value: 'uom' },
          { title: 'Consumed in', value: 'consumedOperation' },
        ],
        unitOptions: [] as Array<Record<string, any>>,
      };
    },
    methods: {
      async getUnits() {
        try {
          this.unitOptions = await etcService.getUnits();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      isLengthCategory(uom: string) {
        const category = this.unitOptions.find((category) => category.value.some((unit: any) => unit.name === uom));
        return category && category.name === 'Length';
      },
    },
    mounted() {
      this.getUnits();
    },
  });
</script>

<style scoped>
  .component-item {
    text-wrap: nowrap;
  }
  .table-desc {
    color: #5e5e5e;
    font-size: 14px;
  }
</style>
