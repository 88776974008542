<template>
  <div class="generate-drawings">
    <v-row>
      <!-- Drawings List Column -->
      <v-col cols="12">
        <!-- Drawings List Card -->
        <v-card class="drawings-list mb-4">
          <v-tabs v-model="activeTab" color="primary" class="mb-2">
            <v-tab value="customer">Customer Drawing</v-tab>
            <v-tab value="manufacture">Manufacturing Drawing</v-tab>
            <v-spacer></v-spacer>
            <!-- Orientation at bottom -->
            <div class="d-flex align-center" style="margin-top: 8px">
              <v-select
                :items="['Auto', 'Landscape', 'Portrait']"
                v-model="selectedOrientation"
                hide-details
                label="Orientation"
                density="compact"
                variant="underlined"
                style="width: 150px"
              ></v-select>
            </div>
            <!-- Generate Drawing Button -->
            <div class="px-4 py-2 d-flex justify-end">
              <v-btn :disabled="isWaiting || isRequestDisabled" @click="handleDrawingRequest" class="btn-orange bold-text">GENERATE DRAWING</v-btn>
            </div>
          </v-tabs>
          <div class="drawings-list-container">
            <v-list density="compact">
              <template v-if="filteredDrawingsList.length > 0">
                <v-list-item v-for="item in filteredDrawingsList" :key="item.fileName" :title="item.fileName">
                  <template v-slot:subtitle>
                    <div class="d-flex align-center">
                      <span class="me-2">{{ item.createdDate }}</span>
                      <span class="me-2">•</span>
                      <span class="me-2">{{ item.username }}</span>
                      <v-chip
                        size="small"
                        :color="getStatusColor(item.status)"
                        class="text-caption"
                        :text-color="getStatusTextColor(item.status)"
                      >
                        {{ item.status }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <v-btn icon size="small" class="mr-2" color="primary" variant="text" v-bind="props" :title="'Download'">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <v-list density="compact">
                        <v-list-item @click="downloadPdfDrawing(item)" :disabled="!item.pdfUrl">
                          <template v-slot:prepend>
                            <v-icon color="primary">mdi-file-pdf-box</v-icon>
                          </template>
                          <v-list-item-title>Download PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="downloadVisioDrawing(item)" :disabled="!item.visioUrl">
                          <template v-slot:prepend>
                            <v-icon color="success">mdi-microsoft-visio</v-icon>
                          </template>
                          <v-list-item-title>Download Visio</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn icon size="small" class="mr-2" @click="viewDrawing(item)" color="grey" variant="text" :title="'View Drawing'">
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn icon size="small" @click="confirmDelete(item)" color="error" variant="text" :title="'Delete Drawing'">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-list-item>
              </template>
              <template v-else>
                <div class="pa-4 text-center text-grey">
                  <v-icon icon="mdi-file-document-outline" size="large" color="grey" class="mb-2"></v-icon>
                  <div>No Drawings Available</div>
                </div>
              </template>
            </v-list>
          </div>
          <hr class="mt-0" />

          <div class="px-4 py-3 align-center">
            <div class="settings-card">
              <div class="settings-header" @click="settingsPanel = !settingsPanel">
                <span class="settings-title"><i class="fa-duotone fa-solid fa-gear"></i> &nbsp; Settings</span>
                <v-icon :icon="settingsPanel ? 'mdi-chevron-up' : 'mdi-chevron-down'" size="small"></v-icon>
              </div>
              
              <v-expand-transition>
                <div v-show="settingsPanel" class="settings-content">
                  <!-- Tolerance Section -->
                  <div>
                    <p class="additional-title">Overall Cable Length Tolerance</p>
                    <v-data-table :headers="toleranceHeaders" :items="toleranceItems" item-key="name" class="elevation-1">
                      <template v-slot:item.MFA="{ item }">
                        <v-text-field v-model="item.MFA" variant="underlined" hide-details></v-text-field>
                      </template>
                      <template v-slot:item.tolerance_mm="{ item }">
                        <v-text-field v-model="item.tolerance_mm" variant="underlined" hide-details></v-text-field>
                      </template>
                      <template v-slot:item.tolerance_in="{ item }">
                        <v-text-field v-model="item.tolerance_in" variant="underlined" hide-details></v-text-field>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn @click="removeToleranceLine(item)" color="red">
                          <i class="fa-duotone fa-solid fa-trash"></i>
                        </v-btn>
                      </template>
                      <template v-slot:no-data>No data available, please add new line</template>
                      <template v-slot:bottom>
                        <v-toolbar flat class="mt-2">
                          <v-btn @click="addNewToleranceLine" :disabled="disableAddNewToleranceLine" class="btn-orange ml-4">Add new line</v-btn>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </div>
                  <!-- Note Section -->
                  <div class="mb-4">
                    <p class="text-subtitle-2 mb-2"><span class="note-label">Note</span></p>
                    <v-textarea v-model="drawNote" rows="4" hide-details density="compact" variant="outlined"></v-textarea>
                  </div>
                </div>
              </v-expand-transition>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Add view dialog -->
    <v-dialog v-model="viewDialog" max-width="90vw">
      <v-card>
        <v-card-title class="modal-title">
          <span>View Drawing</span>
          <v-icon small @click="viewDialog = false" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <vue-pdf-app v-if="selectedDrawing?.pdfUrl" :pdf="selectedDrawing.pdfUrl" style="width: 100%; height: 76vh" theme="light" :config="config" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add confirmation dialog -->
    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title class="modal-title">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this drawing?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="deleteDrawing">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import drawingService from '@/services/api/drawingService';
  import VuePdfApp from 'vue3-pdf-app';
  import 'vue3-pdf-app/dist/icons/main.css';
  import { useProductStore } from '@/store/productStore';
  import Loader from '@/components/bomg/Loader.vue';
  import { remove } from 'lodash';

  export default defineComponent({
    name: 'GenerateDrawings',
    components: {
      VuePdfApp,
      Loader,
    },
    data() {
      return {
        pngUrl: null as string | null,
        renderRequestId: '' as string,
        isWaiting: false,
        pollingIntervalId: null as number | null,
        isRequestDisabled: false,
        pdfFileUrl: null as string | null,
        visioFileUrl: null as string | null,
        config: {
          toolbar: {
            toolbarViewerLeft: false,
            openFile: false,
            viewBookmark: false,
          },
        },
        progress: 0,
        drawNote: '',
        settingsPanel: false,
        toleranceHeaders: [
          { title: 'MFA length in meters', value: 'MFA' },
          { title: 'Overall length tolerance in mm', value: 'tolerance_mm' },
          { title: 'Overall length tolerance in inches', value: 'tolerance_in' },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
        toleranceItems: [] as Array<{ MFA: string; tolerance_mm: string; tolerance_in: string }>,
        selectedOrientation: 'Auto',
        drawingsHeaders: [
          { title: 'File Name', value: 'fileName', width: '40%' },
          { title: 'Type', value: 'type', width: '20%' },
          { title: 'Created Date', value: 'createdDate', width: '30%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        drawingsList: [
          {
            fileName: 'Drawing_2024-03-20',
            createdDate: '3/20/2024, 10:30:45 AM',
            pdfUrl: 'https://example.com/drawings/customer_123.pdf',
            pngUrl: 'https://example.com/drawings/customer_123.png',
            visioUrl: 'https://example.com/drawings/customer_123.vsdx',
            type: 'customer',
            username: 'John Doe',
            status: 'APPROVED'
          },
           {
            fileName: 'Drawing_2024-03-20',
            createdDate: '3/20/2024, 10:30:45 AM',
            pdfUrl: 'https://example.com/drawings/customer_123.pdf',
            pngUrl: 'https://example.com/drawings/customer_123.png',
            visioUrl: 'https://example.com/drawings/customer_123.vsdx',
            type: 'customer',
            username: 'Baba Vera',
            status: 'DRAFT'
          },
          {
            fileName: 'Drawing_2024-03-19',
            createdDate: '3/19/2024, 2:15:30 PM',
            pdfUrl: 'https://example.com/drawings/manufacturing_456.pdf',
            pngUrl: 'https://example.com/drawings/manufacturing_456.png',
            visioUrl: 'https://example.com/drawings/manufacturing_456.vsdx',
            type: 'manufacture',
            username: 'Jane Smith',
            status: 'DRAFT'
          },
          {
            fileName: 'Drawing_2024-03-18',
            createdDate: '3/18/2024, 9:45:12 AM',
            pdfUrl: 'https://example.com/drawings/customer_789.pdf',
            pngUrl: 'https://example.com/drawings/customer_789.png',
            visioUrl: 'https://example.com/drawings/customer_789.vsdx',
            type: 'customer',
            username: 'Mike Johnson',
            status: 'PENDING'
          },
        ],
        viewDialog: false,
        deleteDialog: false,
        selectedDrawing: null as any,
        drawingToDelete: null as any,
        activeTab: 'customer',
      };
    },

    props: {
      items: {
        type: Array as () => any[],
        required: false,
        default: () => [],
      },
      polarityData: {
        type: Object as PropType<any>,
        required: false,
        default: () => ({}),
      },
    },
    setup() {
      const productStore = useProductStore();
      return { productStore };
    },

    watch: {
      drawNote: {
        handler(newValue) {
          this.saveToStore();
        },
      },
      selectedOrientation: {
        handler(newValue) {
          this.saveToStore();
        },
      },
      toleranceItems: {
        handler(newValue) {
          this.saveToStore();
        },
        deep: true,
      },
    },
    computed: {
      disableAddNewToleranceLine() {
        return this.toleranceItems.some((item) => !item.MFA || !item.tolerance_mm || !item.tolerance_in);
      },
      filteredDrawingsList() {
        if (this.activeTab === 'customer') {
          return this.drawingsList.filter((item) => item.type === 'customer');
        } else if (this.activeTab === 'manufacture') {
          return this.drawingsList.filter((item) => item.type === 'manufacture');
        }
        return this.drawingsList;
      },
    },
    methods: {
      handleRequestClick() {
        this.resetState();
        this.mapConnectorsData();

        const productPn = this.productStore.currentProduct.productPn;
        const customerPn = this.productStore.currentProduct.customerPn;
        const description = this.productStore.currentProduct.description;
        const polarity = (this.productStore.currentProduct.polarity as { polarityData?: any })?.polarityData ?? {};
        const tolerance = this.productStore.currentProduct.tolerance;
        const note = this.productStore.currentProduct.note;
        const components = this.productStore.currentProduct.components;

        const patternInfo = (this.productStore.currentProduct.polarity as { patternInfo?: any })?.patternInfo;
        const polarityAdditionalData = {
          pattern: patternInfo?.pattern ?? null,
          connectorsInfo: patternInfo?.connectorsInfo ?? null,
        };

        const requestData = {
          structure: this.items[0],
          product: {
            description,
            productPn,
            customerPn,
            polarityAdditionalData,
            tolerance,
            note,
            components,
          },
          polarity,
        };

        this.sendRenderRequest(requestData, 'customer');
        // console.log('Request Data:', requestData);
      },
      handleManufactureRequestClick() {
        this.resetState();
        this.mapConnectorsData();

        const productPn = this.productStore.currentProduct.productPn;
        const customerPn = this.productStore.currentProduct.customerPn;
        const description = this.productStore.currentProduct.description;
        const polarity = (this.productStore.currentProduct.polarity as { polarityData?: any })?.polarityData ?? {};
        const tolerance = this.productStore.currentProduct.tolerance;
        const note = this.productStore.currentProduct.note;
        const components = this.productStore.currentProduct.components;

        const patternInfo = (this.productStore.currentProduct.polarity as { patternInfo?: any })?.patternInfo;
        const polarityAdditionalData = {
          pattern: patternInfo?.pattern ?? null,
          connectorsInfo: patternInfo?.connectorsInfo ?? null,
        };

        const requestData = {
          structure: this.items[0],
          product: {
            description,
            productPn,
            customerPn,
            polarityAdditionalData,
            tolerance,
            note,
            components,
          },
          polarity,
        };

        this.sendRenderRequest(requestData, 'manufacture');
        // console.log('Request Data:', requestData);
      },

      mapConnectorsData() {
        if (!this.polarityData?.polarityData || !Array.isArray(this.polarityData.polarityData)) {
          return;
        }

        this.polarityData.polarityData = this.polarityData.polarityData.map((item: any) => {
          const connectorAIndex = item.connectorA - 1;
          const connectorBIndex = item.connectorB - 1;

          const connectorAData = this.polarityData.connectorsData[0]?.[connectorAIndex] || {};
          const connectorBData = this.polarityData.connectorsData[1]?.[connectorBIndex] || {};

          return {
            ...item,
            legIdA: connectorAData.leg || null,
            breakoutA: connectorAData.breakout || null,
            connectorTypeA: connectorAData.connector || null,
            legIdB: connectorBData.leg || null,
            breakoutB: connectorBData.breakout || null,
            connectorTypeB: connectorBData.connector || null,
          };
        });
      },

      resetState() {
        this.pngUrl = null;
        this.renderRequestId = '';
        this.isWaiting = false;
        this.clearSessionData();
        this.progress = 0;
      },

      async sendRenderRequest(data: any, type: string) {
        try {
          const serviceMethod = type === 'customer' ? drawingService.requestRender : drawingService.requestManufactureRender;

          const orientation = this.productStore.currentProduct.orientation.toLowerCase();
          const response = await serviceMethod(data, orientation);

          if (response?.code) {
            this.renderRequestId = response.code;
            sessionStorage.setItem('renderID', this.renderRequestId);
            sessionStorage.setItem('renderType', type);
            this.initiatePolling();
            this.fetchDrawing();
            this.isWaiting = true;
          } else {
            this.$log.showError('No code received from server');
          }
        } catch (error) {
          this.$log.showError('Error sending render request', error);
        }
      },

      initiatePolling() {
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
        }

        this.pollingIntervalId = setInterval(() => this.fetchDrawing(), 10000);
      },

      async fetchDrawing() {
        if (!this.renderRequestId) {
          this.$log.showError('No renderRequestId found.');
          return;
        }

        try {
          const renderType = sessionStorage.getItem('renderType') || 'customer';
          const fetchMethod = renderType === 'customer' ? drawingService.getRender : drawingService.getManufactureRender;

          const response = await fetchMethod(this.renderRequestId);

          if (response?.status === 'error') {
            const errorMessage = response.errors?.[0]?.message || 'An unknown error occurred.';
            this.$log.showError(`${errorMessage}`);
            this.stopPolling();
            return;
          }

          if (response?.error) {
            this.$log.showError('Error fetching drawing:', response.errors);
            this.stopPolling();
            return;
          }

          if (response?.progress !== undefined) {
            this.progress = Number(response.progress);
          }

          if (response?.pngUrl) {
            this.handleSuccessfulFetch(response);
          } else {
            this.$log.info('Drawing is still being processed');
          }
        } catch (error) {
          this.$log.showError('Error fetching drawing', error);
          this.cleanupOnError();
        }
      },

      handleSuccessfulFetch(response: any) {
        this.pngUrl = response.pngUrl;
        this.pdfFileUrl = response.pdfUrl;
        this.visioFileUrl = response.vsdxUrl;

        this.$log.info('Drawing fetched successfully:', this.pngUrl);
        this.isWaiting = false;

        if (this.pngUrl) {
          sessionStorage.setItem('pngUrl', this.pngUrl);
        }
        if (this.pdfFileUrl) {
          sessionStorage.setItem('pdfUrl', this.pdfFileUrl);
        }
        if (this.visioFileUrl) {
          sessionStorage.setItem('vsdxUrl', this.visioFileUrl);
        }

        this.stopPolling();

        // Get the render type from session storage
        const renderType = sessionStorage.getItem('renderType') || 'customer';

        // Add the new drawing to the list with type checking
        if (this.pdfFileUrl && this.pngUrl && this.visioFileUrl) {
          this.drawingsList.unshift({
            fileName: `${renderType === 'customer' ? 'Customer' : 'Manufacturing'}_Drawing_${new Date().toISOString().split('T')[0]}`,
            createdDate: new Date().toLocaleString(),
            pdfUrl: this.pdfFileUrl,
            pngUrl: this.pngUrl,
            visioUrl: this.visioFileUrl,
            type: renderType,
            username: 'Current User',
            status: 'DRAFT'
          });
        }
      },

      stopPolling() {
        this.isWaiting = false;
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
        }
        sessionStorage.removeItem('renderID');
      },

      cleanupOnError() {
        this.resetState();
        if (this.pollingIntervalId) {
          clearInterval(this.pollingIntervalId);
          this.pollingIntervalId = null;
        }
      },

      downloadImage() {
        if (!this.pngUrl) return;
        this.downloadFile(this.pngUrl, 'rendered_image.png');
      },

      downloadPDF() {
        if (!this.pdfFileUrl) return;
        this.downloadFile(this.pdfFileUrl, 'rendered_drawing.pdf');
      },

      downloadVisio() {
        if (!this.visioFileUrl) return;
        this.downloadFile(this.visioFileUrl, 'rendered_drawing.vsdx');
      },

      downloadFile(fileUrl: string, filename: string) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = filename;
        link.click();
        this.$log.info(`File downloaded: ${filename}`);
      },

      addNewToleranceLine() {
        this.toleranceItems.push({ MFA: '', tolerance_mm: '', tolerance_in: '' });
      },
      removeToleranceLine(item: any) {
        remove(this.toleranceItems, item);
      },
      saveToStore() {
        this.productStore.currentProduct.note = this.drawNote;
        this.productStore.currentProduct.orientation = this.selectedOrientation;
        this.productStore.currentProduct.tolerance = this.toleranceItems;
      },
      restoreFromStore() {
        this.drawNote = this.productStore.currentProduct.note;
        this.selectedOrientation = this.productStore.currentProduct.orientation || 'Auto';
        this.toleranceItems = this.productStore.currentProduct.tolerance;
      },

      clearSessionData() {
        sessionStorage.removeItem('pngUrl');
        sessionStorage.removeItem('renderID');
        sessionStorage.removeItem('pdfUrl');
        sessionStorage.removeItem('vsdxUrl');
        sessionStorage.removeItem('renderType');
      },

      viewDrawing(item: any) {
        this.selectedDrawing = item;
        this.viewDialog = true;
      },

      confirmDelete(item: any) {
        this.drawingToDelete = item;
        this.deleteDialog = true;
      },

      deleteDrawing() {
        if (this.drawingToDelete) {
          // Here you would typically make an API call to delete the drawing
          const index = this.drawingsList.indexOf(this.drawingToDelete);
          if (index > -1) {
            this.drawingsList.splice(index, 1);
          }
        }
        this.deleteDialog = false;
        this.drawingToDelete = null;
      },

      handleDrawingRequest() {
        if (this.activeTab === 'customer') {
          this.handleRequestClick();
        } else {
          this.handleManufactureRequestClick();
        }
      },

      downloadPdfDrawing(item: any) {
        if (item.pdfUrl) {
          this.downloadFile(item.pdfUrl, `${item.fileName}.pdf`);
        }
      },

      downloadVisioDrawing(item: any) {
        if (item.visioUrl) {
          this.downloadFile(item.visioUrl, `${item.fileName}.vsdx`);
        }
      },

      getStatusColor(status: string) {
        switch (status.toUpperCase()) {
          case 'APPROVED':
            return 'success';
          case 'DRAFT':
            return 'grey lighten-1';
          case 'PENDING':
            return 'warning';
          case 'REJECTED':
            return 'error';
          default:
            return 'grey';
        }
      },

      getStatusTextColor(status: string) {
        switch (status.toUpperCase()) {
          case 'APPROVED':
          case 'REJECTED':
            return 'white';
          default:
            return 'black';
        }
      },
    },

    mounted() {
      this.restoreFromStore();
      const storedRenderID = sessionStorage.getItem('renderID');
      const storedPngUrl = sessionStorage.getItem('pngUrl');

      if (storedPngUrl) {
        this.pngUrl = storedPngUrl;
        this.pdfFileUrl = sessionStorage.getItem('pdfUrl');
        this.visioFileUrl = sessionStorage.getItem('vsdxUrl');
        this.$log.info('Loaded drawing link from sessionStorage:', storedPngUrl);
      }

      if (storedRenderID && !this.pngUrl) {
        this.renderRequestId = storedRenderID;
        this.$log.info('Found renderID in sessionStorage, starting polling:', this.renderRequestId);
        this.initiatePolling();
        this.isWaiting = true;
      }

      if (!this.items || this.items.length === 0) {
        this.isRequestDisabled = true;
        this.$log.warn('No items provided. Disabling request button.');
      }
    },

    beforeUnmount() {
      if (this.pollingIntervalId) {
        clearInterval(this.pollingIntervalId);
      }
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
  .content-col {
    padding: 0px 12px;
    margin-top: -20px;
  }
  .content-col-wait {
    padding: 0px 12px;
    margin-top: -20px;
    height: calc(100vh - 164px);
    min-height: 400px;
  }

  .sidebar-col {
    padding: 0px 12px;
    text-align: center;
  }

  .sidebar-btn-col {
    padding: 0px 12px;
    text-align: center;
  }

  .no-data-v2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .highlight {
    color: orange;
  }

  .btn-orange {
    background-color: orange;
    color: #fff;
  }

  .full-width {
    width: 100%;
  }

  .bold-text {
    font-weight: bold;
  }

  .divider {
    border-top: 1px solid #ccc;
    margin: 16px 0;
  }

  .dots {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 50px auto;
  }

  .dots span {
    width: 15px;
    height: 15px;
    background-color: orange;
    border-radius: 50%;
    animation: bounce 1.2s infinite;
  }

  .dots span:nth-child(1) {
    animation-delay: 0s;
  }

  .dots span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dots span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.5);
    }
  }

  .float-btn {
    position: absolute;
    bottom: 6%;
    right: 2%;
  }

  .pdf-app.light {
    --pdf-app-background-color: white;
  }

  :deep(.pdf-app .pdfViewer .page) {
    border-image: none !important;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }

  :deep(.v-table tr:hover) {
    background-color: #f9f9f9;
  }
  :deep(.v-table tr) {
    height: 44px;
  }
  :deep(.v-table td) {
    border: none !important;
  }

  .additional-main-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
  }
  .additional-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    font-weight: 500;
    margin-bottom: 14px;
  }
  .request-btn {
    width: 400px;
  }

  .v-data-table {
    background: white;
    margin-bottom: 20px;
  }

  :deep(.v-data-table-header) {
    background-color: #f5f5f5;
  }

  :deep(.v-chip) {
    font-size: 0.875rem;
  }

  :deep(.v-data-table__wrapper) {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }

  .v-dialog .v-card {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .v-card-text {
    flex-grow: 1;
    overflow: auto;
  }

  .drawings-list {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    position: relative;
  }

  :deep(.v-list-item) {
    min-height: 48px;
  }

  :deep(.v-list-item:hover) {
    background-color: #f5f5f5;
  }

  :deep(.v-list-item__prepend) {
    margin-right: 12px;
  }

  :deep(.v-list-item__append) {
    margin-left: 8px;
  }

  :deep(.v-chip) {
    margin-right: 8px;
  }

  :deep(.v-list-item--density-compact) {
    min-height: 32px;
  }

  :deep(.v-list-item-title) {
    font-size: 0.875rem;
  }

  .text-grey {
    color: rgba(0, 0, 0, 0.6);
  }

  .text-subtitle-2 {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }

  .note-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 14px;
  }


  .settings-header {
    display: flex;
 
    align-items: center;
    /* padding: 12px 16px; */
    cursor: pointer;

    /* border-bottom: 1px solid #e0e0e0; */
    transition: background-color 0.2s ease;
  }

  /* .settings-header:hover {
    background-color: #eeeeee;
  } */

  .settings-title {
    font-size: 14px;
    font-weight: 500;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
  }

  .settings-content {
    padding: 16px 0px;
  }

  :deep(.v-chip.v-chip--size-small) {
    font-size: 0.75rem;
    height: 20px;
  }

  :deep(.v-list-item__subtitle) {
    margin-top: 4px;
  }
</style>
