<!-- src/views/components/FormulaModal.vue -->
<template>
  <v-dialog v-model="isFormulaModalOpen" max-width="1200px" data-cy="edit-formula-modal" persistent>
    <v-card>
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">Edit Formula</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" class="scrollable-modal" data-cy="form">
          <v-row>
            <v-col cols="12" style="margin-top: -10px">
              <v-text-field label="Description" v-model="functionDescription" variant="underlined" data-cy="input-description" hide-details></v-text-field>
            </v-col>
          </v-row>
          <!-- start of the form -->

          <v-row>
            <div class="ml-3 mb-1"><h4>Formula</h4></div>

            <FormulaComponent
              style="margin-left: 0px; width: 100%"
              v-model="localItem.quantity_formula"
              :start-options="startOptions"
              :functions-options="functionsOptions"
              :attribute-options="attributeOptions"
              :formula="formula"
              :key="formulaComponentKey"
              :depth="0"
              @formula-changed="handleFormulaChanged"
            />
          </v-row>

          <!-- end of the form -->
        </v-form>
      </v-card-text>
      <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="close" data-cy="cancel-btn">Cancel</v-btn>
        <v-btn class="btn-save" @click="save" data-cy="done-btn" :disabled="!isFormulaValid && !isFormulaEmpty">Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import FormulaComponent from '@/components/bomg/components/FormulaComponent.vue';
  import { ContainsItem, AttributeItem } from '@/types/components';
  import { useAttributesStore } from '@/store/attributesStore';
  import etcService from '@/services/api/etcService';
  import { c } from 'naive-ui';
  import { values } from 'lodash';

  export default defineComponent({
    components: {
      FormulaComponent,
    },
    props: {
      item: {
        type: Object as PropType<ContainsItem | AttributeItem | undefined>,
        default: undefined,
      },
      type: {
        type: String,
        required: true,
      },
    },
    emits: ['close', 'save'],
    data() {
      return {
        isFormulaModalOpen: true,
        functionsOptions: [],
        startOptions: ['Function', 'Attribute', 'AttributeValue', 'Value'],
        localItem: this.initializeLocalItem(),
        formula: '',
        formulaComponentKey: 0,
        isFormulaValid: false,
        isDeleteDialogOpen: false,
        functionDescription: '',
      };
    },
    computed: {
      attributeOptions() {
        const attributesStore = useAttributesStore();
        return attributesStore.attributesData.map((attr) => {
          return {
            id: attr.id,
            name: attr.name.length > 30 ? attr.name.slice(0, 30) + '...' : attr.name,
            values: attr.values,
          };
        });
      },
      attributeIdToNameMap() {
        const attributesStore = useAttributesStore();
        return attributesStore.attributesData.reduce(
          (map, attr) => {
            map[attr.id] = { id: attr.id, name: attr.name };
            return map;
          },
          {} as Record<string, { id: string; name: string }>
        );
      },
      isContain() {
        return this.type === 'contain';
      },
      isAttribute() {
        return this.type === 'attribute';
      },

      isFormulaEmpty() {
        return Object.keys(this.localItem.quantity_formula || {}).length === 0;
      },
    },
    watch: {
      item(newVal) {
        this.localItem = JSON.parse(JSON.stringify(newVal));
      },
    },
    methods: {
      openDeleteDialog() {
        this.isDeleteDialogOpen = true;
      },

      closeDeleteDialog() {
        this.isDeleteDialogOpen = false;
      },
      handleFormulaChanged(event: { formulaEmit: any; isValid: boolean }) {
        this.isFormulaValid = event.isValid;
      },
      initializeLocalItem() {
        if (this.item) {
          return JSON.parse(JSON.stringify(this.item));
        }
        return {};
      },
      async getFunctionsList() {
        try {
          const response = await etcService.formulaFunctions();
          this.functionsOptions = response;
        } catch (error: any) {
          this.$error.view(error);
        }
      },

      loadFormulaData() {
        if (this.isContain && this.item && 'qty' in this.item && this.item.qty && 'body' in this.item.qty && this.item.qty.body !== undefined && this.item.qty.body !== null) {
          const formula = JSON.parse(JSON.stringify(this.item.qty.body));
          this.localItem.quantity_formula = this.addStartField(formula);
          this.formulaComponentKey++;
        } else if (
          this.isAttribute &&
          this.item &&
          'value' in this.item &&
          this.item.value &&
          'body' in this.item.value &&
          this.item.value.body !== undefined &&
          this.item.value.body !== null
        ) {
          const formula = JSON.parse(JSON.stringify(this.item.value.body));
          this.localItem.quantity_formula = this.addStartField(formula);
          this.formulaComponentKey++;
        } else {
          console.warn('Item does not exist or does not have a formula body. Cannot load formula.');
        }
      },
      loadDescription() {
        if (this.isContain && this.item && 'description' in this.item) {
          this.functionDescription = this.item.qty.description || '';
        } else if (this.isAttribute && this.item && 'value' in this.item) {
          this.functionDescription = (this.item as AttributeItem).value.description || '';
        }
      },
      addStartField(obj: any): any {
        if (typeof obj === 'object' && obj !== null) {
          if ('function' in obj) {
            return {
              start: 'Function',
              function: obj.function,
              inputA: obj.inputA ? this.addStartField(obj.inputA) : null,
              inputB: obj.inputB ? this.addStartField(obj.inputB) : null,
            };
          }

          if ('attribute' in obj) {
            const attributeName = this.attributeIdToNameMap[obj.attribute] || obj.attribute;
            return {
              start: 'Attribute',
              attribute: attributeName,
            };
          }

          if ('value' in obj) {
            return {
              start: 'Value',
              value: obj.value,
            };
          }
        }

        if (typeof obj === 'string') {
          if (obj.includes('-')) {
            const valueParts = obj.split('-');
            if (valueParts.length === 2) {
              const attributeName = this.attributeIdToNameMap[valueParts[0]] || valueParts[0];
              const attributeValue = valueParts[1];
              return {
                start: 'AttributeValue',
                attribute: attributeName,
                attributeValue: attributeValue,
              };
            }

            return {
              start: 'InvalidFormat',
              value: obj,
            };
          }

          const attributeName = this.attributeIdToNameMap[obj] || obj;
          return {
            start: 'Attribute',
            attribute: attributeName,
          };
        }

        if (typeof obj === 'number') {
          return {
            start: 'Value',
            value: obj.toString(), // Ensure all numbers are returned as strings
          };
        }

        console.warn('Unhandled object type or value:', obj);
        return obj;
      },
      removeStartField(obj: any): any {
        if (typeof obj === 'object' && obj !== null) {
          if (obj.start === 'Function' && 'function' in obj) {
            const result: any = { function: obj.function };

            if ('inputA' in obj) {
              result.inputA = this.removeStartField(obj.inputA);
            }

            if ('inputB' in obj) {
              result.inputB = this.removeStartField(obj.inputB);
            }

            return result;
          } else if (obj.start === 'Attribute' && obj.attribute) {
            return obj.attribute.id;
          } else if (obj.start === 'Value' && 'value' in obj) {
            const value = typeof obj.value === 'number' ? obj.value : Number(obj.value);
            if (isNaN(value)) {
              console.warn('Expected a number for value, but got:', obj.value);
              return { value: 0 };
            }
            return value;
          } else if (obj.start === 'AttributeValue' && 'value' in obj) {
            return `${obj.attribute.id}-${obj.value}`;
          }
        }

        //console.log('object:', obj);
        return obj;
      },

      save() {
        if (this.isFormulaEmpty) {
          this.isFormulaValid = true;

          if (this.isContain) {
            const updatedItem = {
              id: (this.localItem as ContainsItem).id,
              body: null,
              description: null,
            };
            this.$emit('save', { type: 'contain', item: updatedItem });
          } else if (this.isAttribute) {
            const updatedItem = {
              id: (this.localItem as AttributeItem).id,
              body: null,
              description: null,
            };
            this.$emit('save', { type: 'attribute', item: updatedItem });
          }

          this.close();
          return;
        }

        if (!this.isFormulaValid) {
          return;
        }

        const updatedFormula = this.removeStartField(this.localItem.quantity_formula || this.localItem.formula);

        if (this.isContain) {
          const updatedItem = {
            id: (this.localItem as ContainsItem).id,
            body: updatedFormula,
            description: this.functionDescription,
          };
          this.$emit('save', { type: 'contain', item: updatedItem });
        } else if (this.isAttribute) {
          const updatedItem = {
            id: (this.localItem as AttributeItem).id,
            body: updatedFormula,
            description: this.functionDescription,
          };
          this.$emit('save', { type: 'attribute', item: updatedItem });
        }

        this.close();
      },
      close() {
        this.$emit('close');
      },
    },
    mounted() {
      this.getFunctionsList();
      this.loadFormulaData();
      this.loadDescription();
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }
  .custom-width {
    width: 150px;
  }
</style>
