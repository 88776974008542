<!-- src/views/Landing.vue -->
<template>
  <div class="wrapper" style="display: none">
    <div class="content">
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>{{ greeting }}, {{ name }}</h1>
        </v-col>
      </v-row>

      <v-row class="card-row">
        <v-col v-for="card in navigationCards" :key="card.id" cols="12" md="6" sm="6">
          <v-card :class="card.className">
            <template v-slot:title>
              <v-card-title class="title" @click="navigateTo(card.route)">
                <i :class="card.icon"></i>
                {{ card.title }}
              </v-card-title>
            </template>

            <template v-slot:subtitle>
              <v-card-subtitle class="subtitle-wrapper">
                <span class="line-text">{{ card.subtitle }}</span>
              </v-card-subtitle>
            </template>

            <div v-if="card.quickLinks" class="quick-links">
              <v-list-item color="primary" rounded="shaped">
                <v-list-item-content v-for="link in card.quickLinks" :key="link.type">
                  <v-list-item-title>
                    <span class="link" @click="handleAssembliesQuickLink(link.type)">
                      {{ link.label }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>

            <v-card-actions>
              <v-btn class="btn-orange" @click="navigateTo(card.route)">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                &nbsp;open
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuth0 } from '@auth0/auth0-vue';
  import { useUserPreferencesStore } from '@/store/userPreferencesStore';

  const router = useRouter();

  const { user } = useAuth0();
  const name = user.value?.name || 'User';

  const greeting = ref('');
  const updateGreeting = () => {
    const hours = new Date().getHours();
    greeting.value = hours < 12 ? 'Good morning' : hours < 18 ? 'Good afternoon' : hours < 21 ? 'Good evening' : 'Good night';
  };

  const navigateTo = (routeName: string) => {
    router.push({ name: routeName });
  };

  const handleAssembliesQuickLink = (filterType: string) => {
    const userPreferencesStore = useUserPreferencesStore();
    userPreferencesStore.setAssembliesTableFilterStore(filterType);
    navigateTo('Assemblies');
  };

  const navigationCards = [
    {
      id: 1,
      title: 'Generate BOM',
      subtitle: 'Manage BOM data, edit product details, view drawings, and explore the product hierarchy—all in one place.',
      icon: 'fa-duotone fa-solid fa-gears',
      route: 'Generate',
      className: 'card',
    },
    {
      id: 2,
      title: 'Saved BOMs',
      subtitle: 'Access and manage saved BOMs, and create certified BOMs with ease.',
      icon: 'fa-duotone fa-solid fa-rectangle-list',
      route: 'Boms',
      className: 'card',
    },
    {
      id: 3,
      title: 'Assemblies',
      subtitle: 'Manage blueprints, structure sub-assemblies, and define code values for streamlined assembly workflows.',
      icon: 'fa-regular fa-share-nodes',
      route: 'Assemblies',
      className: 'card',
      quickLinks: [
        { type: 'blueprint', label: 'Blueprints' },
        { type: 'assembly', label: 'Sub-Assembly' },
        { type: 'values', label: 'Code Values' },
      ],
    },
    {
      id: 4,
      title: 'Raw Materials',
      subtitle: 'View existing materials, add new ones, and manage your material inventory efficiently.',
      icon: 'fa-duotone fa-solid fa-layer-group',
      route: 'Materials',
      className: 'card',
    },
    {
      id: 5,
      title: 'Logic Modules',
      subtitle: 'Define, edit, and manage logic modules for efficient operations.',
      icon: 'fa-duotone fa-solid fa-network-wired',
      route: 'Logic Modules',
      className: 'card',
    },
    {
      id: 6,
      title: 'Attributes',
      subtitle: 'Create, edit, and organize attributes for better product insights.',
      icon: 'fa-solid fa-table-list',
      route: 'Attributes',
      className: 'card',
    },
  ];

  onMounted(() => {
    router.push({ name: 'Generate' });
    // updateGreeting();
  });
</script>

<style scoped>
  .wrapper {
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 114px);
    padding: 10px;
  }

  .content {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  .quick-links {
    margin-top: -10px;
  }

  .link {
    cursor: pointer;
    color: #575757;
  }

  .link:hover {
    text-decoration: underline;
  }

  .title:hover {
    cursor: pointer;
  }

  .card {
    height: 180px;
  }

  .btn-orange {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  .subtitle-wrapper {
    max-height: 48px;
    overflow: hidden;
  }

  .line-text {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2em;
  }

  .card-row {
    margin-top: -14px !important;
  }
</style>
