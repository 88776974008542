product-cell
<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="flex-grow: 1; border-bottom: 2px solid" data-cy="tabs">
        <v-tab :key="0" :active="activeTab === 0" @click="activeTab = 0" data-cy="tab-saved">
          <i class="fa-solid fa-list"></i>
          &nbsp; Products
        </v-tab>

        <v-spacer></v-spacer>
        <v-btn class="btn-orange" @click="openProductDetailsModal(null, 'create')">Add new product</v-btn>
      </v-tabs>
      <div v-if="tab === 0" data-cy="tab-content-saved">
        <div class="main-table">
          <v-data-table :headers="headers" :items="productCategory" :loading="loading">
            <template v-slot:item.name="{ item }">
              <div class="d-flex align-center product-cell" @click="toggleFamily(item.id)">
                <v-icon size="small" class="mr-2">
                  {{ expandedProducts.includes(item.id) ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                </v-icon>
                {{ item.name }} ({{ filteredProducts(item.id).length }})
              </div>
              <div v-if="expandedProducts.includes(item.id)" class="product-details mt-2">
                <v-table density="compact" class="nested-table">
                  <thead>
                    <tr>
                      <th class="part-number">Part Number</th>
                      <th class="description">Description</th>

                      <th class="actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="product in filteredProducts(item.id)" :key="product.id" class="nested-row">
                      <td class="part-number">{{ product.basePN }}</td>
                      <td class="description">{{ product.description }}</td>

                      <td class="actions">
                        <div class="controls-wrapper">
                          <span @click.stop="openProductDetailsModal(product, 'edit')">
                            <i class="fa-duotone fa-solid fa-pen-to-square"></i>
                          </span>
                          <span @click.stop="confirmRemove(product)">
                            <i class="fa-duotone fa-solid fa-trash"></i>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
      <ProductDetailsModal
        :showProductDetailsModal="showProductDetailsModal"
        :selectedProduct="selectedProduct"
        :modalTitle="modalTitle"
        @close="showProductDetailsModal = false"
        @productUpdated="getProducts"
        @productCreated="getProducts"
      />
      <Dialogs :deleteDialog="deleteDialog !== null" @confirmDeleteItem="confirmDeleteItem" @closeDeleteDialog="closeDeleteDialog" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import productsService from '@/services/api_PM/products';
  import productsCategoryService from '@/services/api_PM/productsCategory';
  import Dialogs from '@/components/common/Dialogs.vue';
  import ProductDetailsModal from './ProductsModal.vue';
  import { Product, Customer, Vendor, ProductCategory } from '@/types/pm/product';
  import { create } from 'lodash';

  export default defineComponent({
    name: 'Start',
    components: {
      Dialogs,
      ProductDetailsModal,
    },
    data: () => ({
      tab: 0,
      activeTab: 0,
      headers: [
        {
          title: 'Products Category',
          key: 'name',
          sortable: true,
        },
      ],
      showProductDetailsModal: false,
      modalTitle: '',
      loading: false,
      expandedProducts: [] as string[],
      selectedFamily: null as any,
      revokeDialog: false,
      deleteDialog: null as Product | null,
      productCategory: [] as ProductCategory[],
      products: [] as Product[],
      selectedProduct: null as Product | null,
    }),

    mounted() {
      // this.getProductCategory();
      this.getProducts();
    },

    methods: {
      async getProductCategory() {
        try {
          // this.productCategory = await productsCategoryService.getProductsCategoryList();
          // this.productCategory = [
          //   { id: 'ID0', name: 'Finished Goods \\ Fiber Array' },
          //   { id: 'ID1', name: 'Finished Goods \\ Fiber Trunc' },
          // ];
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      async getProducts() {
        try {
          this.products = await productsService.getProducts();
          this.assignProductsToNoneCategory();
        } catch (error: any) {
          this.$error.view(error);
        }
      },
      assignProductsToNoneCategory() {
        const productsWithInvalidCategory = this.products.filter((product) => !product.categoryId || !this.productCategory.some((category) => category.id === product.categoryId));

        if (productsWithInvalidCategory.length > 0) {
          if (!this.productCategory.some((category) => category.id === 'NONE')) {
            this.productCategory.push({ id: 'NONE', name: 'Uncategorised' });
          }

          productsWithInvalidCategory.forEach((product) => {
            product.categoryId = 'NONE';
          });
        }
      },
      toggleFamily(categoryId: string) {
        const index = this.expandedProducts.indexOf(categoryId);
        if (index === -1) {
          this.expandedProducts.push(categoryId);
        } else {
          this.expandedProducts.splice(index, 1);
        }
      },
      filteredProducts(categoryId: string) {
        return this.products.filter((product) => product.categoryId === categoryId);
      },
      formatCustomers(customers: Customer[]): string {
        return customers.map((customer) => customer.name).join(', ');
      },
      formatVendors(vendors: Vendor[]): string {
        return vendors.map((vendor) => vendor.name).join(', ');
      },
      openProductDetailsModal(product: Product | null, action: 'create' | 'edit') {
        if (action === 'create') {
          this.modalTitle = 'Add New Product';
          this.selectedProduct = null;
        } else {
          this.modalTitle = 'Edit Product';
          this.selectedProduct = product;
        }
        this.showProductDetailsModal = true;
      },
      closeProductDetailsModal() {
        this.showProductDetailsModal = false;
      },

      async confirmDeleteItem() {
        if (this.deleteDialog) {
          try {
            await productsService.deleteProduct(this.deleteDialog.id);
            // this.products = this.products.filter((product) => product.id !== this.deleteDialog?.id);
            this.assignProductsToNoneCategory();
            this.closeDeleteDialog();
            this.getProducts();
          } catch (error: any) {
            this.$error.view(error);
          }
        }
      },
      confirmRemove(product: Product) {
        this.deleteDialog = product;
      },
      closeDeleteDialog() {
        this.deleteDialog = null;
      },
    },
  });
</script>

<style scoped>
  .certified-product-families-container {
    margin-top: 20px;
  }

  .product-cell {
    cursor: pointer;
    padding: 8px 0;
    height: 52px !important;
  }

  .product-details {
    background-color: #f8fafc;
    border-radius: 4px;
    margin: 8px 0;
  }

  .nested-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  .part-number {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .description {
    width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .customers {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vendors {
    width: 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .actions {
    width: 10%;
    text-align: center;
  }
  .nested-table th {
    background-color: #f1f5f9;
    color: #475569;
    font-size: 0.875rem;
    font-weight: 600;
    text-align: left;
    padding: 8px 16px;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .nested-table td {
    padding: 8px 16px;
    font-size: 0.875rem;
    color: #1e293b;
    border-bottom: 1px solid #e2e8f0;
  }

  .nested-row:hover {
    background-color: #f1f5f9;
  }

  .controls-wrapper {
    padding: 0 8px;
    gap: 15px;
    display: flex;
    font-size: 18px;
  }

  .controls-wrapper span {
    cursor: pointer;
  }

  .controls-wrapper span > i:hover {
    color: orange;
  }

  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }

  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  :deep(v-data-table__tr) {
    padding-top: 29px;
    height: 52px;
  }
</style>
