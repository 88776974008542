<template>
  <v-dialog v-model="showPolarityModal" max-width="1200px" persistent>
    <v-card>
      <v-card-title class="modal-title">
        <span class="text-h5">Polarity</span>
        <v-icon small @click="close" class="close-icon">mdi-close</v-icon>
      </v-card-title>

      <v-container>
        <v-row>
          <v-col md="12">
            <div class="text-start d-flex align-items-center justify-space-between">
              <p class="title-label m-0">
                Polarity Method:
                <span>{{ polarityData?.patternInfo?.pattern }}</span>
              </p>
              <div class="edit-options" v-if="!isAdvancedView">
                <V-select
                  v-model="selectedColorTheme"
                  :items="colorThemes"
                  label="Color Palette"
                  dense
                  density="compact"
                  hide-details
                  variant="underlined"
                  class="color-select-container"
                ></V-select>
                <!-- <V-select
                  v-if="selectedKeyA"
                  v-model="selectedKeyA"
                  :items="connectorKeys"
                  label="END A KEY"
                  dense
                  density="compact"
                  hide-details
                  variant="underlined"
                  class="key-select-container"
                ></V-select> -->
                <V-select
                  v-if="selectedKeyB"
                  v-model="selectedKeyB"
                  :items="connectorKeys"
                  label="END B KEY"
                  dense
                  density="compact"
                  hide-details
                  variant="underlined"
                  class="key-select-container"
                ></V-select>
              </div>
              <v-btn @click="toggleViewMode" class="mb-4 btn-orange" style="width: 140px">
                {{ isAdvancedView ? 'Schema' : 'Branches' }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :md="isAdvancedView ? 12 : 8">
            <v-data-table
              :headers="currentHeaders"
              :items="items"
              class="elevation-1"
              item-value="fiber"
              density="comfortable"
              items-per-page="-1"
              :items-per-page-options="itemsPerPageOptions"
              @mouseenter:row="onRowHover"
              @mouseleave:row="onRowUnHover"
            >
              <template #item.color="{ item }">
                <div :style="{ display: 'flex', alignItems: 'center', border: `1px solid ${getColorValue(item.color || '')}`, padding: '5px', borderRadius: '50px' }">
                  <div class="color-circle me-2" :style="{ backgroundColor: getColorValue(item.color || '') }"></div>
                  <span>{{ getColorLabel(item.color || '') }}</span>
                </div>
              </template>
              <template #item.connectorA="{ item }">
                <!-- <div @dblclick="startEdit(item, 'connectorA')"> -->
                <div v-if="editingItem === item && editingField === 'connectorA'" style="display: flex; align-items: center; gap: 8px">
                  <v-select
                    v-model="item.connectorA"
                    :items="availableConnectorsA"
                    dense
                    density="compact"
                    hide-details
                    style="width: 30px; margin-top: -8px"
                    variant="underlined"
                  ></v-select>
                  <span class="apply-edit" @click="applyEdit(item, 'connectorA', item.connectorA)"><i class="fa-solid fa-check"></i></span>
                  <span class="cancel-edit" @click="cancelEdit"><i class="fa-solid fa-xmark"></i></span>
                </div>
                <div v-else style="display: flex; align-items: center; justify-content: flex-end">
                  <span>{{ item.connectorA }}</span>
                  <span v-if="!isAdvancedView && editMode && editablePattern" class="edit-icon" @click="startEdit(item, 'connectorA')">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                </div>
                <!-- </div> -->
              </template>

              <template #item.fiberAPosition="{ item }">
                <div v-if="editingItem === item && editingField === 'fiberAPosition'" style="display: flex; align-items: center; gap: 8px">
                  <v-select
                    v-model="item.fiberAPosition"
                    :items="availableFiberAPosition"
                    dense
                    density="compact"
                    hide-details
                    style="width: 30px; margin-top: -8px"
                    variant="underlined"
                  ></v-select>
                  <span class="apply-edit" @click="applyEdit(item, 'fiberAPosition', item.fiberAPosition)"><i class="fa-solid fa-check"></i></span>
                  <span class="cancel-edit" @click="cancelEdit"><i class="fa-solid fa-xmark"></i></span>
                </div>
                <div v-else style="display: flex; align-items: center; justify-content: flex-end">
                  <span>{{ item.fiberAPosition }}</span>
                  <span v-if="!isAdvancedView && editMode && editablePattern" class="edit-icon" @click="startEdit(item, 'fiberAPosition')">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                </div>
              </template>

              <template #item.connectorB="{ item }">
                <div v-if="editingItem === item && editingField === 'connectorB'" style="display: flex; align-items: center; gap: 8px">
                  <v-select
                    v-model="item.connectorB"
                    :items="availableConnectorsB"
                    dense
                    density="compact"
                    hide-details
                    style="width: 30px; margin-top: -8px"
                    variant="underlined"
                  ></v-select>
                  <span class="apply-edit" @click="applyEdit(item, 'connectorB', item.connectorB)"><i class="fa-solid fa-check"></i></span>
                  <span class="cancel-edit" @click="cancelEdit"><i class="fa-solid fa-xmark"></i></span>
                </div>
                <div v-else style="display: flex; align-items: center; justify-content: flex-end">
                  <span>{{ item.connectorB }}</span>
                  <span v-if="!isAdvancedView && editMode && editablePattern" class="edit-icon" @click="startEdit(item, 'connectorB')">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                </div>
              </template>

              <template #item.fiberBPosition="{ item }">
                <div v-if="editingItem === item && editingField === 'fiberBPosition'" style="display: flex; align-items: center; gap: 8px">
                  <v-select
                    v-model="item.fiberBPosition"
                    :items="availableFiberBPosition"
                    dense
                    density="compact"
                    hide-details
                    style="width: 30px; margin-top: -8px"
                    variant="underlined"
                  ></v-select>
                  <span class="apply-edit" @click="applyEdit(item, 'fiberBPosition', item.fiberBPosition)"><i class="fa-solid fa-check"></i></span>
                  <span class="cancel-edit" @click="cancelEdit"><i class="fa-solid fa-xmark"></i></span>
                </div>
                <div v-else style="display: flex; align-items: center; justify-content: flex-end">
                  <span>{{ item.fiberBPosition }}</span>
                  <span v-if="!isAdvancedView && editMode && editablePattern" class="edit-icon" @click="startEdit(item, 'fiberBPosition')">
                    <i class="fa-solid fa-pen-to-square"></i>
                  </span>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col md="4" v-if="!isAdvancedView">
            <v-container>
              <v-row>
                <div class="text-start">
                  <p class="title-label-scheme">Connection scheme:</p>
                </div>
                <FiberConnectionsVisualizer v-if="showDraw" :items="items" :selectedRow="selectedRow" :drawOptions="drawOptions" style="margin-top: -4px" />
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions class="btn-modal-bottom">
        <input type="file" accept=".xlsx, .xls" ref="fileInput" @change="handleFileUpload" style="display: none" />
        <v-btn v-if="editMode && editablePattern" @click="exportData" class="btn-cancel ml-4">Export</v-btn>
        <v-btn v-if="editMode && editablePattern" @click="triggerFileInput" class="btn-cancel">Import</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="close" class="btn-cancel">Cancel</v-btn>
        <!-- <v-btn v-if="!editMode" @click="close" class="btn-cancel">Close</v-btn> -->
        <v-btn @click="handleSave" class="btn-save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType, watch } from 'vue';
  import _ from 'lodash';
  import * as XLSX from 'xlsx';
  import FiberConnectionsVisualizer from '@/components/bomg/polarity/PolarityDraw.vue';

  type FiberPosition = number | 'A' | 'B';

  interface PolarityItem {
    fiber: number;
    color?: string;
    connectorA: number;
    fiberAPosition: FiberPosition;
    connectorB: number;
    fiberBPosition: FiberPosition;
  }

  export default defineComponent({
    components: {
      FiberConnectionsVisualizer,
    },
    props: {
      showPolarityModal: {
        type: Boolean,
        required: true,
      },
      polarityData: {
        type: Object as any,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close', 'save'],

    watch: {
      showPolarityModal: {
        immediate: true,
        handler(val) {
          if (val) {
            this.items = this.polarityData?.polarityData || [];
            this.selectedKeyA = this.polarityData?.patternInfo?.connectorsInfo.a.key || '';
            this.selectedKeyB = this.polarityData?.patternInfo?.connectorsInfo.b.key || '';
            this.mapConnectorsData();
            this.determineColorTheme();
          }
        },
      },
      selectedColorTheme(newVal) {
        let palette: string[] = [];
        switch (newVal) {
          case '8-Blue':
            palette = this.colorTheme8Blue;
            break;
          case '8-Black':
            palette = this.colorTheme8Black;
            break;
          case '16-Blue':
            palette = this.colorTheme16Blue;
            break;
          case '16-Lime':
            palette = this.colorTheme16Lime;
            break;
          default:
            palette = [];
        }
        this.applyColorTheme(palette);
      },
      polarityData: {
        deep: true,
        handler() {
          this.items = this.polarityData?.polarityData || [];
          this.selectedKeyA = this.polarityData?.patternInfo?.connectorsInfo.a.key || '';
          this.selectedKeyB = this.polarityData?.patternInfo?.connectorsInfo.b.key || '';
          this.mapConnectorsData();
          this.determineColorTheme();
        },
      },
    },

    data() {
      return {
        basicHeaders: [
          {
            title: 'Trunk',
            align: 'center' as const,
            children: [
              { title: 'Fiber #', value: 'fiber', align: 'end' as const },
              { title: 'Color', value: 'color', align: 'start' as const },
            ],
          },
          {
            title: 'END A',
            align: 'center' as const,
            children: [
              { title: 'Connection', value: 'connectorA', align: 'center' as const },
              { title: 'Fiber Position', value: 'fiberAPosition', align: 'center' as const },
            ],
          },
          {
            title: 'END B',
            align: 'center' as const,
            children: [
              { title: 'Connection', value: 'connectorB', align: 'center' as const },
              { title: 'Fiber Position', value: 'fiberBPosition', align: 'center' as const },
            ],
          },
        ],
        advancedHeaders: [
          {
            title: 'Trunk',
            align: 'center' as const,
            children: [
              { title: 'Fiber #', value: 'fiber', align: 'end' as const },
              { title: 'Color', value: 'color', align: 'start' as const },
            ],
          },
          {
            title: 'END A',
            align: 'center' as const,
            children: [
              { title: 'Connection', value: 'connectorA', align: 'center' as const },
              { title: 'Fiber Position', value: 'fiberAPosition', align: 'center' as const },
              { title: 'Breakout', value: 'breakoutA', align: 'start' as const },
              { title: 'Leg ID', value: 'legIdA', align: 'start' as const },
              { title: 'Connector Type', value: 'connectorTypeA', align: 'start' as const },
            ],
          },
          {
            title: 'END B',
            align: 'center' as const,
            children: [
              { title: 'Connection', value: 'connectorB', align: 'center' as const },
              { title: 'Fiber Position', value: 'fiberBPosition', align: 'center' as const },
              { title: 'Breakout', value: 'breakoutB', align: 'start' as const },
              { title: 'Leg ID', value: 'legIdB', align: 'start' as const },
              { title: 'Connector Type', value: 'connectorTypeB', align: 'start' as const },
            ],
          },
        ],
        items: [] as any[],
        colors: [
          { label: 'Black', value: '#000000' },
          { label: 'Red', value: '#FF0000' },
          { label: 'White', value: '#f5f5f5' },
          { label: 'Slate', value: '#708090' },
          { label: 'Brown', value: '#A52A2A' },
          { label: 'Green', value: '#008000' },
          { label: 'Orange', value: '#FFA500' },
          { label: 'Blue', value: '#0000FF' },
        ],
        colorTheme8Blue: ['Blue', 'Orange', 'Green', 'Brown', 'Slate', 'White', 'Red', 'Black'],
        colorTheme8Black: ['Black', 'Red', 'White', 'Slate', 'Brown', 'Green', 'Orange', 'Blue'],
        colorTheme16Blue: ['Blue', 'Orange', 'Green', 'Brown', 'Slate', 'White', 'Red', 'Black', 'Yellow', 'Violet', 'Rose', 'Aqua', 'Olive', 'Magenta', 'Tan', 'Lime'],
        colorTheme16Lime: ['Lime', 'Tan', 'Magenta', 'Olive', 'Aqua', 'Rose', 'Violet', 'Yellow', 'Black', 'Red', 'White', 'Slate', 'Brown', 'Green', 'Orange', 'Blue'],

        itemsPerPageOptions: [10, 25, 50, 100, -1],
        selectedRow: null as PolarityItem | null,
        showDraw: true,
        isAdvancedView: false,
        editingItem: null as PolarityItem | null,
        editingField: '' as string,

        previousValue: null as any,
        selectedColorTheme: '',
        selectedKeyA: '',
        selectedKeyB: '',
        connectorKeys: ['UP', 'DOWN'],
        // colorThemes: ['8-Blue', '8-Black', '16-Blue', '16-Lime'],
      };
    },

    // onMounted() {
    //   this.items = this.polarityData;
    // },

    computed: {
      drawOptions() {
        const connectorsData = this.polarityData.connectorsData;

        const data = {
          connectorInfo: {
            a: {
              key: this.selectedKeyA,
              sequence: this.selectedKeyA === 'UP' ? 'asc' : 'desc',
              connectorType: connectorsData[0][0].connectorType,
              fibers: ['M8F', 'M8M'].includes(connectorsData[0][0].connectorType) ? 12 : connectorsData[0][0].fibers,
              connectorsCount: connectorsData[0].length,
            },
            b: {
              key: this.selectedKeyB,
              sequence: this.selectedKeyB === 'UP' ? 'desc' : 'asc',
              connectorType: connectorsData[1][0].connectorType,
              fibers: ['M8F', 'M8M'].includes(connectorsData[1][0].connectorType) ? 12 : connectorsData[1][0].fibers,
              connectorsCount: connectorsData[1].length,
            },
          },
        };

        return data;
      },
      currentHeaders() {
        return this.isAdvancedView ? this.advancedHeaders : this.basicHeaders;
      },
      availableConnectorsA() {
        return [...new Set(this.polarityData?.polarityData?.map((item: PolarityItem) => item.connectorA))];
      },
      availableConnectorsB() {
        return [...new Set(this.polarityData?.polarityData?.map((item: PolarityItem) => item.connectorB))];
      },
      availableFiberAPosition() {
        return [...new Set(this.polarityData?.polarityData?.map((item: PolarityItem) => item.fiberAPosition))];
      },
      availableFiberBPosition() {
        return [...new Set(this.polarityData?.polarityData?.map((item: PolarityItem) => item.fiberBPosition))];
      },
      colorThemes() {
        const fibersA = this.polarityData?.connectorsData?.[0]?.[0]?.fibers || 0;
        const fibersB = this.polarityData?.connectorsData?.[1]?.[0]?.fibers || 0;
        const maxFibers = Math.max(fibersA, fibersB);

        return maxFibers <= 12 ? ['8-Blue', '8-Black'] : ['16-Blue', '16-Lime'];
      },
      editablePattern() {
        return this.polarityData?.patternInfo?.pattern === 'C';
      },
    },
    methods: {
      startEdit(item: PolarityItem, field: string) {
        if (this.editingItem && this.editingField) {
          (this.editingItem as any)[this.editingField] = this.previousValue;
        }
        this.previousValue = (item as any)[field];
        this.editingItem = item;
        this.editingField = field;
      },

      applyEdit(item: PolarityItem, field: string, newValue: number | string) {
        (item as any)[field] = newValue;
        this.editingField = '';
        this.previousValue = null;
      },

      cancelEdit() {
        if (this.editingItem && this.editingField) {
          (this.editingItem as any)[this.editingField] = this.previousValue;
        }
        this.editingItem = null;
        this.editingField = '';
        this.previousValue = null;
      },
      toggleViewMode() {
        this.isAdvancedView = !this.isAdvancedView;
      },
      onRowHover(event: Event, row: any) {
        const item = row.item;
        this.selectedRow = item as PolarityItem;
      },
      onRowUnHover() {
        this.selectedRow = null;
      },
      getColorValue(colorName: string) {
        const colorItem = this.colors.find((c) => c.label === colorName);
        return colorItem ? colorItem.value : colorName;
      },
      getColorLabel(colorValue: string) {
        const colorItem = this.colors.find((c) => c.value === colorValue);
        return colorItem ? colorItem.label : colorValue;
      },
      mapConnectorsData() {
        this.items = this.items.map((item) => {
          const connectorAIndex = item.connectorA - 1;
          const connectorBIndex = item.connectorB - 1;

          const connectorAData = this.polarityData.connectorsData[0]?.[connectorAIndex] || {};
          const connectorBData = this.polarityData.connectorsData[1]?.[connectorBIndex] || {};

          return {
            ...item,
            legIdA: connectorAData.leg || null,
            breakoutA: connectorAData.breakout || null,
            connectorTypeA: connectorAData.connector || null,
            legIdB: connectorBData.leg || null,
            breakoutB: connectorBData.breakout || null,
            connectorTypeB: connectorBData.connector || null,
          };
        });
      },

      validateRow(row: any): PolarityItem | null {
        const fiber = parseInt(row[0]);
        const color = String(row[1]);
        const connectorA = parseInt(row[2]);
        const fiberAPosition = row[3];
        const connectorB = parseInt(row[4]);
        const fiberBPosition = row[5];

        if (
          !isNaN(fiber) &&
          color &&
          !isNaN(connectorA) &&
          (typeof fiberAPosition === 'number' || ['A', 'B'].includes(fiberAPosition)) &&
          !isNaN(connectorB) &&
          (typeof fiberBPosition === 'number' || ['A', 'B'].includes(fiberBPosition))
        ) {
          return {
            fiber,
            color,
            connectorA,
            fiberAPosition,
            connectorB,
            fiberBPosition,
          };
        }
        return null;
      },

      handleFileUpload(event: Event) {
        this.showDraw = false;
        const input = event.target as HTMLInputElement;
        const file = input?.files?.[0];
        if (!file) return;

        const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        if (!validTypes.includes(file.type)) {
          alert('Please upload a valid Excel file (.xlsx or .xls)');
          return;
        }

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          try {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

            const validItems: PolarityItem[] = [];
            for (let i = 1; i < jsonData.length; i++) {
              const row = jsonData[i];
              const validatedRow = this.validateRow(row);
              if (validatedRow) {
                validItems.push(validatedRow);
              }
            }

            if (validItems.length === 0) {
              alert('No valid data found in the uploaded file');
              return;
            }

            this.items = validItems;
            this.mapConnectorsData();
            this.showDraw = true;
          } catch (error) {
            console.error('Error processing file:', error);
            alert('Error processing the file. Please check the file format and try again.');
          }
        };

        reader.onerror = () => {
          alert('Error reading the file');
        };

        reader.readAsArrayBuffer(file);
        input.value = '';
      },

      exportData() {
        try {
          const headers = ['Fiber #', 'Position Color', 'END A Connection', 'END A Fiber Position', 'END B Connection', 'END B Fiber Position'];

          const data = this.items.map((item: PolarityItem) => [
            item.fiber,
            this.getColorLabel(item.color || ''),
            item.connectorA,
            item.fiberAPosition,
            item.connectorB,
            item.fiberBPosition,
          ]);

          const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

          // Add column widths
          const colWidths = [10, 20, 20, 20, 20, 20];
          worksheet['!cols'] = colWidths.map((width) => ({ width }));

          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Polarity Data');

          const date = new Date().toISOString().split('T')[0];
          XLSX.writeFile(workbook, `polarity_data_${date}.xlsx`);
        } catch (error) {
          console.error('Error exporting data:', error);
          alert('Error exporting data. Please try again.');
        }
      },
      triggerFileInput() {
        const fileInput = this.$refs.fileInput as HTMLInputElement;
        if (fileInput) {
          fileInput.click();
        }
      },
      applyColorTheme(palette: string[]) {
        this.items = this.items.map((item) => {
          const index = (item.fiber - 1) % palette.length;
          return {
            ...item,
            color: palette[index],
          };
        });
      },
      determineColorTheme() {
        if (!this.polarityData?.connectorsData) {
          console.warn('No connectors data available');
          return;
        }

        const fibersA = this.polarityData.connectorsData[0]?.[0]?.fibers || 0;
        const fibersB = this.polarityData.connectorsData[1]?.[0]?.fibers || 0;
        const maxFibers = Math.max(fibersA, fibersB);

        const detectedColors = this.items
          .filter((item) => item && item.color)
          .map((item) => {
            const color = this.getColorLabel(item.color);
            return typeof color === 'string' ? color.trim() : color;
          })
          .slice(0, maxFibers);

        if (detectedColors.length === 0) {
          this.selectedColorTheme = maxFibers <= 12 ? '8-Blue' : '16-Blue';
          return;
        }

        const compareTheme = (theme: string[]) => {
          const themeLength = theme.length;
          const relevantDetectedColors = detectedColors.slice(0, themeLength);
          return relevantDetectedColors.every((color, index) => color === theme[index]);
        };

        if (maxFibers <= 12) {
          console.log('Checking 8-color themes');
          if (compareTheme(this.colorTheme8Black)) {
            this.selectedColorTheme = '8-Black';
          } else {
            this.selectedColorTheme = '8-Blue';
          }
        } else {
          if (compareTheme(this.colorTheme16Lime)) {
            this.selectedColorTheme = '16-Lime';
          } else {
            this.selectedColorTheme = '16-Blue';
          }
        }

        const selectedTheme =
          this.selectedColorTheme === '8-Black'
            ? this.colorTheme8Black
            : this.selectedColorTheme === '16-Lime'
              ? this.colorTheme16Lime
              : maxFibers <= 12
                ? this.colorTheme8Blue
                : this.colorTheme16Blue;

        this.applyColorTheme(selectedTheme);
      },
      close() {
        this.$emit('close');
      },
      handleSave() {
        const updatedPolarityData = {
          ...this.polarityData,
          polarityData: this.items,
          patternInfo: {
            ...this.polarityData.patternInfo,
            connectorsInfo: {
              ...this.polarityData.patternInfo.connectorsInfo,
              a: {
                ...this.polarityData.patternInfo.connectorsInfo.a,
                key: this.selectedKeyA,
              },
              b: {
                ...this.polarityData.patternInfo.connectorsInfo.b,
                key: this.selectedKeyB,
              },
            },
          },
        };
        this.$emit('save', updatedPolarityData);
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-icon {
    cursor: pointer;
  }

  .custom-header {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    text-align: center;
    font-weight: bold;
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  .group-header {
    font-size: 14px;
  }

  .color-select-container {
    width: 100%;
    min-width: 150px;
  }

  .color-select {
    width: 100%;
  }

  .color-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: inline-block;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }
  ::v-deep(.v-data-table tr) {
    font-size: 15px;
  }

  .title-label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: -6px;
  }
  ::v-deep(.v-data-table th),
  ::v-deep(.v-data-table td) {
    border-right: 1px solid #ddd;
  }

  ::v-deep(.v-data-table th:last-child),
  ::v-deep(.v-data-table td:last-child) {
    border-right: none;
  }
  .apply-edit {
    cursor: pointer;
    color: green;
  }
  .cancel-edit {
    cursor: pointer;
    color: red;
  }

  .edit-icon {
    cursor: pointer;
    color: #3b516b3b;
    margin-left: 4px;
  }
  .edit-icon:hover {
    color: orange;
  }
  .title-label-scheme {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: -6px;
  }
  .edit-options {
    display: flex;
    gap: 10px;
  }
  .key-select-container {
    width: 100%;
    min-width: 150px;
  }
</style>
