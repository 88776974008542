<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <p>Pricing TAB</p>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PricingTab',

  });
</script>
